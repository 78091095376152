import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Login } from "src/app/models/Login";
import {
  BehaviorSubject,
  Observable,
  Subject,
  takeUntil,
  tap,
  timer,
} from "rxjs";
import { RequestVoucher } from "../../models/RequestVoucher";
import { RequestDeals } from "../../models/RequestDeal";
import { RequestBrand } from "../../models/RequestBrand";
import { RequestBrandmgt } from "../../models/Requestbrandmgt";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { AES, enc } from "crypto-js";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { WalletHistory } from "src/app/models/WalletHistory";
import { MultiDenomination } from "src/app/models/MultiDenomination";
import { company } from "src/app/models/company";
import { RequestCompany } from "src/app/models/RequestCompany";
@Injectable()
export class AppService {
  private sharingObject: any;
  private memberObject: any;
  private pageNumber: any;
  private key = "secret key 123";
  private sharingObjectData: any;
  private brandmgtDetail = new BehaviorSubject<any>(new RequestBrandmgt());
  private companyEdit = new BehaviorSubject<any>(new company());
  private clientProducts = new BehaviorSubject<any>(new company());
  private voucherDetail = new BehaviorSubject<any>(new RequestVoucher());
  private listWallet = new BehaviorSubject<any>(new WalletHistory());
  private multiList = new BehaviorSubject<any>(new MultiDenomination());
  private dealDetail = new BehaviorSubject<any>(new RequestDeals());
  private brandDetail = new BehaviorSubject<any>(new RequestBrand());
  private clientDetail = new BehaviorSubject<any>(new RequestCompany());
  private clientDetailwallet = new BehaviorSubject<any>(new RequestCompany());

  voucherDetailItem = this.voucherDetail.asObservable();
  dealDetailItem = this.dealDetail.asObservable();
  brandDetailItem = this.brandDetail.asObservable();
  // clientDetailItem = this.clientDetail.asObservable();
  private logoutSubject = new Subject<void>();
  // private readonly COOKIE_EXPIRATION_TIME_IN_HOURS = 1;
  private oneDayInMillis = 24 * 60 * 60 * 1000; // 1 day in milliseconds

  // private readonly sessionTimeoutDuration = 1/1440;
  private headers: any = {};
  private companyList = new BehaviorSubject<any>(new RequestCompany());

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router
  ) {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
  }

  getauthenticateToken(tokenparams: any) {
    const path = "get_token";
    return this.http.post(environment.ENVIRONMENT.baseURL + path, tokenparams, {
      responseType: "json",
    });
  }

  // login(loginForm: Login,headers :any) {

  //   const path = "http://44.235.191.111:8882/api/en/users/login?"
  //   return this.http.post(path + "UserName=" + loginForm.UserName + "&Password=" + loginForm.Password + "&RoleCode=" + loginForm.RoleCode + "&Domain=" + loginForm.Domain, loginForm,

  //   // { responseType: 'json' }
  //   headers
  //   )
  //   .pipe( tap((res:any) => {
  //     let encryptedToken = this.cookieService.get('token');
  //     if (!encryptedToken) {
  //       encryptedToken = AES.encrypt('testToken', this.key).toString();
  //       this.cookieService.set('token', encryptedToken, this.COOKIE_EXPIRATION_TIME_IN_HOURS * 60 * 60 * 1000);
  //     }
  //   })
  // )

  // }

  adminlogin(loginForm: any, headers: any) {
    const path = "user/login";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        loginForm,

        headers
      )
      .pipe(
        tap((res: any) => {
          let encryptedToken = this.cookieService.get("token");

          if (!encryptedToken) {
            encryptedToken = AES.encrypt("testToken", this.key).toString();
            const oneDayInMillis = 24 * 60 * 60 * 1000; // 1 day in milliseconds
            this.cookieService.set("token", encryptedToken, oneDayInMillis);
          }
        })
      );
  }

  brandlogin(loginForm: any, headers: any) {
    const path = "internal/brand/login";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        loginForm,

        headers
      )

      .pipe(
        tap((res: any) => {
          let encryptedToken = this.cookieService.get("token");
          if (!encryptedToken) {
            encryptedToken = AES.encrypt("testToken", this.key).toString();
            const oneDayInMillis = 24 * 60 * 60 * 1000; // 1 day in milliseconds
            this.cookieService.set("token", encryptedToken, oneDayInMillis);
          }
        })
      );
  }
  companylogin(loginForm: any, headers: any) {
    const path = "internal/company/login";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        loginForm,

        headers
      )

      .pipe(
        tap((res: any) => {
          let encryptedToken = this.cookieService.get("token");
          if (!encryptedToken) {
            encryptedToken = AES.encrypt("testToken", this.key).toString();
            const oneDayInMillis = 24 * 60 * 60 * 1000; // 1 day in milliseconds
            this.cookieService.set("token", encryptedToken, oneDayInMillis);
          }
        })
      );
  }

  changeToken(newToken: string) {
    let encryptedToken = AES.encrypt(newToken, this.key).toString();
    const oneDayInMillis = 24 * 60 * 60 * 1000; // 1 day in milliseconds
    this.cookieService.set("token", encryptedToken, oneDayInMillis);
    localStorage.removeItem("logged");
    location.reload();
  }

  getToken(): string {
    let encryptedToken = this.cookieService.get("token");
    if (encryptedToken) {
      return AES.decrypt(encryptedToken, this.key).toString(enc.Utf8);
    }
    return "";
  }

  isLoggedIn(): boolean {
    return this.cookieService.check("token");
  }

  logout() {
    this.cookieService.delete("token");
    this.logoutSubject.next();
  }

  // private getCookieExpirationTime(): number {
  //   const cookieSetTime = this.getCookieSetTime();
  //   return cookieSetTime + (this.COOKIE_EXPIRATION_TIME_IN_HOURS * 60 * 60 * 1000);
  // }

  private getCookieExpirationTime(): number {
    const cookieSetTime = this.getCookieSetTime();
    const oneDayInMillis = 24 * 60 * 60 * 1000; // 1 day in milliseconds
    return cookieSetTime + oneDayInMillis;
  }

  checkCookieExpiration(): void {
    /* const cookieExpirationTime = this.getCookieExpirationTime();
    const currentTime = new Date().getTime();*/

    let cookie: any = document.cookie;

    if (!cookie) {
      localStorage.removeItem("logged");
      location.reload();
    }
  }

  private getCookieSetTime(): any {}

  //!--- Voucher List---!

  getVoucherList(
    limit: number,
    skip: number,
    company: string,
    brand: string,
    approved: string,
    type: string,
    category: string,
    user_type: string,
    user_id: string,
    voucher_type: string,
    headers: any
  ) {
    const path = "get_voucher_request_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&Company=" +
          company +
          "&Brand=" +
          brand +
          "&Approved=" +
          approved +
          "&type=" +
          type +
          "&category=" +
          category +
          "&user_type=" +
          user_type +
          "&user_id=" +
          user_id +
          "&voucher_type=" +
          voucher_type,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getInventoryList(
    page: number,
    limit: number,
    headers: any
  ) {
    const path = "inventor/file/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?page=" +
          page +
          "&limit=" +
          limit,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }


  getInventoryListFilter(
    query: any,
    headers: any
  ) {
    const queryParams = new URLSearchParams(query).toString();
    const path = `inventor/file/list?${queryParams}`;

    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path,headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getinternationalVoucherList(
    limit: number,
    skip: number,
    company: string,
    brand: string,
    approved: string,
    type: string,
    category: string,
    user_type: string,
    user_id: string,
    voucher_type: string,
    action:string,
    headers: any
  ) {
    const path = "get_voucher_request_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&Company=" +
          company +
          "&Brand=" +
          brand +
          "&Approved=" +
          approved +
          "&type=" +
          type +
          "&category=" +
          category +
          "&user_type=" +
          user_type +
          "&user_id=" +
          user_id +
          "&voucher_type=" +
          voucher_type +
          "&action=" + action,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }
  //!------ Multi Denomination get API  Start------!

  getMultiDenominationList(
    limit: number,
    skip: number,
    status: string,
    client_name: string,
    user_type: string,
    user_id: string,
    voucher_type: string,
    created_by_role: string,
    headers: any
  ) {
    const path = "get/group/voucher/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&status=" +
          status +
          "&client_name=" +
          client_name +
          "&user_type=" +
          user_type +
          "&user_id=" +
          user_id +
          "&voucher_type=" +
          voucher_type +
          "&created_by_role=" +
          created_by_role,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //!------ Multi Denomination get API  End------!

  getCompanyList(company: string, limit: number, skip: number, headers: any) {
    const path = "/internal/company/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&search=" +
          company,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getBrandList(limit: number, skip: number, brandtype: any, headers: any) {
    const path = "list/brand";
    let url =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip;

    if (brandtype) url = url + "&type=" + brandtype;
    return this.http
      .get(url, headers)

      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getCompanycontractList(
    id: number,
    limit: number,
    skip: number,
    headers: any
  ) {
    const path = "company/contract/get";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&company_id=" +
          id,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getproductList(
    limit: number,
    skip: number,
    source: any,
    brand_name: any,
    category: any,
    product: any,
    headers: any
  ) {
    const path = "get/admin/product/list";
    let URL: any = environment.ENVIRONMENT.baseURL + path;

    if (limit) URL = URL + "?limit=" + limit;
    if (skip) URL = URL + "&skip=" + skip;
    if (source) URL = URL + "&source=" + source;
    if (brand_name && brand_name !== "All")
      URL = URL + "&brand_name=" + brand_name;
    if (category && category !== "All") URL = URL + "&category_id=" + category;
    if (product) URL = URL + "&product_name=" + product;

    return (
      this.http
        .get(
          URL,

          headers
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              localStorage.removeItem("logged");
              location.reload();
            }
            return throwError(error);
          })
        )
    );
  }

  getproductDownload( source: any,category: any,brand_name: any,product: any) {
    // let urlQuery = "";
    // if(source || category || brand_name || product){

    // }else{
    //   urlQuery = "?";
    // }
    let newArr = [];
    const path = "get/admin/product/download?";
    let URL: any = environment.ENVIRONMENT.baseURL + path;
    
    if (source) newArr.push("source=" + source);
    if (brand_name && brand_name !== "All")
      newArr.push("brand_name=" + brand_name)
      // URL = URL + "&brand_name=" + brand_name;
    if (category && category !== "All")
      newArr.push("category_id=" + category)
      //  URL = URL + "&category_id=" + category;
    if (product) 
      newArr.push("product_name=" + product)
      URL = URL + newArr.join("&")
      // URL = URL + "&product_name=" + product;
    let atag = document.createElement("a");
    atag.href = URL;
    atag.download = "Test";
    atag.click();

    // return (
    //   this.http
    //     .get(
    //       URL
    //     )
    //     .pipe(
    //       catchError((error: HttpErrorResponse) => {
    //         if (error.status === 401) {
    //           localStorage.removeItem("logged");
    //           location.reload();
    //         }
    //         return throwError(error);
    //       })
    //     )
    // );
  }

  getbrandmgtlist(
    brandname: any,
    categoryid: any,
    limit: number,
    skip: number,
    headers: any
  ) {
    const path = "internal/brand/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip;
    if (brandname) URL = URL + "&name=" + brandname;
    if (categoryid) URL = URL + "&category_id=" + categoryid;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getproductmgtlist(
    product: any,
    brand: any,
    limit: number,
    skip: number,
    headers: any
  ) {
    const path = "internal/product/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip;
    if (product) URL = URL + "&name=" + product;
    if (brand) URL = URL + "&brand_id=" + brand;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }
  getVoucherRedemptionHistory(
    product: any,
    brand: any,
    limit: number,
    skip: number,
    headers: any,
    start_date: any,
    end_date: any
  ) {
    const path = "get/voucher/redemption/history";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip;
    // if(product)URL=URL+ "&name=" + product;
    if (brand)
      URL =
        URL +
        "&brand_id=" +
        brand +
        "&start_date=" +
        start_date +
        "&end_date=" +
        end_date;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getproductmaplist(
    productsku: string,
    limit: number,
    skip: number,
    type:string,
    headers: any
  ) {
    const path = "data/mapping/list";
    let url =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip + "&type=" + type;

    if (productsku) url = url + "&name=" + productsku;
    return this.http
      .get(url, headers)

      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getClientproductmaplist(
    productsku: string,
    limit: number,
    skip: number,
    client_id: any,
    headers: any
  ) {
    const path = "internal/client/product/list";
    let url =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip +
      "&client_id=" +
      client_id;

    if (productsku) url = url + "&name=" + productsku;
    return this.http
      .get(url, headers)

      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getcategorylist(name: string, limit: number, skip: number, headers: any) {
    const path = "category/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&name=" +
          name,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getNamelist(external: string, limit: number, skip: number, headers: any) {
    const path = "get/external/category/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&search=" +
          external,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getB2cBrandlist(external: string, limit: number, skip: number, headers: any) {
    const path = "b2c/brand/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&name=" +
          external,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getB2ccategorylist(
    external: string,
    limit: number,
    skip: number,
    headers: any
  ) {
    const path = "b2c/category/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&name=" +
          external,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getBrandlist(external: string, limit: number, skip: number, headers: any) {
    const path = "get/external/brand/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&search=" +
          external,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }
  // get api for template component
  getCategorySubject(
    name: string,
    limit: number,
    skip: number,
    headers: any,
    platform: any
  ) {
    const path = "get/email/template";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip +
      "&name=" +
      name +
      "&platform=" +
      platform;

    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getClientEmailSubject(roletype: any, headers: any) {
    const path = "get/clientEmailTemplate";
    let URL: any =
      environment.ENVIRONMENT.baseURL + path + "?companyId=" + roletype;

    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getProductBrandlist(brand: number, external: string, headers: any) {
    const path = "b2c/brand/product/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?&brand_id=" +
          brand +
          "&search=" +
          external,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getProductCategorylist(brand: number, external: string, headers: any) {
    const path = "b2c/category/product/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?category_id=" +
          brand +
          "&search=" +
          external,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getTerms(limit: number, skip: number, headers: any) {
    const path = "get/settings";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip;

    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getWallet(client_id: string, limit: number, skip: number, headers: any) {
    const path = "internal/client/wallet/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip +
      "&client_id=" +
      client_id;

    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //Update template body

  updateTemplateBody(postParams: any, headers: any) {
    const path = "email/template/update";
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateClientTemplateBody(postParams: any, headers: any) {
    const path = "email/template/update";
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateImage(postParams: any) {
    const path = "external/product/image";
    return this.http
      .put(environment.ENVIRONMENT.baseURL + path, postParams, {
        headers: new HttpHeaders({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateTerms(postParams: any, headers: any) {
    const path = "update/terms/condition";
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateClientProduct(postParams: any, headers: any) {
    const path = "internal/client/product/update";
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  // Get voucher wallet history

  // getId(name:string, limit: number, skip: number , headers : any){
  //   const path = 'get_generation_list';
  //     let URL:any=environment.ENVIRONMENT.baseURL + path + "?limit=" + limit + "&skip=" + skip + "&name=" + name

  //     return this.http.get(
  //       URL,

  //       headers
  //     )
  //     .pipe(
  //       catchError((error: HttpErrorResponse) => {
  //         if (error.status === 401) {
  //           localStorage.removeItem('logged');
  //           location.reload();
  //         }
  //         return throwError(error);
  //       })
  //     );
  //     }

  getVoucherWalletHistory(
    voucher_id: any,
    name: string,
    limit: number,
    skip: number,
    headers: any
  ) {
    const path = "get/voucher/wallet/history";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?voucher_id=" +
      voucher_id +
      "&name=" +
      name +
      "&limit=" +
      limit +
      "&skip=" +
      skip;

    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  // For Get body using id

  // getCategorySubjectId(id:string ) {

  //   const path = 'get/email/template';
  //   let URL:any=environment.ENVIRONMENT.baseURL + path + "/" + id

  //   return this.http.get(
  //     URL,

  //   )
  //   .pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       if (error.status === 401) {
  //         localStorage.removeItem('logged');
  //         location.reload();
  //       }
  //       return throwError(error);
  //     })
  //   );
  //   ;
  // }

  deletebrandList(brandId: any, headers: any) {
    const path = `internal/brand/delete/${brandId}`;
    return this.http
      .delete(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  deleteClient(id: any, headers: any) {
    const path = `internal/company/delete?id=${id}`;
    return this.http
      .delete(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  deleteproductList(productId: any, headers: any) {
    const path = `internal/product/delete/${productId}`;
    return this.http
      .delete(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  deleteBrandproductList(productId: any, headers: any) {
    const path = `b2c/brand/product/delete/?id=${productId}`;
    return this.http
      .delete(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  deleteCategoryproductList(productId: any, headers: any) {
    const path = `b2c/category/product/delete/?id=${productId}`;
    return this.http
      .delete(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  deletecategoryList(categoryId: any, headers: any) {
    const path = `category/delete/${categoryId}`;
    return this.http
      .delete(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getcategoryeditlist(id: any, headers: any) {
    const path = `get/category/${id}`;
    // const path = 'get/category';
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getproducteditlist(id: any, headers: any) {
    const path = `get/internal/product/${id}`;
    // const path = 'get/category';
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getbrandeditlist(id: any, headers: any) {
    const path = `get/internal/brand/${id}`;
    // const path = 'get/category';
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getbrandload(headers: any) {
    const path = "get/internal/brand/list?search=";
    return (
      this.http
        .get(
          environment.ENVIRONMENT.baseURL + path,

          headers
        )

        //  const path = 'get/rewardkart/product/list';
        // let URL:any=environment.ENVIRONMENT.baseURL + path
        // if(searchText)URL=URL+ "?search=" + searchText;
        // return this.http.get(
        //   URL,

        //   headers
        // )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              localStorage.removeItem("logged");
              location.reload();
            }
            return throwError(error);
          })
        )
    );
  }
  getbrandName(headers: any) {
    const path = "recharge/product";
    return (
      this.http
        .get(
          environment.ENVIRONMENT.baseURL + path,

          headers
        )

        //  const path = 'get/rewardkart/product/list';
        // let URL:any=environment.ENVIRONMENT.baseURL + path
        // if(searchText)URL=URL+ "?search=" + searchText;
        // return this.http.get(
        //   URL,

        //   headers
        // )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              localStorage.removeItem("logged");
              location.reload();
            }
            return throwError(error);
          })
        )
    );
  }
  getbrandloadsproduct(searchText: any, vouchertype: any, headers: any) {
    const path = "get/internal/brand/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL + path + "?vouchertype=" + vouchertype;
    if (searchText) URL = URL + "&search=" + searchText;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getbrandloads(searchText: any, headers: any) {
    const path = "get/internal/brand/list";
    let URL: any = environment.ENVIRONMENT.baseURL + path;
    if (searchText) URL = URL + "?search=" + searchText;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getParcategory(headers: any) {
    const path = "get/category/list?search=";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getCurrency(headers: any) {
    const path = "/currency/dropdown";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getCurrencyList(headers: any) {
    const path = "/currency/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getCurrencymarkup(Params: any, headers: any) {

    const path = "/currency/add";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        Params,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
}

getEditCurrencymarkup(Params: any, headers: any) {

  const path = "/currency/update";
  return this.http
    .post(
      environment.ENVIRONMENT.baseURL + path,
      Params,

      headers
    )
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.removeItem("logged");
          location.reload();
        }
        return throwError(error);
      })
    );
}
  addNewBrandlist(postParams: any, headers: any) {
    const path = "internal/product/add";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  addNewproductlist(postParams: any, headers: any) {
    const path = "internal/product/add";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  addWallet(postParams: any, headers: any) {
    const path = "internal/client/wallet/add";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateproductlist(postParams: any, headers: any) {
    const path = "internal/product/update";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateinternallist(postParams: any, headers: any) {
    const path = "internal/product/update";
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  addNewbrand(postParams: any, headers: any) {
    const path = "internal/brand/add";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updatebrand(postParams: any, headers: any) {
    const path = "internal/brand/update";
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  createNewClient(postParams: any, headers: any, Edit?: boolean) {
    let path = "internal/company/add";
    if (Edit) {
      path = "internal/company/update";
    }

    return this.http[Edit ? "put" : "post"](
      environment.ENVIRONMENT.baseURL + path,
      postParams,

      headers
    ).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.removeItem("logged");
          location.reload();
        }
        return throwError(error);
      })
    );
  }

  addNewcategory(postParams: any, headers: any) {
    const path = "category/add_update";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  addNewB2cbrand(postParams: any, headers: any) {
    const path = "b2c/brand/create";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  addNewB2ccategory(postParams: any, headers: any) {
    const path = "b2c/category/create";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  addNewproductmap(postParams: any, headers: any) {
    const path = "data/mapping/add";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  editNewproductmap(postParams: any, headers: any) {
    const path = "data/mapping/update";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  clientNewproductmap(postParams: any, headers: any) {
    const path = "internal/client/product/create";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updatecategory(postParams: any, headers: any) {
    const path = "category/add_update";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateB2cBrandcategory(postParams: any, headers: any) {
    const path = "b2c/brand/update";
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateB2cCategorycategory(postParams: any, headers: any) {
    const path = "b2c/category/update";
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  deleteContract(ContractId: any, headers: any) {
    const path = "company/contract/delete/";
    return this.http
      .delete(
        environment.ENVIRONMENT.baseURL + path + ContractId,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  addNewcontractor(postParams: any, headers: any) {
    const path = "company/contract/create";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updatecontractor(postParams: any, headers: any) {
    const path = "company/contract/update";
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //brandmagtlist

  brandmgtDetails(brandmgtDetail: RequestBrandmgt) {
    this.brandmgtDetail.next(brandmgtDetail);
  }

  companyDetails(companyEdit: RequestCompany) {
    this.companyEdit.next(companyEdit);
  }

  clientProduct(clientProducts: company) {
    this.clientProducts.next(clientProducts);
  }

  //!--- Voucher Detail---!

  voucherDetails(voucherDetail: RequestVoucher) {
    this.voucherDetail.next(voucherDetail);
  }

  voucherWalletDetail(walletList: WalletHistory) {
    this.listWallet.next(walletList);
  }

  voucherGroupDetails(requestGroupVoucherlist: MultiDenomination) {
    this.multiList.next(requestGroupVoucherlist);
  }

  //!--- Add New Voucher ---!

  voucherRequest(postParams: any, headers: any) {
    const path = "voucher_request";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //!--- get sourcelist---!
  getSource(searchText: string, headers: any) {
    const path = "get_supplier_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path + "?search=",

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getInternationalSource(searchText: string,type: string, headers: any) {
    const path = "get_supplier_list";
      let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&type=" +
      type;
    // if (type) URL = URL + "&type=" + type;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getDomesticSource(searchText: string,type: string, headers: any) {
    const path = "get_supplier_list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&type=" +
      type;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getBrandSource(headers: any) {
    const path = "b2c/brand/dropdown/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getCategorySource(headers: any) {
    const path = "b2c/category/dropdown/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getproduct(searchText: string, headers: any) {
    // const path = 'get/rewardkart/product/list';
    // return this.http.get(
    //   environment.ENVIRONMENT.baseURL + path + "?search=" + searchText ,

    //   headers
    // )

    const path = "get/rewardkart/product/list";
    let URL: any = environment.ENVIRONMENT.baseURL + path;
    if (searchText) URL = URL + "?search=" + searchText;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getCategory(source: string, headers: any) {
    const path = "get/admin/product/category/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path + "?source=" + source,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getBrands(source: string, headers: any) {
    const path = "get/admin/brand/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path + "?source=" + source,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //!--- getCompany---!
  getCompany(searchText: string, headers: any) {
    const path = "company_search";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path + "?search=" + searchText,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  currencyConversion(headers:any) {
    const path = "exchange_rate_convertion";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path ,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }
  //!--- getBrand---!

  getBrandproduct(
    searchText: string,
    type: string,
    voucher_type: any,
    headers: any
  ) {
    const path = "brand_search";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&voucher_type=" +
      voucher_type;
    if (type) URL = URL + "&type=" + type;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getBrand(searchText: string, type: string, headers: any) {
    const path = "brand_search";
    let URL: any =
      environment.ENVIRONMENT.baseURL + path + "?search=" + searchText;
    if (type) URL = URL + "&type=" + type;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getQCBrand(item: any, headers: any) {
    const path = "get_product_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path + "?brand_id=" + item.BrandId,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getAmazonproductsbrand(
    searchText: string,
    type: any,
    voucher_type: any,
    brandid: any,
    headers: any
  ) {
    // const path = 'product_search';
    // return this.http.get(
    //   environment.ENVIRONMENT.baseURL + path + "?search=" + searchText + "&type=" + type + "&voucher_type=" + voucher_type,

    //   headers
    // )

    const path = "product_search";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&type=" +
      type +
      "&voucher_type=" +
      voucher_type;
    if (brandid) URL = URL + "&brand_id=" + brandid;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getAmazonproducts(searchText: string, type: any, headers: any) {
    const path = "product_search";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?search=" +
          searchText +
          "&type=" +
          type,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getEzpinproducts(searchText: string, type: any, headers: any) {
    const path = "/EZPINProducts";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?search=" +
          searchText +
          "&type=" +
          type,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getEzpininternationalproducts(searchText: string, type: any,currency: string, headers: any) {
    const path = "international/product_search";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?search=" +
          searchText +
          "&source=" +
          type + "&currency=" +currency,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getDtoneinternationalproducts(searchText: string, type: any,currency: string, headers: any) {
    const path = "international/product_search";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?search=" +
          searchText +
          "&source=" +
          type + "&currency=" +currency,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getClientproducts(
    searchText: string,
    headers: any,
    client_id: any,
    sourceParam?: any,
    sourceList?: any
  ) {
    const path = "product_search";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&role=Company" +
      `${client_id ? `&client_id=${client_id}` : ""}`;
    if (sourceList) URL = URL + "&type=" + sourceParam;
    return this.http.get(URL, headers).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.removeItem("logged");
          location.reload();
        }
        return throwError(error);
      })
    );
  }

  getTEproductsmap(searchText: string, type: any, headers: any) {
    const path = "product_search";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&type=" +
      type;
    // if(brandid)URL=URL+ "&brand_id=" + brandid;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getDTproductsmap(searchText: string, type: any, headers: any) {
    const path = "product_search";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&type=" +
      type;
    // if(brandid)URL=URL+ "&brand_id=" + brandid;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getMEAproductsmap(searchText: string, type: any, headers: any) {
    const path = "product_search";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&type=" +
      type;
    // if(brandid)URL=URL+ "&brand_id=" + brandid;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }
  

  getEZPINproductsmap(searchText: string, type: any, headers: any) {
    const path = "product_search";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&type=" +
      type;
    // if(brandid)URL=URL+ "&brand_id=" + brandid;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getTEproductsbrand(
    searchText: string,
    type: any,
    voucher_type: any,
    brandid: any,
    headers: any
  ) {
    const path = "product_search";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&type=" +
      type +
      "&voucher_type=" +
      voucher_type;
    if (brandid) URL = URL + "&brand_id=" + brandid;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getTEproducts(searchText: string, type: any, brandid: any, headers: any) {
    const path = "product_search";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&type=" +
      type;
    if (brandid) URL = URL + "&brand_id=" + brandid;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getproductTEproducts(
    searchText: string,
    type: any,
    brandid: any,
    voucher_type: any,
    headers: any
  ) {
    const path = "product_search";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&type=" +
      type +
      "&voucher_type=" +
      voucher_type;
    if (brandid) URL = URL + "&brand_id=" + brandid;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getproductvoucherproducts(
    searchText: string,
    brandid: any,
    variant: any,
    headers: any
  ) {
    const path = "get/internal/product/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&variant=" +
      variant;
    if (brandid) URL = URL + "&brand_id=" + brandid;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getvoucherproducts(
    searchText: string,
    brandid: any,
    variant: any,
    headers: any
  ) {
    const path = "get/internal/product/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&variant=" +
      variant;
    if (brandid) URL = URL + "&brand_id=" + brandid;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getQCproductsbrand(
    searchText: any,
    type: any,
    voucher_type: any,
    brandid: any,
    headers: any
  ) {
    if (typeof searchText == "object") {
      searchText = searchText.search;
    }
    // const path = 'product_search';
    // return this.http.get(
    //   environment.ENVIRONMENT.baseURL + path + "?search=" + searchText + "&type=" + type + "&voucher_type=" + voucher_type ,

    //   headers
    // )

    const path = "product_search";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?search=" +
      searchText +
      "&type=" +
      type +
      "&voucher_type=" +
      voucher_type;
    if (brandid) URL = URL + "&brand_id=" + brandid;
    return this.http
      .get(URL, headers)

      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getQCproducts(searchText: any, type: any, headers: any) {
    if (typeof searchText == "object") {
      searchText = searchText.search;
    }
    const path = "product_search";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?search=" +
          searchText +
          "&type=" +
          type,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getQCinternationalproducts(searchText: any, type: any,currency:string, headers: any, brand?:any) {
    if (typeof searchText == "object") {
      searchText = searchText.search;
    }
    const path = "international/product_search";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?search=" +
          searchText +
          "&source=" +
          type + 
          "&currency=" +
          currency + (brand ? `&brand=${brand}` : ""), headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getfetchlist(item: any, headers: any) {
    const path = "company/contract/get/";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path + item.id,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  gettxfetchlist(item: any, headers: any) {
    const path = "company/contract/get/";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path + item.id,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  exportVoucherssDatas(
    dealRequestId: number,
    user_type: any,
    UserID: any,
    voucher_type: string
  ) {
    const path = "voucherExports";
    let link =
      environment.ENVIRONMENT.baseURL +
      path +
      "?voucher_request_id=" +
      dealRequestId +
      "&type=" +
      user_type +
      "&user_id=" +
      UserID +
      "&voucher_type=" +
      voucher_type;
    let atag = document.createElement("a");
    atag.href = link;
    atag.download = "Test";
    atag.click();
  }


  exportInventoryDatas(
    dealRequestId: number,
     ) {
    const path = "inventor/voucher/download";
    let link =
      environment.ENVIRONMENT.baseURL +
      path +
      "?file_id=" +
      dealRequestId;
    let atag = document.createElement("a");
    atag.href = link;
    atag.download = "Test";
    atag.click();
  }


  // exportInventoryDatas(dealRequestId: number) {
  //   const path = "inventor/voucher/download";
  //   const url = `${environment.ENVIRONMENT.baseURL}${path}?file_id=${dealRequestId}`;
  
  //   return this.http
  //     .get(url)  // Pass headers directly here, no need for an additional options object
  //     .subscribe({
  //       next: () => {
  //         alert();
  //         const downloadUrl = url; // Assuming the API sends a URL in the response
  
  //         const link = document.createElement("a");
  //         link.href = downloadUrl;
  //         link.download = "voucher_download.csv";  // Set the desired file name
  //         link.click();
  //       },
  //       error: (error) => {
  //         console.error("Download failed", error);
  //         // Optional: Display error message
  //         // this.toastr.error("Failed to download the file. Please try again.");
  //       }
  //     });
  // }
  

  

  getVoucherDownload(
    limit: number,
    skip: number,
    isDownload: boolean,
    role: any,
    start_date: any,
    end_date: any
  ) {
    const path = "/mis/report/download";
    let link: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip +
      "&is_download=" +
      isDownload +
      "&role=" +
      role +(start_date && end_date ? "&start_date=" + start_date + "&end_date=" + end_date : '') 
    let atag = document.createElement("a");
    atag.href = link;
    atag.download = "Test";
    atag.click();
  }

  exportAllVouchers(usertype: string, userId: number, type: string) {
    const path = "voucherExports";
    let link =
      environment.ENVIRONMENT.baseURL +
      path +
      "?type=" +
      usertype +
      "&user_id=" +
      userId +
      "&voucher_type=" +
      type;
    let atag = document.createElement("a");
    atag.href = link;
    atag.download = "Test";
    atag.click();
  }

  exportVoucherDatas(
    dealRequestId: number,
    user_type: any,
    UserID: any,
    voucher_type: string
  ) {
    const path = "voucherExports";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?voucher_request_id=" +
          dealRequestId +
          "&type=" +
          user_type +
          "&user_id=" +
          UserID +
          "&voucher_type=" +
          voucher_type
        //  { responseType: 'blob' });
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  exportVoucherData(
    dealRequestId: number,
    user_type: any,
    UserID: any,
    voucher_type: string,
    headers: any
  ) {
    const path = "voucherExports";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?voucher_request_id=" +
          dealRequestId +
          "&type=" +
          user_type +
          "&user_id=" +
          UserID +
          "&voucher_type=" +
          voucher_type,
        headers
        //  { responseType: 'blob' });
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  // exportAllVoucher(usertype: string, userId: number, type: string,headers :any) {
  //   const path = 'voucherExports';
  //   return this.http.get(environment.ENVIRONMENT.baseURL + path + '?type=' + usertype + '&user_id=' + userId + '&voucher_type=' + type,
  //   //  { responseType: 'blob' }
  //   headers
  //    )
  //    .pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       if (error.status === 401) {
  //         location.reload();
  //       }
  //       return throwError(error);
  //     })
  //   );
  //    ;
  // }

  // exportVoucherData(dealRequestId:number,user_type:any,UserID:any,voucher_type:string) {
  //   const path = 'voucherExports';
  //   return this.http.get(environment.ENVIRONMENT.baseURL + path + "?voucher_request_id=" + dealRequestId + "&type=" + user_type + "&user_id=" + UserID + "&voucher_type=" + voucher_type ,
  //   //  { responseType: 'blob' });
  //   )
  //   .pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       if (error.status === 401) {
  //         location.reload();
  //       }
  //       return throwError(error);
  //     })
  //   );

  //   ;
  // }

  //!--- Voucher Generation List---!

  getVoucherGenerationList(
    id: number,
    limit: number,
    skip: number,
    headers: any
  ) {
    const path = "get_generation_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?request_id=" +
          id +
          "&limit=" +
          limit +
          "&skip=" +
          skip,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getMultigroupGenerationList(id: number, headers: any) {
    const path = "get/group/voucher/";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path + id,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getVoucherGenerationListone(
    id: number,
    limit: number,
    skip: number,
    headers: any,
    action: string,
    created_by_role: string
  ) {
    const path = "get_generation_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?request_id=" +
          id +
          "&limit=" +
          limit +
          "&skip=" +
          skip +
          "&action=" +
          action +
          "&created_by_role=" +
          created_by_role,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getVoucherGenerationListAmazon(
    ids: number,
    limit: number,
    skip: number,
    headers: any,
    action: string
  ) {
    const path = "get_generation_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?request_id=" +
          ids +
          "&limit=" +
          limit +
          "&skip=" +
          skip +
          "&action=" +
          action,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getVoucherGenerationListTE(
    ids: number,
    limit: number,
    skip: number,
    headers: any,
    action: string
  ) {
    const path = "get_generation_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?request_id=" +
          ids +
          "&limit=" +
          limit +
          "&skip=" +
          skip +
          "&action=" +
          action,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getVoucherGenerationLists(
    ids: number,
    limit: number,
    skip: number,
    headers: any,
    action: string
  ) {
    const path = "get_generation_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?request_id=" +
          ids +
          "&limit=" +
          limit +
          "&skip=" +
          skip +
          "&action=" +
          action,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  importUserBulkApproval(postParams: any, headers: any) {
    const path = "request_generation_user_bulk_import";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        //  { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  importproduct(postParams: any, headers: any) {
    const path = "update/external/product/discount";
    return this.http
      .post(environment.ENVIRONMENT.baseURL + path, postParams, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  importClientProduct(postParams: any, headers: any) {
    const path = "/internal/client/product/import";
    return this.http
      .post(environment.ENVIRONMENT.baseURL + path, postParams, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  importBrandProduct(postParams: any, headers: any) {
    const path = "b2c/brand/product/upload";
    return this.http
      .post(environment.ENVIRONMENT.baseURL + path, postParams, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  importCategoryProduct(postParams: any, headers: any) {
    const path = "b2c/category/product/upload";
    return this.http
      .post(environment.ENVIRONMENT.baseURL + path, postParams, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  importbulkemail(postParams: any, headers: any) {
    const path = "upload/delivery/emails";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  // Group Voucher File Upload API-------------!

  groupvoucherfileupload(postParams: any, headers: any) {
    const path = "group/voucher/upload";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }


  groupinventoryfileupload(postParams: any, headers: any) {
    const path = "inventor/voucher/upload";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  importUserDataVoucher(postParams: any, headers: any) {
    // const request = new HttpRequest('POST', `${this.serverUrl}/upload`, formData, {
    //   reportProgress: true,
    //   responseType: 'json'
    // });

    const path = "voucher_request_bulk";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }


  importManualDataVoucher(postParams: any,headers: any) {
    // const request = new HttpRequest('POST', `${this.serverUrl}/upload`, formData, {
    //   reportProgress: true,
    //   responseType: 'json'
    // });

    const path = "voucher/upload";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  importUserDataBrand(postParams: any, headers: any) {
    const path = "voucher_brand_request_bulk";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,

        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  importUserDataDeal(postParams: any, headers: any) {
    const path = "voucher_deal_request_bulk";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        //  { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  exportAllVoucher(
    usertype: string,
    userId: number,
    type: string,
    headers: any
  ) {
    const path = "voucherExports";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?type=" +
          usertype +
          "&user_id=" +
          userId +
          "&voucher_type=" +
          type,
        //  { responseType: 'blob' }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //!--- Approve Voucher ---!

  voucherApproval(postParams: any, headers: any) {
    const path = "request_approve_reject";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  inventoryVouchersApproval(postParams: any, headers: any) {
    const path = "inventor/voucher/request_approve";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //!--- Group Voucher Approve and Reject API Start ---!

  groupvoucherApproval(postParams: any, headers: any) {
    const path = "group/voucher/approve_reject";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //!--- Group Voucher Approve and Reject API End ---!

  getSomeData() {
    return this.http
      .get("/api/some-data")
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  companySync(headers: any) {
    const path = "company_sync";
    return this.http
      .post(environment.ENVIRONMENT.baseURL + path, {}, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  brandSync(headers: any) {
    const path = "brand_sync";
    return this.http
      .post(environment.ENVIRONMENT.baseURL + path, {}, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getTrackOrderReport(postParams: any, headers: any) {
    const path = "TrackOrder/GetTrackOrderReport";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  blockVoucher(postParams: any, headers: any) {
    const path = "voucher_block";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getSupplierList(
    limit: any,
    skip: any,
    supplier_name: any,
    status: any,
    headers: any
  ) {
    const path = "supplier_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&supplier_name=" +
          supplier_name +
          "&status=" +
          status,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  deleteSupplierList(supplierId: any, headers: any) {
    const path = "supplier_delete";
    return this.http
      .delete(
        environment.ENVIRONMENT.baseURL + path + "?supplier_id=" + supplierId,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  addNewSupplier(postParams: any, headers: any) {
    const path = "supplier_create";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateNewSupplier(postParams: any, headers: any) {
    const path = "supplier_update";
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  supplierBulkApproval(postParams: any, headers: any) {
    const path = "supplier_bulk_upload";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        //  { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateproduct(postParams: any, headers: any) {
    const path = "update/admin/product/display";
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateInternalproduct(postParams: any, headers: any) {
    const path = "update/admin/internalProduct/display";
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateSupplier(postParams: any, headers: any) {
    const path = "update/companyBrandType";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  supplierStatus(postParams: any, headers: any) {
    const path = "supplier_status_update";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  clientStatus(postParams: any, headers: any) {
    const path = "internal/company/status";
    return this.http
      .put(environment.ENVIRONMENT.baseURL + path, postParams, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  rewaardkartStatus(postParams: any, headers: any) {
    const path = "email/statusUpdate";
    return this.http
      .put(environment.ENVIRONMENT.baseURL + path, postParams, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  clienProuducttStatus(postParams: any, headers: any) {
    const path = "internal/client/product/status";
    return this.http
      .post(environment.ENVIRONMENT.baseURL + path, postParams, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  walletStatus(postParams: any, headers: any) {
    const path = "internal/client/wallet/status";
    return this.http
      .post(environment.ENVIRONMENT.baseURL + path, postParams, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getSupplierItems(searchText: string, headers: any) {
    const path = "get_supplier_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path + "?search=" + searchText,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getTrackOrderSearch(postParams: any, headers: any) {
    const path = "TrackOrder/GetTrackOrderSearch";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  uploadFile(postParams: any, headers: any) {
    const path = "UploadFile/UploadFile";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        //  { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  set batchData(obj) {
    this.sharingObject = obj;
  }

  get batchData() {
    return this.sharingObject;
  }

  set memberData(obj: any) {
    this.memberObject = obj;
  }

  get memberData() {
    return this.memberObject;
  }

  //!--- Deal Detail---!

  dealDetails(dealDetail: RequestDeals) {
    this.dealDetail.next(dealDetail);
  }

  //grtproducts

  getProdducts(searchText: string, headers: any) {
    const path = "http://44.235.191.111:8882/api/en/search";
    return this.http
      .get(
        path + "?&CustomerID=1111&name=" + searchText + "&userID=9361",

        // {responseType: "json"}
        headers
      )

      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getProductsByBrand(postParams: object, headers: any) {
    const path =
      "http://44.235.191.111:8882/api/en/search?pageIndex=1&pageSize=10000";
    return this.http
      .post(
        path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //get dashboard count values

  getcounts(start_date: any, end_date: any, headers: any, getstartDate: any) {
    const path = "dashboard";
    let URL: any = environment.ENVIRONMENT.baseURL + path;
    if (getstartDate)
      URL = URL + "?start_date=" + start_date + "&end_date=" + end_date;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getcount(
    user_id: any,
    start_date: any,
    end_date: any,
    headers: any,
    getstartDate: any
  ) {
    const path = "dashboard";
    let URL: any =
      environment.ENVIRONMENT.baseURL + path + "?user_id=" + user_id;
    if (getstartDate)
      URL = URL + "&start_date=" + start_date + "&end_date=" + end_date;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //!--- Add New Deal ---!

  dealRequest(postParams: any, headers: any) {
    const path = "deal_request_save";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )

      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //!--- Approve Deal ---!

  dealApproval(postParams: any, headers: any) {
    const path = "deal_request_approve_reject";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  importUserData(postParams: any, headers: any) {
    const path = "voucher_brand_request_bulk";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        //  { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  exportDealData(dealRequestId: number, headers: any) {
    const path = "voucherExports";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?voucher_request_id=" +
          dealRequestId,
        //  { responseType: 'blob' }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //!--- Deal Generation List---!

  getDealGenerationList(id: number, limit: number, skip: number, headers: any) {
    const path = "deal_generation_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?request_id=" +
          id +
          "&limit=" +
          limit +
          "&skip=" +
          skip,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  exportbrandData(brandRequestId: number, headers: any) {
    const path = "voucherExports";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?voucher_request_id=" +
          brandRequestId,
        //  { responseType: 'blob' }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  set pageNo(num: any) {
    this.pageNumber = num;
  }

  get pageNo() {
    return this.pageNumber;
  }

  //!--- brand Detail---!

  brandDetails(brandDetail: RequestBrand) {
    this.brandDetail.next(brandDetail);
  }
  clientDetails(clientDetail: any) {
    this.clientDetail.next(clientDetail);
  }

  getClientName(): Observable<any> {
    return this.clientDetail.asObservable();
  }

  getClientwallet(): Observable<any> {
    return this.clientDetailwallet.asObservable();
  }

  clientDetailswallet(clientDetailwallet: any) {
    this.clientDetailwallet.next(clientDetailwallet);
  }

  clientDetailsproduct(clientDetail: any) {
    this.clientDetail.next(clientDetail);
  }

  getClientNameproduct(): Observable<any> {
    return this.clientDetail.asObservable();
  }

  // clientDetailswallet(clientDetail: any) {
  //   this.clientDetail.next(clientDetail);
  // }

  getClientNamewallet(): Observable<any> {
    return this.clientDetail.asObservable();
  }

  getbrandKartList(
    limit: number,
    skip: number,
    company: string,
    brand: string,
    approved: string,
    type: string,
    user_type: string,
    user_id: string,
    headers: any
  ) {
    const path = "get_brand_request_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&Company=" +
          company +
          "&Brand=" +
          brand +
          "&Approved=" +
          approved +
          "&type=" +
          type +
          "&user_type=" +
          user_type +
          "&user_id=" +
          user_id,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //!--- Add New brand ---!

  brandRequest(postParams: any, headers: any) {
    const path = "brand_request";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //!--- Approve brand ---!

  brandApproval(postParams: any, headers: any) {
    const path = "brand_request_approve_reject";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  blockCoupon(postParams: any, headers: any) {
    const path = environment.ENVIRONMENT.baseURL + "voucher_block";
    return this.http
      .post(
        path,
        postParams,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  exportAllbrand(usertype: string, userId: number, headers: any) {
    const path = "voucherExports";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?type=" +
          usertype +
          "&user_id=" +
          userId,
        // { responseType: 'blob' }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  //!--- brand Generation List---!

  getbrandGenerationList(
    id: number,
    limit: number,
    skip: number,
    headers: any
  ) {
    const path = "get_brand_generation_list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?request_id=" +
          id +
          "&limit=" +
          limit +
          "&skip=" +
          skip,
        // { responseType: "json" }
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getviewlistInven(page: any,refno:any,headers: any) {
    const path = "inventor/voucher/list/" +
    "?page=" +
    page +
    "&ref_no=" +
    refno;
    return this.http.get(
      environment.ENVIRONMENT.baseURL + path,
      // { responseType: "json" }
      headers
    );

    // const path = '/voucher/get';
    // let URL:any=environment.ENVIRONMENT.baseURL + path
    // if(idval)URL=URL+ "?Vocuher_request_id=" + idval;
    // return this.http.get(
    //   URL,
    //   headers
    // );
  }

  getviewlist(idval: any, headers: any) {
    const path = "voucher/get/" + idval;
    return this.http.get(
      environment.ENVIRONMENT.baseURL + path,
      // { responseType: "json" }
      headers
    );

    // const path = '/voucher/get';
    // let URL:any=environment.ENVIRONMENT.baseURL + path
    // if(idval)URL=URL+ "?Vocuher_request_id=" + idval;
    // return this.http.get(
    //   URL,
    //   headers
    // );
  }

  getClientDetails(headers: any, id: number) {
    const path = `internal/company/get?id=${id}`;
    return this.http.get(environment.ENVIRONMENT.baseURL + path, headers);
  }

  //payment settings api

  getsettingslist(headers: any) {
    const path = "get/settings";
    return this.http.get(
      environment.ENVIRONMENT.baseURL + path,
      // { responseType: "json" }
      headers
    );
  }

  getFlkrtBalance(headers:any) {
    const path = "get/flipkart/balance";
    return this.http.get(environment.ENVIRONMENT.baseURL + path, headers);
  }
  getEzPin(headers:any) {
    const path = "EZPINBlanceCheck";
    return this.http.get(environment.ENVIRONMENT.baseURL + path, headers);
  }
  getDtPin(headers:any) {
    const path = "dt_one/balance";
    return this.http.get(environment.ENVIRONMENT.baseURL + path, headers);
  }
  getyggBal(headers:any) {
    const path = "you_gota_gift/balance";
    return this.http.get(environment.ENVIRONMENT.baseURL + path, headers);
  }
  getamazonBal(headers:any) {
    const path = "amazon/balance";
    return this.http.get(environment.ENVIRONMENT.baseURL + path, headers);
  }
  getJoyalukkasBal(headers:any) {
    const path = "joyalukkas/balance";
    return this.http.get(environment.ENVIRONMENT.baseURL + path, headers);
  }
  httpGet(path:string, headers:any) {
    return this.http.get(environment.ENVIRONMENT.baseURL + path, headers);
  }
  chartData(headers:any) {
      let path = "ExternalDashboard";
      return this.http.get(environment.ENVIRONMENT.baseURL + path, headers);
  }
  chartintData(headers:any) {
    let path = "InternalDashboard";
    return this.http.get(environment.ENVIRONMENT.baseURL + path, headers);
}


  getsettingsmenulist(limit: number, skip: number, headers: any) {
    const path = "voucher/payment/history/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip;
    return this.http
      .get(URL, headers)

      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getWalletAmount(userId: number, headers: any) {
    const path = "/voucherkart/get/wallet";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL + path + "?userId=" + userId,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getWalletRefresh(userId: number, role: any, walletId: number, headers: any) {
    const path = "/walletZero";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?clientId=" +
          walletId +
          "&role=" +
          role +
          "&id=" +
          userId,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getDigitalVoucherList(
    company: string,
    limit: number,
    skip: number,
    headers: any
  ) {
    const path = "/internal/company/list";
    return this.http
      .get(
        environment.ENVIRONMENT.baseURL +
          path +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&search=" +
          company,
        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getVoucherClear(limit: number, skip: number, headers: any, role: any) {
    // const path = "/get/digital/voucher/report";
    const path = "/mis/list"
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip +
      "&role=" +
      role;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getVoucherReport(
    limit: number,
    skip: number,
    headers: any,
    role: any,
    start_date?: any,
    end_date?: any,
    type?:string,
    clientType?:string,
    name?:string
  ) {
    // const path = "/get/digital/voucher/report";
    const path = "/mis/list";
    let dateParam = "";
    if ((role === "admin" || role === "SuperAdmin") && start_date && end_date){
       dateParam = "&start_date=" + start_date + "&end_date=" + end_date;
    }
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip + (dateParam ? dateParam : '') + (type ? "&payment_type=" + type : '') + (clientType ? "&client_type=" + clientType : '') + (name ? "&client_name=" + name : '');
    // if ((role === "admin" || role === "SuperAdmin") && start_date && end_date)
    //   URL = URL + "&start_date=" + start_date + "&end_date=" + end_date;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getExternalReport(limit: number, skip: number, headers: any) {
    const path = "get/external/category/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getExternalBrand(limit: number, skip: number, headers: any) {
    const path = "get/external/brand/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getproductBrandlist(
    paramValue: any,
    limit: number,
    skip: number,
    headers: any
  ) {
    const path = "b2c/brand/product/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?brand_id=" +
      paramValue +
      "&limit=" +
      limit +
      "&skip=" +
      skip;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getproductCategorylist(
    paramValue: any,
    limit: number,
    skip: number,
    headers: any
  ) {
    const path = "b2c/category/product/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?category_id=" +
      paramValue +
      "&limit=" +
      limit +
      "&skip=" +
      skip;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getB2cBrand(limit: number, skip: number, headers: any) {
    const path = "b2c/brand/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getB2ccategory(limit: number, skip: number, headers: any) {
    const path = "b2c/category/list";
    let URL: any =
      environment.ENVIRONMENT.baseURL +
      path +
      "?limit=" +
      limit +
      "&skip=" +
      skip;
    return this.http
      .get(
        URL,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getExternaleditlist(postParam: any, headers: any) {
    const path = `external/category/update`;
    // const path = 'get/category';
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParam,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getExternalBrandeditlist(postParam: any, headers: any) {
    const path = `external/brand/update`;
    // const path = 'get/category';
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParam,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getExternalProductlist(postParam: any, headers: any) {
    const path = `external/product/update`;
    // const path = 'get/category';
    return this.http
      .put(
        environment.ENVIRONMENT.baseURL + path,
        postParam,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateorderamount(Params: any, headers: any) {
    const path = "update/settings";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        Params,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  importproductsku(postParams: any, headers: any) {
    const path = "update/external/product/sku";
    return this.http
      .post(environment.ENVIRONMENT.baseURL + path, postParams, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }
  B2CBrandUpdate(postParams: any, headers: any) {
    const path = "/b2c/brand/status";
    return this.http
      .put(environment.ENVIRONMENT.baseURL + path, postParams, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  B2CCategoryUpdate(postParams: any, headers: any) {
    const path = "/b2c/category/status";
    return this.http
      .put(environment.ENVIRONMENT.baseURL + path, postParams, headers)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  saveEmails(Params: any, headers: any) {
    const path = "/AddDeliveryEmails";
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        Params,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  updateSku(Params: any, headers: any, id:any) {
    const path = `/get/admin/product/update_sku/${id}`;
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        Params,

        headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  reGeneration(headers: any, id:any) {
    const path = `/re_generate_order/${id}`;
    return this.http
      .get(environment.ENVIRONMENT.baseURL + path, headers).pipe(catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  set setProducts(data: any) {
    this.sharingObjectData = data;
  }

  get getProduct() {
    return this.sharingObjectData;
  }

  addRewardkartClient(url:string, Params: any, headers: any) {
    const path = url;
    return this.http
      .post(
        environment.ENVIRONMENT.baseURL + path,
        Params, headers
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  getReClient(headers: any) {
    const path = `/client/discount/list`;
    return this.http
      .get(environment.ENVIRONMENT.baseURL + path, headers).pipe(catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            localStorage.removeItem("logged");
            location.reload();
          }
          return throwError(error);
        })
      );
  }

  exportMultiple(role: string, userId: number) {
    let path : string = "";
    if (role.toLowerCase() === 'company') {
       path = `${environment.ENVIRONMENT.baseURL}MultipulVoucherExports?user_type=${role}&user_id=${userId}`;
    } else {
       path = `${environment.ENVIRONMENT.baseURL}MultipulVoucherExports?user_type=${role}`;
    }
    let atag = document.createElement("a");
    atag.href = path;
    atag.download = "Test";
    atag.click();
  }
}
