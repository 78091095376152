import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { INgxMyDpOptions, IMyDateModel } from '@mksasi/ngx-mydatepicker';
import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppService } from '../restful-services/restful.service';
import { environmentfiles } from 'src/environments/environment';
import { MultiDenomination } from '../models/MultiDenomination';
import { Modal } from 'bootstrap';
import { Supplier } from '../models/supplierList';
import { browserRefresh } from '../app.component';
import { NgxPaginationModule } from 'ngx-pagination';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-denomination-dashboard',
  templateUrl: './denomination-dashboard.component.html',
  styleUrls: ['./denomination-dashboard.component.scss']
})
export class DenominationDashboardComponent implements OnInit {
  public company: string = "";
  public brand: string = "";
  public approved: string = "";
  public type: string = "";
  public modal: string = "";
  public category: string = "";
  limit: number = 25;
  skip: number = 0;
  c: number = 1;
  count: number = 0;
  @ViewChild('inputFile') inputFile: any;
  user_type: any;
  userDetails: any;
  supplierType: any;
  approvalVoucherList: any = [];
  voucherStatus: string = "";
  approvalStatus: string = '';
  supplierImportType: string = '';
  lastvalue: number = 0;
  apiLoading: boolean = false;
  private headers: any = {};
  urlError: boolean = false;
  productURL: string = '';
  created_by_name: any;
  status: any;
  public role: any = 'company';
  postparams: any;
  fileurls: any;
  multidenomilist: any;
  supplierName: any;
  requestGroupVoucher!: MultiDenomination;
  showApproveReject: boolean = false;
  closeBtn: any;
  rejectedStatus: string = '';
  supplierForm: any;
  settingvalue: any;
  id: any;
  voucher_request_id: any;
  getid: any = [];
  getgroupid: any;
  supplierDetail: any;
  browserRefresh: boolean = false;
  tooltipList: any;
  created_by_role:any;

  constructor(private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {

    this.supplierDetail = new Supplier();
    this.requestGroupVoucher = new MultiDenomination();

  }
  ngOnInit() {

    this.role = localStorage.getItem('role');
    console.log(this.role);
    
    let user = localStorage.getItem("userDetails");
    
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }
    this.created_by_role = this.userDetails.role;
    let page: any = localStorage.getItem('pagesNo');
    if (page !== null) {
      this.pageChanged(page);
    }else{
    this.getMultiDenominationRequestList();
    }
    
    

    this.fileurls = environmentfiles.ENVIRONMENT.baseURL;


    // if (page !== null) {
    //   this.pageChanged(page);
    // }
    // else {
    //   this.getMultiDenominationRequestList();
    // }

    this.browserRefresh = browserRefresh;
    if (this.browserRefresh === true) {
      this.c = 1;
      this.pageChanged(this.c);
    }

  }


  getMultiDenominationRequestList() {

    if (this.role === "admin" || this.role ==="SuperAdmin") {
      this.user_type = "ALL"
    }
    else if (this.role === "company" || this.role === "brand") {
      this.user_type = 'Restricted';
    }
    if (this.role === "company") {
      this.type = 'ID';
      // this.company = this.userDetails.UserName;
    }
    else if (this.role === "brand") {
      this.type = '';
      this.brand = this.userDetails.UserName;
    }
    this.loader.start();
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    
    this.apiLoading = true;
    this._http.getMultiDenominationList(this.limit, this.skip, this.approved,this.company,this.user_type,this.userDetails.UserID,"voucher",this.created_by_role, this.headers).subscribe({
      next: (data: any) => {
        this.multidenomilist = data.data;
        this.loader.stop();
        if (data) {
          this.count = data.count;
          this.apiLoading = false;
          // this.toolTipInit();
          if (this.skip == 0) {
            this.lastvalue = this.skip + 10;
          }
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })
  }



  filterList() {

    if (this.approved || this.type || this.category || this.brand || this.company) {
      this.skip = 0;
      this.c = 1;
    }
    this.getMultiDenominationRequestList();
  }

  groupbulkimportfileupload(event: any) {

    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';

    if ((getImg.toLowerCase() !== '.csv')) {
      this.toastr.error("File not supported");
      return;
    } else {
    }

    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set('Accept', "multipart/form-data");
      const formData: any = new FormData();
      formData.append('voucherfile', event.target.files[0]);
      formData.append('created_by_role', this.created_by_role);
      formData.append('created_by_name', this.userDetails.entity_name);
      formData.append('created_by', this.userDetails.UserID);
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          //  'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
      this._http.groupvoucherfileupload(formData, this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();
          if (data.status === 1) {
            this.toastr.success(data.msg);
            this.getMultiDenominationRequestList();
          }
          else if (data.status === 0) {
            this.toastr.error(data.msg);
          }

          this.inputFile.nativeElement.value = '';
         
        },
        error: (error: any) => {
          this.loader.stop();
          this.toastr.error(error.Message);
        }
      })
    }
  }

  pageChanged(pagesNo: any) {

    this.c = pagesNo;
    this.skip = (pagesNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem('pagesNo', pagesNo);
    this.getMultiDenominationRequestList();
  }

  clear() {

    this.company = '';
    if (this.role === 'company') {
      this.type = "ID";
    }
    else if (this.role === 'brand') {
      this.type = "I";
    }
    else {
      this.type = '';
    }
    this.approved = '';
    this.approvalVoucherList = [];
    this.getMultiDenominationRequestList();
    this.c = 1;
    let page = this.c;
    this.pageChanged(page);
  }


  rejectshowModal(rejectedStatus: string, voucher: MultiDenomination) {
    this.getgroupid = voucher.id;
    this.approvalVoucherList.push(this.getgroupid);
    this.rejectedStatus = rejectedStatus;
    const element = document.getElementById('rejectModal') as HTMLElement;
    const rejectModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    rejectModal.show();
  }

  approveshowModal(approvalStatus: string, voucher: MultiDenomination) {
    this.getgroupid = voucher.id;
    this.approvalVoucherList.push(this.getgroupid);
    this.approvalStatus = approvalStatus;
    const element = document.getElementById('approveModals') as HTMLElement;
    const approveModals = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
    this.urlError = false;
    this.productURL = '';
    approveModals.show();

  }

  Rejectbrand(requestGroupVoucher: MultiDenomination) {

    const params = {
      "voucher_group_id": this.getgroupid,
      "status": this.rejectedStatus,
      "created_by": this.userDetails.UserID,
      "updated_by_role": this.userDetails.role,
      "approved_by_name": this.userDetails.UserName

    }
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    this.loader.start();
    this._http.groupvoucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        // this.closeBtn.nativeElement.click();
        // this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            if (this.rejectedStatus == 'R') {
              this.toastr.success("Voucher rejected successfully");
              this.showApproveReject = false;
            }
            else {
              this.toastr.success("Vouchers approved successfully");
              this.showApproveReject = false;
            }
          }
          else {
            if (data.statu == 2) {
              this.toastr.success(data.msg);
              this.toastr.info("Voucher has been already approved or generated");
              this.showApproveReject = false;
            }
          }
          this.approvalVoucherList = [];
          this.getMultiDenominationRequestList();
        }
      },
      error: (error: any) => {
        this.loader.stop();
        this.toastr.error(error.Message);
      }
    })
  }

  approveVouchers() {

    const params = {
      "voucher_group_id": this.getgroupid,
      "status": this.approvalStatus,
      "created_by": this.userDetails.UserID,
      "updated_by_role": this.userDetails.role,
      "approved_by_name": this.userDetails.UserName
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.groupvoucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();
        // this.closeBtn.nativeElement.click();
        // this.showApproveReject = false;
        if (data && data.status == 1) {
            if (this.approved == 'A' || this.approved == 'Y') {
              this.toastr.success("Voucher approved successfully");
              this.showApproveReject = false;
            }
            else if (this.rejectedStatus == 'R') {
              this.toastr.error("Voucher rejected");
              this.showApproveReject = false;
            }
            else {
              this.toastr.success("Vouchers approved successfully");
              this.showApproveReject = false;
          }
        }
          else {
            if (data.status == 2) {
              this.toastr.success(data.msg);
              this.toastr.info("Voucher has been already approved or generated");
              this.showApproveReject = false;
            }
          }
          this.approvalVoucherList = [];
          this.getMultiDenominationRequestList();
   
      },
      error: error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    })
  }



  approveVoucher() {

    const params = {
      "voucher_group_id": this.getgroupid,
      "status": this.approvalStatus,
      "created_by": this.userDetails.UserID,
      "updated_by_role": this.userDetails.role,
      "approved_by_name": this.userDetails.UserName
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this._http.groupvoucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();
        // this.closeBtn.nativeElement.click();
        // this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            console.log("toaster", data.msg)
            this.toastr.success(data.msg);
            if (this.approved == 'A' || this.approved == 'Y') {
              this.toastr.success("Voucher approved successfully");
              this.showApproveReject = false;
            }
            else if (this.rejectedStatus == 'R') {
              this.toastr.error("Voucher rejected");

              this.showApproveReject = false;
            }
            else {
              this.toastr.success("Vouchers approved successfully");
              this.showApproveReject = false;
            }
          }
          else {
            if (data.status == 2) {
              this.toastr.success(data.msg);
              this.toastr.info("Voucher has been already approved or generated");
              this.showApproveReject = false;
            }
          }
          this.approvalVoucherList = [];
          this.getMultiDenominationRequestList();
        }
      },
      error: error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    })
  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover' })
      })
    }, 500)
  }


  viewGenerationgroupList(requestGroupVoucherlist: MultiDenomination) {
    this._http.voucherGroupDetails(requestGroupVoucherlist);
    console.log(requestGroupVoucherlist);
    const id = requestGroupVoucherlist.id;
    console.log(id);
    localStorage.setItem('voucher_group_id', JSON.stringify(requestGroupVoucherlist));
    this.router.navigate(['/denomination-order-viewlist'], {
      queryParams: { voucher_group_id: id },
      state: { id },
      replaceUrl: true
    });
    localStorage.removeItem('logvalue');
  }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  export() {
    this._http.exportMultiple(this.role,this.userDetails.UserID);
  }



}
