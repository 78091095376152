<h6 class="font-18 font-weight-400 mt-4 mb-3 text-left product-heading title">
  Settings
</h6>
<div class="card">
  <div class="card-body">
    <form
      #settingRequestForm="ngForm"
      name="form"
      (ngSubmit)="Updateorderamount(RequestSetting)">
      <div class="row flex-wrap">
        <div class="col-3 no-icon">
          <div class="md-form form-sm my-3">
            <label for="order_amount" class="mb-2"
              >Admin Approve Limit
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              [attr.maxlength]="20"
              [(ngModel)]="RequestSetting.order_amount"
              placeholder="Enter Admin Approve Limit"
              name="brand_name"
              (keypress)="onKeyPresscomma($event)"
              #order_amount="ngModel"
              mdbInput
              autocomplete="off"
              (input)="restrictDecimals($event)"
              (keypress)="
                onKeyPressspecialcharacters($event);
                onKeyPresscomma($event);
                numberOnly($event);
                restrictAlphabets($event)
              "
              (keydown)="keyDownHandler($event)"
              required />

            <span
              class="text-danger"
              *ngIf="
                            (order_amount.touched || settingRequestForm.submitted) &&
                            order_amount.errors?.['required']
                          ">
              admin approve limit is required
            </span>
          </div>
        </div>

        <div *ngIf="this.amountval" class="col-2 no-icon pt-5">
          <div class="md-form form-sm">
            <button class="btn btn-success" type="submit">Update</button>
          </div>
        </div>

        <div *ngIf="!this.amountval" class="col-2 no-icon pt-5">
          <div class="md-form form-sm">
            <button class="btn btn-success" type="submit">Save</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="md-form form-sm my-3">
            <label for="order_amount" class="mb-2">FlipKart Wallet Balance</label>
            <div>
              <strong>&#8377; {{ flipKartBalance }}</strong>
              <span
                class="ms-4 btn btn-sm btn-success"
                (click)="getFlipkartBalance()"
                ><i class="fa fa-refresh"></i> Refresh</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf="ezpinBalance">
          <div class="row">
            <div class="col align-self-center">
              <div class="md-form form-sm my-3">
                <label for="type">EZPIN Balance</label>
                    <select id="type" class="form-select" name="type">
                      <option [value]="value.currency.code + '-' + value.balance" *ngFor="let value of balanceDatas" selected>{{value.currency.code + '-' + value.balance}}</option>
                    </select>
                </div>
            </div>
            <div class="col pt-4 align-self-center">
              <span class="btn btn-sm btn-success" (click)="getEzPinBal()"><i class="fa fa-refresh"></i> Refresh</span>
            </div>
          </div>
          
        </div>
        <div class="col-md-4">
          <div class="md-form form-sm my-3">
            <label for="order_amount" class="mb-2">DT ONE Wallet Balance</label>
            <div>
              <strong>{{dtUnit + '-' + dtBal }}</strong>
              <span
                class="ms-4 btn btn-sm btn-success"
                (click)="getdtBal()"
                ><i class="fa fa-refresh"></i> Refresh</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf="bambooBalance && bambooBalance.length > 0">
          <div class="row">
            <div class="col align-self-center">
              <div class="md-form form-sm my-3">
                <label for="type">Bamboo Balance</label>
                    <select id="type" class="form-select" name="type">
                      <option [value]="value.currency + '-' + value.balance" *ngFor="let value of bambooBalance" selected>{{value.currency + '-' + value.balance}}</option>
                    </select>
                </div>
            </div>
            <div class="col pt-4 align-self-center">
              <span class="btn btn-sm btn-success" (click)="getEzPinBal()"><i class="fa fa-refresh"></i> Refresh</span>
            </div>
          </div>
          
        </div>
        <div class="col-md-3" *ngIf="valuedesign">
          <div class="md-form form-sm my-3">
            <label for="order_amount" class="mb-2">Value Design</label>
            <div>
              <strong>{{'₹' + ' ' + ValueDesignBalance }}</strong>
              <span
                class="ms-4 btn btn-sm btn-success"
                (click)="josBalannce()"
                ><i class="fa fa-refresh"></i> Refresh</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="md-form form-sm my-3">
            <label for="order_amount" class="mb-2">YGG Wallet Balance</label>
            <div>
              <strong>{{yggCurrency + '-' + yggBal }}</strong>
              <span
                class="ms-4 btn btn-sm btn-success"
                (click)="yggBalance()"
                ><i class="fa fa-refresh"></i> Refresh</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="md-form form-sm my-3">
            <label for="order_amount" class="mb-2">LikeCard Wallet Balance</label>
            <div>
              <strong>{{likecardCurrency + '-' + likeCardBal }}</strong>
              <span
                class="ms-4 btn btn-sm btn-success"
                (click)="likeCardBalance()"
                ><i class="fa fa-refresh"></i> Refresh</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="md-form form-sm my-3">
            <label for="order_amount" class="mb-2">{{source}} Wallet Balance</label>
            <div>
              <strong>{{'₹' + ' ' + josBalance }}</strong>
              <span
                class="ms-4 btn btn-sm btn-success"
                (click)="josBalannce()"
                ><i class="fa fa-refresh"></i> Refresh</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-9" *ngIf="amazon">
          <div class="row">
            <div class="col align-self-center">
              <div class="md-form form-sm my-3">
                <label for="amazon">Amazon Wallet Balance</label>
                    <select id="amazon" class="form-select" name="amazon">
                      <option [value]="value.name + '-' + value.balance" *ngFor="let value of amazonDatas" selected>{{value.name + '-' + value.balance}}</option>
                    </select>
                </div>
            </div>
            <div class="col pt-4 align-self-center">
              <span class="btn btn-sm btn-success" (click)="amazonBalance()"><i class="fa fa-refresh"></i> Refresh</span>
            </div>
          </div>
          
        </div>
      </div>
        
      
    </form>
  </div>
</div>
<div class="card mt-2">
  <div class="card-body">
    <form
      #emailAddress="ngForm"
      name="form"
      (ngSubmit)="UpdateEmailSettings(DeliveryEmail)">
      <div class="row flex-wrap">
        <div class="col-6 no-icon">
          <div class="md-form form-sm my-3">
            <label class="mb-2"
              >Success To Email List (comma-separated) :
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="DeliveryEmail.success_email_list"
              placeholder="Enter the success delivery emails."
              #success_email="ngModel"
              name="successEmailToList"
              mdbInput
              autocomplete="off"
              required />
            <span
              class="text-danger"
              *ngIf="
                            (success_email.touched || emailAddress.submitted) &&
                            success_email.errors?.['required']
                          ">
              Atleast one E-Mail is required
            </span>
          </div>
        </div>
        <div class="col-6 no-icon">
          <div class="md-form form-sm my-3">
            <label class="mb-2"
              >Failure To Email list (comma-separated) :
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="DeliveryEmail.failure_email_list"
              placeholder="Enter failure delivery Emails."
              #failure_email="ngModel"
              mdbInput
              name="failureEmailToList"
              autocomplete="off"
              required />

            <span
              class="text-danger"
              *ngIf="
                            (failure_email.touched || emailAddress.submitted) &&
                            failure_email.errors?.['required']
                          ">
              Atleast one E-Mail is required
            </span>
            <!-- <span *ngIf="emailAddress.get('emailAddresses').hasError('invalidEmails')"> -->
            <!-- Please enter valid email addresses. -->
            <span
              class="text-danger"
              *ngIf="(failure_email.touched || emailAddress.submitted) &&
                            failure_email.errors?.['commaSeparatedEmailsValidator']"
              >Please enter valid email addresses.
            </span>
          </div>
        </div>
      </div>
      <div class="row flex-wrap">
        <div class="col-6 no-icon">
          <div class="md-form form-sm my-3">
            <label class="mb-2"
              >Success CC Email List (comma-separated) :
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="DeliveryEmail.success_email_list_cc"
              placeholder="Enter the success delivery emails."
              #success_email_cc="ngModel"
              name="successEmailCCList"
              mdbInput
              autocomplete="off"
              required />
            <span
              class="text-danger"
              *ngIf="
                            (success_email_cc.touched || emailAddress.submitted) &&
                            success_email_cc.errors?.['required']
                          ">
              Atleast one E-Mail is required
            </span>
          </div>
        </div>
        <div class="col-6 no-icon">
          <div class="md-form form-sm my-3">
            <label class="mb-2"
              >Failure CC Email list (comma-separated) :
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="DeliveryEmail.failure_email_list_cc"
              placeholder="Enter failure delivery Emails."
              #failure_email_cc="ngModel"
              mdbInput
              name="failureEmailCCList"
              autocomplete="off"
              required />

            <span
              class="text-danger"
              *ngIf="
                            (failure_email_cc.touched || emailAddress.submitted) &&
                            failure_email_cc.errors?.['required']
                          ">
              Atleast one E-Mail is required
            </span>
            <!-- <span *ngIf="emailAddress.get('emailAddresses').hasError('invalidEmails')"> -->
            <!-- Please enter valid email addresses. -->
            <span
              class="text-danger"
              *ngIf="(failure_email_cc.touched || emailAddress.submitted) &&
                            failure_email_cc.errors?.['commaSeparatedEmailsValidator']"
              >Please enter valid email addresses.
            </span>
          </div>
        </div>
      </div>
      <div class="md-form form-sm text-center">
        <button class="btn btn-success" type="submit">Save</button>
      </div>
    </form>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
