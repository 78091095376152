<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
      <div class="card-body pb-4">
        <div class="row mb-4">
          <div class="col-4">
            <label class="texts">Digital Voucher Generation List</label>
          </div>
          <div class="text-end col-8" *ngIf="voucherGenerationList.length > 0">
            <a
              href="{{ this.fileurls }}/sample/generation_sample_user_import.csv"
              class="add_bt btn btn-success mr-10"
              type="button"
              target="_self"
              download="sample-file.csv">
              <i class="fa fa-download pr-1"></i> Sample file
            </a>
            <label
              class="rounded px-4 btalig bg-success text-center border-0 labelText mr-10"
              for="inputFile">
              <input
                type="file"
                accept=".csv"
                class="form-control d-none"
                id="inputFile"
                #inputUserFile
                (change)="importUserBulkApproval($event)" />
              <i class="fa fa-upload pr-1"></i> Import</label
            >
          </div>
          <div class="text-end col-8" *ngIf="voucherGenerationList.length === 0">
            <button class="btn btn-success me-3" *ngIf="reGenerate" (click)="reGenerations()">Re-Generate</button>
            <button
              (click)="showSupplierModal('A')"
              class="btn btn-success mr-2"
              type="button"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Accept Request List"
              *ngIf="
                (role === 'admin' || role === 'SuperAdmin') &&
                approvehide &&
                (requestCouponVoucher.status == 'Y' ||
                  requestCouponVoucher.status == 'R')
              ">
              Approve
            </button>
  
            <button
              (click)="showModa('R')"
              class="btn btn-primary ms-2"
              type="button"
              *ngIf="
                (role === 'admin' || role === 'SuperAdmin') &&
                rejecthide &&
                requestCouponVoucher.status == 'Y'
              "
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Reject Request List">
              Reject
            </button>
          </div>
  
          <div class="col-12 card border-0 shadow-sm px-0">
            <div class="card-body bg-light pb-4">
              <div class="row" style="line-height: 50px">
                <div class="col-md-3">
                  <label>Reference No: </label>
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    ">
                    {{ requestCouponVoucher.voucher_request_id }}
                  </span>
                </div>
                <div class="col-md-3 text-truncate">
                  <label>Client: </label>
                  <span
                    class="mr-5 text-truncate"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    title="{{ requestCouponVoucher.company_name }}"
                    >{{ requestCouponVoucher.company_name }}
                  </span>
                </div>
                <div
                  class="col-md-3"
                  *ngIf="role === 'admin' || role === 'SuperAdmin'">
                  <label>Source: </label>
                  <span
                    class="mr-5 text-truncate"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    >{{ requestCouponVoucher.source }}
                  </span>
                </div>
  
                <div class="col-md-3 text-truncate">
                  <label>{{requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard' ? 'Brand:' : 'Product:'}}</label>
                  <span
                    class="mr-5 text-truncate"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    title="{{ requestCouponVoucher.brand_name }}"
                    >{{ requestCouponVoucher.brand_name }}
                  </span>
                </div>
  
                <div class="col-md-3">
                  <label
                    >Issuance Fee:
                    <span
                      [ngClass]="
                        role == 'admin' || role === 'SuperAdmin'
                          ? 'advcolor'
                          : role == 'company'
                          ? 'sides'
                          : 'side'
                      "
                      style="font-size: larger"
                      *ngIf="requestCouponVoucher.currency_symbol">
                      {{ requestCouponVoucher.currency_symbol }}</span
                    ></label
                  >
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    >{{
                      requestCouponVoucher.currency_symbol
                        ? requestCouponVoucher.issuance_fee
                        : (requestCouponVoucher.issuance_fee | currency : "INR")
                    }}
                  </span>
                </div>
  
                <div class="col-md-3">
                  <label
                    >Denomination:
                    <span
                      [ngClass]="
                        role == 'admin' || role === 'SuperAdmin'
                          ? 'advcolor'
                          : role == 'company'
                          ? 'sides'
                          : 'side'
                      "
                      style="font-size: larger"
                      *ngIf="requestCouponVoucher.currency_symbol">
                      {{ requestCouponVoucher.currency_symbol }}</span
                    ></label
                  >
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    >{{
                      requestCouponVoucher.currency_symbol
                        ? requestCouponVoucher.denomination
                        : (requestCouponVoucher.denomination | currency : "INR")
                    }}
                  </span>
                </div>
  
                <div
                  class="col-md-3"
                  *ngIf="requestCouponVoucher.kart_type === '0'">
                  <label>Discount: </label>
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    >{{ requestCouponVoucher.discount }} %
                  </span>
                </div>
  
                <div
                  class="col-md-3"
                  *ngIf="requestCouponVoucher.kart_type === '1'">
                  <label>Discount: </label>
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    >{{ requestCouponVoucher.discount | currency : "INR" }}
                  </span>
                </div>
  
                <div class="col-md-3 text-truncate">
                  <label
                    >Total:
                    <span
                      [ngClass]="
                        role == 'admin' || role === 'SuperAdmin'
                          ? 'advcolor'
                          : role == 'company'
                          ? 'sides'
                          : 'side'
                      "
                      style="font-size: larger"
                      *ngIf="requestCouponVoucher.currency_symbol">
                      {{ requestCouponVoucher.currency_symbol }}</span
                    ></label
                  >
                  <span
                    class="mr-5 text-truncate"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    " title="{{ requestCouponVoucher.total }}">
                    
                    {{
                      requestCouponVoucher.currency_symbol
                        ? requestCouponVoucher.total
                        : (requestCouponVoucher.total | currency : "INR")
                    }}
                  </span>
                </div>
  
                <div class="col-md-3">
                  <label>Quantity: </label>
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    >{{ requestCouponVoucher.quantity }}
                  </span>
                </div>
  
                <div class="col-md-3">
                  <label>Type: </label>
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    >{{ requestCouponVoucher.category }}
                  </span>
                </div>
  
                <div class="col-md-3 text-truncate">
                  <label>Model: </label>
                  <span
                    class="text-truncate mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    >{{
                      requestCouponVoucher.type == "ID"
                        ? "Issuance & Distribution"
                        : requestCouponVoucher.type === "I"
                        ? "Issuance"
                        : ""
                    }}
                  </span>
                </div>
  
                <div
                  class="col-md-3 text-truncate"
                  *ngIf="
                    requestCouponVoucher.source !== 'FlipKart' &&
                    requestCouponVoucher.source !== 'QwikCilver' &&
                    requestCouponVoucher.source !== 'Amazon' &&
                    requestCouponVoucher.source !== 'Ticket Express' &&
                    requestCouponVoucher.source !== 'EZPIN' &&
                    requestCouponVoucher.source !== 'DT' &&
                    requestCouponVoucher.source !== 'MEA' && requestCouponVoucher.source !== 'YGG'  && requestCouponVoucher.source !== 'Bamboo' && requestCouponVoucher.source !== 'LikeCard' 
                  ">
                  <label>Authentication Type: </label>
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    title="{{ requestCouponVoucher.authentication_type }}"
                    >{{ requestCouponVoucher.authentication_type }}
                  </span>
                </div>
  
                <div class="col-md-3" *ngIf="requestCouponVoucher.usage_mode">
                  <label>Usage Mode: </label>
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    >{{ requestCouponVoucher.usage_mode }}
                  </span>
                </div>
  
                <div class="col-md-3">
                  <label>Payment: </label>
                  <span
                    class="text-truncate mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    >{{
                      requestCouponVoucher.payment_status == "0"
                        ? "Advance"
                        : requestCouponVoucher.payment_status == "1"
                        ? "Credit"
                        : ""
                    }}
                  </span>
                </div>
  
                <div class="col-md-3 text-truncate">
                  <label>Issuance Date: </label>
                  <span
                    class="mr-5 text-truncate"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    title="{{
                      requestCouponVoucher.issuance_date | date : 'dd/MM/yyyy'
                    }}"
                    >{{
                      requestCouponVoucher.issuance_date | date : "dd/MM/yyyy"
                    }}
                  </span>
                </div>
  
                <div class="col-md-3 text-truncate">
                  <label>Start Date: </label>
                  <span
                    class="mr-5 text-truncate"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    title="{{
                      requestCouponVoucher.start_date | date : 'dd/MM/yyyy'
                    }}"
                    >{{ requestCouponVoucher.start_date | date : "dd/MM/yyyy" }}
                  </span>
                </div>
  
                <div class="col-md-3 text-truncate">
                  <label>Expiry: </label>
                  <span
                    *ngIf="this.voucherkartvalue != 'voucherkart'"
                    class="mr-5 text-truncate"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    title="{{ requestCouponVoucher.validity }}"
                    >{{ requestCouponVoucher.validity?requestCouponVoucher.validity:'365 days' }}
                  </span>
                  <span
                    *ngIf="this.voucherkartvalue == 'voucherkart'"
                    class="mr-5 text-truncate"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    title="{{ requestCouponVoucher.validity }}"
                    >{{ requestCouponVoucher.validity }}
                  </span>
                </div>
  
                <div
                  class="col-md-3"
                  *ngIf="
                    requestCouponVoucher.status === 'A' ||
                    requestCouponVoucher.status === 'G'
                  ">
                  <label>Supplier Type: </label>
                  <span
                    style="text-transform: capitalize"
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    >{{ requestCouponVoucher.supplier_type }}
                  </span>
                </div>
  
                <div
                  class="col-md-3 text-truncate"
                  *ngIf="requestCouponVoucher.product">
                  <label>Product: </label>
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    title="{{ requestCouponVoucher.product }}"
                    >{{ requestCouponVoucher.product }}
                  </span>
                </div>
  
                <div
                  class="col-md-3 text-truncate"
                  *ngIf="requestCouponVoucher.voucher_delivery_type">
                  <label>Delivery Type: </label>
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    title="{{ requestCouponVoucher.voucher_delivery_type }}"
                    >{{ requestCouponVoucher.voucher_delivery_type }}
                  </span>
                </div>
  
                <div
                  class="col-md-3 text-truncate"
                  *ngIf="requestCouponVoucher.voucher_delivery_type == 'Single'">
                  <label>Recipient Email: </label>
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    title="{{ requestCouponVoucher.voucher_delivery_email }}"
                    >{{ requestCouponVoucher.voucher_delivery_email }}
                  </span>
                </div>
  
                <div
                  class="col-md-3 text-start"
                  *ngIf="
                    requestCouponVoucher.voucher_delivery_type == 'Multiple'
                  ">
                  <label> Download preview: </label>
                  <a
                    style="color: green; padding-left: 5px"
                    href="{{ this.fileurls }}/downlaod/delivery/emails/{{
                      requestCouponVoucher.voucher_request_id
                    }}"
                    class="mr-10 mt-2"
                    type="button"
                    target="_self"
                    download="sample-file.csv">
                    <i class="fa fa-download pr-1"></i>
                  </a>
                </div>
  
                <div
                  class="col-md-4 text-truncate"
                  *ngIf="
                    requestCouponVoucher.source !== 'FlipKart' &&
                    requestCouponVoucher.source !== 'QwikCilver' &&
                    requestCouponVoucher.source !== 'Amazon' &&
                    requestCouponVoucher.source !== 'Ticket Express' &&
                    requestCouponVoucher.source !== 'EZPIN' &&
                    requestCouponVoucher.source !== 'DT' &&
                    requestCouponVoucher.source !== 'MEA' &&
                    requestCouponVoucher.source !== 'YGG' && requestCouponVoucher.source !== 'Bamboo' && requestCouponVoucher.source !== 'LikeCard'
                  ">
                  <label>Voucher Redemption Type: </label>
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'company'
                        ? 'sides'
                        : 'side'
                    "
                    title="{{ requestCouponVoucher.voucher_redemption_type }}"
                    >{{ requestCouponVoucher.voucher_redemption_type }}
                  </span>
                </div>
  
                <div class="col-md-3" *ngIf="requestCouponVoucher.usagemode">
                  <label>Usage Mode: </label>
                  <span
                    class="mr-5"
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'advcolor'
                        : role == 'Company'
                        ? 'sides'
                        : 'side'
                    "
                    >{{ requestCouponVoucher.usagemode }}
                  </span>
                </div>
              </div>
            </div>
          </div>
  
          <!-- <div class="" ng-reflect-ng-class="bg-light"
                  *ngIf="voucherGenerationList.length === 0">
                  <div class="card-body row mx-0 ps-0">
                      <div class="text-center">
                          No voucher generated
                      </div>
                  </div>
              </div> -->
  
          <div
            class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
            ng-reflect-ng-class="bg-light"
            *ngIf="voucherGenerationList.length === 0">
            <div class="card-body row mx-0 ps-0">
              <div
                class="text-center"
                *ngIf="requestCouponVoucher.status === 'Y' && pending">
                Voucher is Pending for Approval.
              </div>
  
              <div
                class="text-center"
                *ngIf="
                  (requestCouponVoucher.status === 'A' || approvestatus) &&
                  !issuehide &&
                  !this.logvalue
                ">
                Voucher is Approved. Voucher will be generated on
                {{ issuancedate | date : "dd/MM/yyyy" }}.
              </div>
  
              <div
                class="text-center"
                *ngIf="
                  (requestCouponVoucher.status === 'A' || approvestatus) &&
                  issuehide &&
                  !this.logvalue
                ">
                Voucher is Approved. Voucher will be generated on {{ issuedate }}.
              </div>
  
              <div class="text-center" *ngIf="this.logvalue">
                {{ this.logvalue }}.
              </div>
  
              <div
                class="text-center"
                *ngIf="requestCouponVoucher.status === 'R' || rejectstatus">
                Voucher is Rejected.
              </div>
  
              <div
                class="text-center"
                *ngIf="requestCouponVoucher.status === 'G' &&  !logvalue">
                Voucher is Generated.
              </div>
            </div>
          </div>
  
          <div class="mt-4 px-1" *ngIf="voucherGenerationList.length > 0">
            <div
              class="row mx-0 py-4 opacity-75"
              [ngClass]="
                role == 'admin' || role === 'SuperAdmin'
                  ? 'adcolor'
                  : role == 'company'
                  ? 'backcolor1'
                  : 'backcolor1'
              ">
              <div class="col-2 labelText text-start" *ngIf="hide">
                Voucher Code
              </div>
  
              <div
                class="col-2 labelText text-center"
                *ngIf="
                  requestCouponVoucher.source == 'voucherkart' &&
                  requestCouponVoucher.authentication_type != 'OTP'
                ">
                Voucher Pin
              </div>
  
              <div
                class="col-2 labelText text-center"
                *ngIf="requestCouponVoucher.source == 'voucherkart'">
                Status
              </div>
  
              <div
                class="col-2 labelText text-center"
                *ngIf="requestCouponVoucher.source == 'Ticket Express'">
                View Code
              </div>
  
              <div
                class="col-2 labelText text-center"
                *ngIf="requestCouponVoucher.source == 'Ticket Express'">
                Activation URL
              </div>
  
              <div
                class="labelText"
                [ngClass]="
                  requestCouponVoucher.source === 'FlipKart' ||
                  requestCouponVoucher.source === 'QwikCilver' ||
                  requestCouponVoucher.source === 'Amazon' ||
                  requestCouponVoucher.source === 'EZPIN' ||
                  requestCouponVoucher.source === 'DT' ||
                  requestCouponVoucher.source === 'YGG' ||
                  requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                    ? 'ps-2 col-2'
                    : 'col-2 px-0'
                "
                *ngIf="
                  requestCouponVoucher.source === 'QwikCilver' ||
                  requestCouponVoucher.source === 'Amazon' ||
                  requestCouponVoucher.source === 'FlipKart' ||
                  requestCouponVoucher.source === 'EZPIN' ||
                  requestCouponVoucher.source === 'DT' ||
                  requestCouponVoucher.source === 'YGG' ||
                  requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                ">
                Card Number
              </div>
  
              <div
                class="labelText text-center"
                [ngClass]="
                  requestCouponVoucher.source === 'FlipKart' ||
                  requestCouponVoucher.source === 'QwikCilver' ||
                  requestCouponVoucher.source === 'Amazon' ||
                  requestCouponVoucher.source == 'Ticket Express' ||
                  requestCouponVoucher.source == 'EZPIN' ||
                  requestCouponVoucher.source === 'DT' ||
                  requestCouponVoucher.source === 'YGG' ||
                  requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                    ? 'ps-2 col-2'
                    : 'col-2 px-0'
                "
                *ngIf="
                  requestCouponVoucher.source === 'QwikCilver' ||
                  requestCouponVoucher.source == 'Amazon' ||
                  requestCouponVoucher.source === 'FlipKart' ||
                  requestCouponVoucher.source === 'Ticket Express' ||
                  requestCouponVoucher.source === 'EZPIN' ||
                  requestCouponVoucher.source === 'DT' ||
                  requestCouponVoucher.source === 'YGG' ||
                  requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                ">
                Card Pin
              </div>
  
              <div
                class="labelText text-center"
                [ngClass]="
                  requestCouponVoucher.source === 'FlipKart' ||
                  requestCouponVoucher.source === 'QwikCilver' ||
                  requestCouponVoucher.source === 'Amazon' ||
                  requestCouponVoucher.source === 'EZPIN' ||
                  requestCouponVoucher.source === 'DT' ||
                  requestCouponVoucher.source === 'YGG' ||
                  requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                    ? 'ps-2 col-2'
                    : 'col-2 px-0'
                "
                *ngIf="
                  requestCouponVoucher.source === 'QwikCilver' ||
                  requestCouponVoucher.source === 'Amazon' ||
                  requestCouponVoucher.source === 'FlipKart' ||
                  requestCouponVoucher.source === 'EZPIN' ||
                  requestCouponVoucher.source === 'DT' ||
                  requestCouponVoucher.source === 'YGG' ||
                  requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                ">
                Activation URL
              </div>
  
              <div
                class="labelText"
                [ngClass]="
                  requestCouponVoucher.source === 'FlipKart' ||
                  requestCouponVoucher.source === 'QwikCilver' ||
                  requestCouponVoucher.source === 'Amazon' ||
                  requestCouponVoucher.source === 'EZPIN' ||
                  requestCouponVoucher.source === 'DT' ||
                  requestCouponVoucher.source === 'YGG' ||
                  requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                    ? 'ps-2 col-2'
                    : 'col-2 px-0'
                "
                *ngIf="
                  requestCouponVoucher.source === 'FlipKart' ||
                  requestCouponVoucher.source === 'QwikCilver' ||
                  requestCouponVoucher.source === 'Amazon' ||
                  requestCouponVoucher.source === 'EZPIN' ||
                  requestCouponVoucher.source === 'DT' ||
                  requestCouponVoucher.source === 'YGG' ||
                  requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                ">
                Activation Code
              </div>
  
              <div
                class="labelText text-center"
                [ngClass]="
                  requestCouponVoucher.source === 'FlipKart' ||
                  requestCouponVoucher.source === 'QwikCilver' ||
                  requestCouponVoucher.source === 'Amazon' ||
                  requestCouponVoucher.source === 'EZPIN' ||
                  requestCouponVoucher.source === 'DT' ||
                  requestCouponVoucher.source === 'YGG' ||
                  requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                    ? 'ps-2 col-2'
                    : 'col-3 px-0'
                "
                *ngIf="
                  requestCouponVoucher.source === 'QwikCilver' ||
                  requestCouponVoucher.source === 'Amazon' ||
                  requestCouponVoucher.source === 'FlipKart' ||
                  requestCouponVoucher.source === 'EZPIN' ||
                  requestCouponVoucher.source === 'DT' ||
                  requestCouponVoucher.source === 'YGG' ||
                  requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                "> 
                Validity
              </div>
  
              <div
                class="labelText text-center col-2"
                [ngClass]="
                  requestCouponVoucher.source === 'FlipKart' ||
                  requestCouponVoucher.source === 'QwikCilver' ||
                  requestCouponVoucher.source === 'Amazon' ||
                  requestCouponVoucher.source === 'EZPIN' ||
                  requestCouponVoucher.source === 'DT' ||
                  requestCouponVoucher.source === 'YGG' ||
                  requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                    ? 'ps-2 col-2'
                    : 'col-2 px-0'
                "
                *ngIf="requestCouponVoucher.type === 'ID'">
                Allocated user email
              </div>
              <div
                class="col-2 labelText text-center"
                *ngIf="
                  requestCouponVoucher.source == 'voucherkart' ||
                  requestCouponVoucher.supplier_type == 'Internal'
                ">
                QR Code
              </div>
  
              <div
                class="col-2 labelText text-center"
                *ngIf="requestCouponVoucher.source == 'voucherkart'">
                Redemption History
              </div>
            </div>
  
            <div>
              <div *ngIf="voucherGenerationList.length > 0">
                <div
                  class=""
                  *ngFor="
                    let list of voucherGenerationList
                      | paginate
                        : {
                            itemsPerPage: limit,
                            currentPage: p,
                            totalItems: count
                          }
                  ">
                  <div
                    class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                    ng-reflect-ng-class="bg-light">
                    <div class="card-body row mx-0 ps-0">
                      <div
                        class="col-2 text-truncate text-start textsiz"
                        *ngIf="hide">
                        {{ list.voucher_code }}
                      </div>
  
                      <div
                        class="textsiz"
                        class="col-2 text-truncate text-center"
                        *ngIf="
                          requestCouponVoucher.source == 'voucherkart' &&
                          requestCouponVoucher.authentication_type != 'OTP'
                        ">
                        {{ list.voucher_pin }}
                      </div>
  
                      <div
                        class="textsiz"
                        class="col-2 text-truncate text-center"
                        *ngIf="requestCouponVoucher.source == 'voucherkart'">
                        {{ list.status == "G" ? "Generated" : "" }}
                      </div>
  
                      <div
                        class="col-2 text-truncate text-center textsiz"
                        *ngIf="requestCouponVoucher.source == 'Ticket Express'">
                        {{ list.view_code }}
                      </div>
  
                      <!-- <div class="col-2 text-truncate text-start textsiz" *ngIf = "requestCouponVoucher.source == 'Ticket Express'" title="{{list.edenred_url}}"> {{list.edenred_url}}
                                  </div> -->
  
                      <div
                        class="col-2 text-truncate text-center textsiz"
                        *ngIf="requestCouponVoucher.source == 'Ticket Express'">
                        <a
                          *ngIf="list.edenred_url"
                          class="linkColor"
                          (click)="viewurl(list.edenred_url)">
                          <i
                            class="fa fa-copy cursor col"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Click here to Copy url"></i>
                        </a>
                      </div>
  
                      <div
                        class="textsiz ygg"
                        [ngClass]="
                          requestCouponVoucher.source === 'FlipKart' ||
                          requestCouponVoucher.source === 'QwikCilver' ||
                          requestCouponVoucher.source === 'Amazon' ||
                          requestCouponVoucher.source === 'EZPIN' ||
                          requestCouponVoucher.source === 'DT' ||
                          requestCouponVoucher.source === 'YGG' ||
                          requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                            ? 'ps-2 col-2'
                            : 'col-2 px-0'
                        "
                        *ngIf="requestCouponVoucher.source === 'FlipKart' ||
                          requestCouponVoucher.source === 'QwikCilver' ||
                          requestCouponVoucher.source === 'Amazon' ||
                          requestCouponVoucher.source === 'EZPIN' ||
                          requestCouponVoucher.source === 'DT' ||
                          requestCouponVoucher.source === 'YGG' ||
                          requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                        "
                        title="{{ list.card_number }}">
                        {{ list.card_number }}
                      </div>
  
                      <div
                        class="textsiz text-center text-truncate"
                        [ngClass]="
                          requestCouponVoucher.source === 'FlipKart' ||
                          requestCouponVoucher.source === 'QwikCilver' ||
                          requestCouponVoucher.source === 'Amazon' ||
                          requestCouponVoucher.source === 'Ticket Express' ||
                          requestCouponVoucher.source === 'EZPIN' ||
                          requestCouponVoucher.source === 'DT' ||
                          requestCouponVoucher.source === 'YGG' ||
                          requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                            ? 'ps-2 col-2'
                            : 'col-2 px-0'
                        "
                        *ngIf="
                          requestCouponVoucher.source === 'FlipKart' ||
                          requestCouponVoucher.source === 'QwikCilver' ||
                          requestCouponVoucher.source === 'Amazon' ||
                          requestCouponVoucher.source === 'Ticket Express' ||
                          requestCouponVoucher.source === 'EZPIN' ||
                          requestCouponVoucher.source === 'DT' ||
                          requestCouponVoucher.source === 'YGG' ||
                          requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                        "
                        title="{{ list.card_pin }}">
                        {{ list.card_pin }}
                      </div>
  
                      <div
                        class="text-center"
                        [ngClass]="
                          requestCouponVoucher.source === 'FlipKart' ||
                          requestCouponVoucher.source == 'QwikCilver' ||
                          requestCouponVoucher.source === 'Amazon' ||
                          requestCouponVoucher.source === 'EZPIN' ||
                          requestCouponVoucher.source === 'DT' ||
                          requestCouponVoucher.source === 'YGG' ||
                          requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                            ? 'ps-2 col-2'
                            : 'col px-0'
                        "
                        *ngIf="
                          requestCouponVoucher.source === 'FlipKart' ||
                          requestCouponVoucher.source === 'QwikCilver' ||
                          requestCouponVoucher.source === 'Amazon' ||
                          requestCouponVoucher.source === 'EZPIN' ||
                          requestCouponVoucher.source === 'DT' ||
                          requestCouponVoucher.source === 'YGG' ||
                          requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                        ">
                        <a
                          *ngIf="list.activation_url"
                          class="linkColor"
                          (click)="viewurl(list.activation_url)">
                          <i
                            class="fa fa-copy cursor col"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Click here to Copy url"></i>
                        </a>
                      </div>
                      <!-- <button [cdkCopyToClipboard]="value">Copy to clipboard</button> -->
  
                      <div
                        class="textsiz ygg1"
                        [ngClass]="
                          requestCouponVoucher.source === 'FlipKart' ||
                          requestCouponVoucher.source === 'QwikCilver' ||
                          requestCouponVoucher.source === 'Amazon' ||
                          requestCouponVoucher.source === 'EZPIN' ||
                          requestCouponVoucher.source === 'DT' ||
                          requestCouponVoucher.source === 'YGG' ||
                          requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                            ? 'ps-2 col-2'
                            : 'col-2 px-0'
                        "
                        *ngIf="
                          requestCouponVoucher.source === 'FlipKart' ||
                          requestCouponVoucher.source === 'QwikCilver' ||
                          requestCouponVoucher.source === 'Amazon' ||
                          requestCouponVoucher.source === 'EZPIN' ||
                          requestCouponVoucher.source === 'DT' ||
                          requestCouponVoucher.source === 'YGG' ||
                          requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                        "
                        title="{{ list.activation_code }}">
                        <img src="{{ list.activation_code }}" *ngIf="list.activation_code"  class="img-fluid" alt="barcode"> 
                      </div>
  
                      <div
                        class="text-center text-truncate textsiz"
                        [ngClass]="
                          requestCouponVoucher.source === 'FlipKart' ||
                          requestCouponVoucher.source === 'QwikCilver' ||
                          requestCouponVoucher.source === 'Amazon' ||
                          requestCouponVoucher.source === 'EZPIN' ||
                          requestCouponVoucher.source === 'DT' ||
                          requestCouponVoucher.source === 'YGG' ||
                          requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                            ? 'ps-2 col-2'
                            : 'col-2 px-0'
                        "
                        *ngIf="
                          requestCouponVoucher.source === 'FlipKart' ||
                          requestCouponVoucher.source === 'QwikCilver' ||
                          requestCouponVoucher.source === 'Amazon' ||
                          requestCouponVoucher.source === 'EZPIN' ||
                          requestCouponVoucher.source === 'DT' ||
                          requestCouponVoucher.source === 'YGG' ||
                          requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                        "
                        title="{{ list.validity }}">
                        {{ list.validity }}
                      </div>
  
                      <div
                        class="textsiz text-center text-truncate col-2"
                        [ngClass]="
                          requestCouponVoucher.source === 'FlipKart' ||
                          requestCouponVoucher.source === 'QwikCilver' ||
                          requestCouponVoucher.source === 'Amazon' ||
                          requestCouponVoucher.source === 'EZPIN' ||
                          requestCouponVoucher.source === 'DT' ||
                          requestCouponVoucher.source === 'YGG' ||
                          requestCouponVoucher.source === 'MEA'|| requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                            ? 'ps-2 col-2'
                            : 'col-2 px-0'
                        "
                        *ngIf="requestCouponVoucher.type == 'ID'"
                        title="{{ list.voucher_allocated_user_email }}">
                        {{ list.voucher_allocated_user_email }}
                      </div>
                      <div
                        class="textsiz text-center text-truncate col-2"
                        [ngClass]="
                          requestCouponVoucher.source === 'FlipKart' ||
                          requestCouponVoucher.source === 'QwikCilver' ||
                          requestCouponVoucher.source === 'Amazon' ||
                          requestCouponVoucher.source === 'DT' ||
                          requestCouponVoucher.source === 'YGG' ||
                          requestCouponVoucher.source === 'MEA' || requestCouponVoucher.source === 'Bamboo' || requestCouponVoucher.source === 'LikeCard'
                            ? 'ps-2 col-2'
                            : 'col-2 px-0'
                        "
                        *ngIf="requestCouponVoucher.type == 'ID'"
                        title="{{ list.voucher_allocated_user_email }}">
                        <img
                          src="{{ list.qr_code }}"
                          alt=""
                          srcset=""
                          width="100px" />
                      </div>
  
                      <!-- <div class="textsiz text-start text-truncate" [ngClass]="requestCouponVoucher.source === 'FlipKart' || requestCouponVoucher.source === 'QwikCilver' || requestCouponVoucher.source === 'Amazon'  ? 'ps-2 col-2':'col-2 px-0'" *ngIf="requestCouponVoucher.voucher_redemption_type == 'Multiple'" title="{{list.voucher_redemption_type}}">
                                      <button type="button" class= "btn btn-success">View</button>
                                  </div> -->
                      <div
                        class="textsiz"
                        class="col-2 text-truncate text-center"
                        *ngIf="requestCouponVoucher.source == 'voucherkart'"
                        title="{{ list.voucher_redemption_type }}">
                        <div>
                          <button
                            type="button"
                            class="btn btn-success"
                            (click)="view(list)">
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="voucherGenerationList.length > 0">
                <div class="col-6 pt-3">
                  <p>
                    Showing {{ p > 1 ? skip + 1 : p }} to
                    {{ lastvalue > count ? count : lastvalue }} of
                    {{ count }} entries
                  </p>
                </div>
                <div class="col-6">
                  <pagination-controls
                    [ngClass]="
                      role == 'admin' || role === 'SuperAdmin'
                        ? 'list-pagination adpage'
                        : role == 'company'
                        ? 'list-pagination1 adpage1'
                        : 'list-pagination1 adpage1'
                    "
                    (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
              </div>
            </div>
          </div>
  
          <div class="d-flex align-items-end justify-content-end mt-4">
            <button
              class="btn"
              [ngClass]="
                role == 'admin' || role === 'SuperAdmin'
                  ? 'backcolor'
                  : role == 'company'
                  ? 'backcolor1'
                  : 'backcolor1'
              "
              (click)="back()">
              Back
            </button>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade"
        id="rejectModal"
        tabindex="-1"
        aria-
        labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body text-center">
              <p>Are you sure to reject the voucher?</p>
              <p class="mt-5">
                <button
                  class="btn btn-secondary mr-6"
                  data-bs-dismiss="modal"
                  
                  #closeBtn>
                  Cancel
                </button>
                <button
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                  (click)="Rejectbrand(requestCouponVoucher)">
                  Confirm
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade"
        id="approveModals"
        tabindex="-1"
        aria-
        labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body text-center">
              <div
                style="
                  display: flex;
                  align-items: center;
                  position: relative;
                  padding: 10px;
                "
              >
                <div style="text-align: center; flex-grow: 1">
                  <label>Supplier Type: </label>
                  <span class="tex text-primary" style="color: red"
                    >External</span
                  >
                </div>
                <div
                  style="
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    cursor: pointer;
                    font-weight: bold;
                  "
                  data-bs-dismiss="modal"
                  (click)="clear()"
                  #closeBtn
                >
                  <i
                    class="fa fa-times cursor col"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Close"
                  ></i>
                </div>
              </div>
  
              <p class="mt-5">
                <button
                  class="btn btn-success mr-5"
                  data-bs-dismiss="modal"
                  (click)="approveVouchers(requestCouponVoucher)"
                  #closeBtn
                >
                  API
                </button>
                <button
                  class="btn btn-success mr-5"
                  data-bs-dismiss="modal"
                  (click)="manualUpload(requestCouponVoucher)"
                >
                  Manual Upload
                </button>
                <button
                class="btn btn-success mr-5"
                data-bs-dismiss="modal"
                (click)="inventoryVouchers(requestCouponVoucher)"
              >
              Inventory
              </button>
              </p>
            </div>
          </div>
        </div>
      </div>



      <div
      class="modal fade"
      id="manualModals"
      tabindex="-1"
      aria-
      labelledby="exampleModalLabel"
      aria-hidden="true"
      
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div class="d-flex">
              <div>
                <h6 style="font-weight: bold">Manual Upload</h6>
              </div>
              <div style="margin-left: auto; font-weight: bold">
                <a
                  href="{{ this.fileurls }}sample/sample_manual_voucher.csv"
                  class="add_bt btn btn-success mr-10"
                  type="button"
                  target="_self"
                  download="sample-file.csv"
                >
                  <i class="fa fa-download pr-1"></i> Sample file
                </a>
                <label
                  class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10"
                  for="inputFileManual"
                >
                  <input
                    type="file"
                    accept=".csv"
                    class="form-control d-none"
                    id="inputFileManual"
                    #inputFileManual
                    (change)="onFileChange($event)"
                  />
                  <i class="fa fa-upload pr-1"></i> Import</label
                >
              </div>
              <div
                style="
                  position: absolute;
                  top: 5px;
                  right: 5px;
                  cursor: pointer;
                  font-weight: bold;
                "
                data-bs-dismiss="modal"
                (click)="clear()"
                #closeBtn
              >
                <i
                  class="fa fa-times cursor col"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Close"
                ></i>
              </div>
            </div>
  
            <div class="mt-4 batch-dat px-1">
              <div class="row mx-0 adcolor py-4 opacity-75">
                <div class="col labelText test-class">Voucher Code</div>
                <div class="col labelText test-class">Voucher Pin</div>
                <div class="col labelText test-class">Validity</div>
              </div>
              <div
                *ngIf="previewData.length > 0"
                [ngStyle]="{ 'height': previewData.length > 5 ? '300px' : 'auto', 'overflow-y': previewData.length > 5 ? 'auto' : 'visible' }"
                >
                <div
                  *ngFor="let row of previewData"
                  class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                >
                  <div class="card-body row mx-0 px-0">
                    <div
                      *ngFor="let cell of row"
                      class="col text-star px-2 test-class"
                    >
                      {{ cell }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                ng-reflect-ng-class="bg-light"
                *ngIf="!previewData?.length"
              >
                <div class="card-body row mx-0 ps-0">
                  <div class="text-center">
                    No data imported. Please upload a file.
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex mt-2">
              <p *ngIf="previewData.length > 0">
                No of Data: {{ previewData.length }}
              </p>
              <button
                *ngIf="previewData?.length"
                class="btn btn-success"
                style="margin-left: auto; padding: 5px 20px"
                data-bs-dismiss="modal"
                #closeBtn
                (click)="submitData()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div
        class="modal fade"
        id="approveModalss"
        tabindex="-1"
        aria-
        labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body text-center">
              <div
                style="
                  display: flex;
                  align-items: center;
                  position: relative;
                  padding: 10px;
                "
              >
                <div style="text-align: center; flex-grow: 1">
                  <label>Supplier Type: </label>
                  <span class="tex text-primary" style="color: red"
                    >Internal</span
                  >
                </div>
                <div
                  style="
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    cursor: pointer;
                    font-weight: bold;
                  "
                  data-bs-dismiss="modal"
                  (click)="clear()"
                  #closeBtn
                >
                  <i
                    class="fa fa-times cursor col"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Close"
                  ></i>
                </div>
              </div>
  
              <p class="mt-5">
                <button
                  class="btn btn-success mr-5"
                  data-bs-dismiss="modal"
                  (click)="approveVouchers(requestCouponVoucher)"
                  #closeBtn
                >
                  API
                </button>
                <button
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                  (click)="manualUpload(requestCouponVoucher)"
                >
                  Manual Upload
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
