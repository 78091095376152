<div class="row mx-0">
  <div class="col-12 card border-0 shadow-sm px-0">
    <div class="card-body pb-4">
      <div class="row mb-4">
        <div class="col-4">
          <label class="texts">Inventory Voucher List
          </label>
        </div>

        <div class="mt-4 px-1">
          <div
            class="row mx-0 py-4 opacity-75"
            [ngClass]="
              role == 'admin' || role === 'SuperAdmin'
                ? 'adcolor'
                : role == 'company'
                ? 'backcolor1'
                : 'backcolor1'
            "
          >
            <div class="col-2 labelText text-start">
              Product Name
            </div>
            <!-- <div class="col-2 labelText text-start" *ngIf="hide">
              Product Sku
            </div> -->
            <div class="col-2 labelText text-start" >
              Voucher Code
            </div>

            <!-- <div class="col-2 labelText text-center">Voucher Pin</div> -->

            <div class="col-2 labelText text-center">Denomination</div>
            <div class="col-2 labelText text-center">Refernce Id</div>
            <div class="col-2 labelText text-center">Company Name</div>

            <div class="col-2 labelText text-center">Validity</div>
          </div>

          <div>
            <div *ngIf="requestInventoryVoucher.length > 0">
              <div
                class=""
                *ngFor="
                  let list of requestInventoryVoucher
                    | paginate
                      : {
                          itemsPerPage: limit,
                          currentPage: p,
                          totalItems: count
                        }
                "
              >
                <div
                  class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                  ng-reflect-ng-class="bg-light"
                >
                  <div class="card-body row mx-0 ps-0">
                    <div class="col-2 text-truncate text-start textsiz" title=" {{ list.product_name }}">
                      {{ list.product_name }}
                    </div>

                    <!-- <div
                      class="textsiz"
                      class="col-2 text-truncate text-center"
                    >
                      {{ list.product_sku }}
                    </div> -->

                    <div
                      class="textsiz"
                      class="col-2 text-truncate text-center"
                    >
                      {{ list.card_no }}
                    </div>

                    <!-- <div
                      class="textsiz"
                      class="col-2 text-truncate text-center"
                    >
                      {{ list.card_pin }}
                    </div> -->

                    <div
                      class="textsiz"
                      class="col-2 text-truncate text-center"
                    >
                      {{ list.denomination }}
                    </div>

                    <div
                    class="textsiz"
                    class="col-2 text-truncate text-center"
                  >
                    {{ list.voucher_request_id || '-' }}
                   </div>

                   <div
                   class="textsiz"
                   class="col-2 text-truncate text-center"
                 >
                   {{ list.company_name || '-' }}
                  </div>

                    <div
                      class="textsiz"
                      class="col-2 text-truncate text-center"
                    >
                      {{ list.validity }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="requestInventoryVoucher.length > 0">
              <div class="col-6 pt-3">
                <p>
                  Showing {{ p > 1 ? skip + 1 : p }} to
                  {{ lastvalue > count ? count : lastvalue }} of
                  {{ count }} entries
                </p>
              </div>
              <div class="col-6">
                <pagination-controls
                  [ngClass]="
                    role == 'admin' || role === 'SuperAdmin'
                      ? 'list-pagination adpage'
                      : role == 'company'
                      ? 'list-pagination1 adpage1'
                      : 'list-pagination1 adpage1'
                  "
                  (pageChange)="pageChanged($event)"
                ></pagination-controls>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-end justify-content-end mt-4">
          <button
            class="btn"
            [ngClass]="
              role == 'admin' || role === 'SuperAdmin'
                ? 'backcolor'
                : role == 'company'
                ? 'backcolor1'
                : 'backcolor1'
            "
            (click)="back()"
          >
            Back
          </button>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="rejectModal"
      tabindex="-1"
      aria-
      labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center">
            <p>Are you sure to reject the voucher?</p>
            <p class="mt-5">
              <button
                class="btn btn-secondary mr-6"
                data-bs-dismiss="modal"
                (click)="clear()"
                #closeBtn
              >
                Cancel
              </button>
              <button
                class="btn btn-success"
                data-bs-dismiss="modal"
                (click)="Rejectbrand(requestCouponVoucher)"
              >
                Confirm
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="approveModals"
      tabindex="-1"
      aria-
      labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div
              style="
                display: flex;
                align-items: center;
                position: relative;
                padding: 10px;
              "
            >
              <div style="text-align: center; flex-grow: 1">
                <label>Supplier Type: </label>
                <span class="tex text-primary" style="color: red"
                  >External</span
                >
              </div>
              <div
                style="
                  position: absolute;
                  top: 5px;
                  right: 5px;
                  cursor: pointer;
                  font-weight: bold;
                "
                data-bs-dismiss="modal"
                (click)="clear()"
                #closeBtn
              >
                <i
                  class="fa fa-times cursor col"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Close"
                ></i>
              </div>
            </div>

            <p class="mt-5">
              <button
                class="btn btn-success mr-5"
                data-bs-dismiss="modal"
                (click)="approveVouchers(requestCouponVoucher)"
                #closeBtn
              >
                API
              </button>
              <button
                class="btn btn-success mr-5"
                data-bs-dismiss="modal"
                (click)="manualUpload(requestCouponVoucher)"
              >
                Manual Upload
              </button>
              <button
                class="btn btn-success"
                data-bs-dismiss="modal"
                (click)="manualUpload(requestCouponVoucher)"
              >
                Inventory
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="manualModals"
      tabindex="-1"
      aria-
      labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div class="d-flex">
              <div>
                <h6 style="font-weight: bold">Manual Upload</h6>
              </div>
              <div style="margin-left: auto; font-weight: bold">
                <a
                  href="{{ this.fileurls }}sample/sample_manual_voucher.csv"
                  class="add_bt btn btn-success mr-10"
                  type="button"
                  target="_self"
                  download="sample-file.csv"
                >
                  <i class="fa fa-download pr-1"></i> Sample file
                </a>
                <label
                  class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10"
                  for="inputFileManual"
                >
                  <input
                    type="file"
                    accept=".csv"
                    class="form-control d-none"
                    id="inputFileManual"
                    #inputFileManual
                    (change)="onFileChange($event)"
                  />
                  <i class="fa fa-upload pr-1"></i> Import</label
                >
              </div>
              <div
                style="
                  position: absolute;
                  top: 5px;
                  right: 5px;
                  cursor: pointer;
                  font-weight: bold;
                "
                data-bs-dismiss="modal"
                (click)="clear()"
                #closeBtn
              >
                <i
                  class="fa fa-times cursor col"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Close"
                ></i>
              </div>
            </div>

            <div class="mt-4 batch-dat px-1">
              <div class="row mx-0 adcolor py-4 opacity-75">
                <div class="col labelText test-class">Voucher Code</div>
                <div class="col labelText test-class">Voucher Pin</div>
                <div class="col labelText test-class">Validity</div>
              </div>
              <div
                *ngIf="previewData.length > 0"
                [ngStyle]="{
                  height: previewData.length > 5 ? '300px' : 'auto',
                  'overflow-y': previewData.length > 5 ? 'auto' : 'visible'
                }"
              >
                <div
                  *ngFor="let row of previewData"
                  class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                >
                  <div class="card-body row mx-0 px-0">
                    <div
                      *ngFor="let cell of row"
                      class="col text-star px-2 test-class"
                    >
                      {{ cell }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                ng-reflect-ng-class="bg-light"
                *ngIf="!previewData?.length"
              >
                <div class="card-body row mx-0 ps-0">
                  <div class="text-center">
                    No data imported. Please upload a file.
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex mt-2">
              <p *ngIf="previewData.length > 0">
                No of Data: {{ previewData.length }}
              </p>
              <button
                *ngIf="previewData?.length"
                class="btn btn-success"
                style="margin-left: auto; padding: 5px 20px"
                data-bs-dismiss="modal"
                #closeBtn
                (click)="submitData()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="approveModalss"
        tabindex="-1"
        aria-
        labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body text-center">
              <div
                style="
                  display: flex;
                  align-items: center;
                  position: relative;
                  padding: 10px;
                "
              >
                <div style="text-align: center; flex-grow: 1">
                  <label>Supplier Type: </label>
                  <span class="tex text-primary" style="color: red"
                    >Internal</span
                  >
                </div>
                <div
                  style="
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    cursor: pointer;
                    font-weight: bold;
                  "
                  data-bs-dismiss="modal"
                  (click)="clear()"
                  #closeBtn
                >
                  <i
                    class="fa fa-times cursor col"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Close"
                  ></i>
                </div>
              </div>

              <p class="mt-5">
                <button
                  class="btn btn-success mr-5"
                  data-bs-dismiss="modal"
                  (click)="approveVouchers(requestCouponVoucher)"
                  #closeBtn
                >
                  API
                </button>
                <button
                  class="btn btn-success mr-5"
                  data-bs-dismiss="modal"
                  (click)="manualUpload(requestCouponVoucher)"
                >
                  Manual Upload
                </button>
                <button
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                  (click)="manualUpload(requestCouponVoucher)"
                >
                  Inventory
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
