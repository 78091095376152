import { Component,ElementRef, OnInit, ViewChild,HostListener} from '@angular/core';
import { AppService } from './../restful-services/restful.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { RequestProductmap } from '../models/productmapping';
import { Modal } from 'bootstrap';
import { Subscription } from 'rxjs';
declare var bootstrap: any;




@Component({
  selector: 'app-product-mapping',
  templateUrl: './product-mapping.component.html',
  styleUrls: ['./product-mapping.component.scss']
})




export class ProductMappingComponent {


  @ViewChild('addForm', { static: true }) addForm: any;
  @ViewChild('closeProductmapBtn', { static: true }) closeProductmapBtn!: ElementRef;

  limit: number = 25; 
  skip: number = 0;
  d: number = 1;
  search:any;
  test:any;
  count: number = 0;
  lastvalue: number = 0;
  tooltipList: any;
 

  role: any;
  userDetails: any;

  public name: string = "";
  public names: string = "";
  private headers:any={}; 
  apiLoading: boolean = false;


  productmapList: RequestProductmap[];
  Addrequestproductmap: RequestProductmap;   
  categoryData: any = [];
  Edit: Boolean;

  catid:any;
  productsku:any;

  productData: any = [];
  selectproductData: any = [];

  productKeyword = "ProductName";
  selectproductKeyword = "BrandName";
  skuhide:boolean = true;
  sourcevalue: any;
  BrandName:any;
  brandName: any = "";
  skuinput: any;
  discountinput: any;
  productName: any;
  productSku: any;
  sku: any;
  amazonsku: any;
  amazonproductSku: any;
  amazonproductName: any;
  tesku: any;
  teproductSku: any;
  teproductName: any;
  qcproductName: any;
  qcproductSku: any;
  qcsku: any;
  amazonSku:boolean = false;
  qcSku:boolean = false;
  teSku:boolean = false;
  idss:any;
  type:string = "domestic"



  constructor(private _http: AppService, private router: Router, private toastr: ToastrService, private loader: NgxUiLoaderService) {

    this.Edit = false;
    this.productmapList = new Array<RequestProductmap>();
    this.Addrequestproductmap = new RequestProductmap();

  }


  ngOnInit() {

    this.role = localStorage.getItem('role');
    let user = localStorage.getItem("userDetails");
    if (typeof user === 'string') {
      const parse = JSON.parse(user)
      this.userDetails = parse.data;
    }

     this.getproductmaplist();
    //  this.onChangeProduct();   

  }

  restrictEnter(args: any) {
    if (args.key === "Enter" && args.keyCode == 13)
      args.preventDefault();
  }
  

  onChangeProduct(item: any) { 

    this.Addrequestproductmap.rewardkart_sku = '';

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }; 


    this._http.getproduct(item,this.headers).subscribe((data: any) => {
 
      this.productData = data.data;    
    
      if (data) {
        data.data.forEach((e: any) => {

          this.productData.push(e.name);

        })
      }    

    },
      error => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      })
  }


  selectproductEvent(item:any){

let skuval = this.productData.filter((x:any) => x.name === item); 
this.Addrequestproductmap.rewardkart_sku = skuval[0].sku;

  }


  selectsourceEvent(item:any){

    this.Addrequestproductmap.product = '';
    this.selectproductData = [];
    this.sourcevalue = item;

  }


  onChangeProductselect(item: any) {  
   
    let source = this.sourcevalue;    
  
    if (source === 'QwikCilver') {
  
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
  
      const type = 'QC';
      this._http.getQCproducts(item, type, this.headers).subscribe((data: any) => {    
      
        this.selectproductData = data.data.map((names: any) => ({ ...names, BrandName: names.name }));
            
        if (this.selectproductData.length > 0) {
  
          for (let data of this.selectproductData) {  
  
            if (data.BrandName === item.brandName) {
              // this.noBrandFound = false;
              return;
            }
            else {
              // this.noBrandFound = true;
            }
          }
        }
  
  
      },
        error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        })  
    }
     
  
    else if (source === 'Ticket Express') {
  
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
  
      let type = 'TE';
  
      this._http.getTEproductsmap(item, type, this.headers).subscribe((data: any) => {
  
        this.selectproductData = data.data.map((names: any) => ({ ...names, BrandName: names.name }));
      
     
        if (this.selectproductData.length > 0) {
  
          for (let data of this.selectproductData) {  
  
            if (data.BrandName === item.brandName) {
              // this.noBrandFound = false;
              return;
            }
            else {
              // this.noBrandFound = true;
            }
          }
        }
  
      },
        error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        })
    }
    else if (source === 'EZPIN') {
  
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
  
      let type = 'EZPIN';
  
      this._http.getTEproductsmap(item, type, this.headers).subscribe((data: any) => {
  
        this.selectproductData = data.data.map((names: any) => ({ ...names, BrandName: names.name }));
      
     
        if (this.selectproductData.length > 0) {
  
          for (let data of this.selectproductData) {  
  
            if (data.BrandName === item.brandName) {
              // this.noBrandFound = false;
              return;
            }
            else {
              // this.noBrandFound = true;
            }
          }
        }
  
      },
        error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        })
    }
    else if (source === 'MEA') {
  
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
  
      let type = 'MEA';
  
      this._http.getMEAproductsmap(item, type, this.headers).subscribe((data: any) => {
  
        this.selectproductData = data.data.map((names: any) => ({ ...names, BrandName: names.name }));
      
     
        if (this.selectproductData.length > 0) {
  
          for (let data of this.selectproductData) {  
  
            if (data.BrandName === item.brandName) {
              // this.noBrandFound = false;
              return;
            }
            else {
              // this.noBrandFound = true;
            }
          }
        }
  
      },
        error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        })
    }
    else if (source === 'DT' || source === "YGG" || source === "Bamboo" || source === "ValueDesign" || source === "LikeCard") {
  
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
      let type = "";
      type = source === 'DT' ? 'DT' : source === 'Bamboo' ? 'Bamboo' : source === 'ValueDesign' ? 'ValueDesign' : source === "LikeCard" ? "LikeCard" : 'YGG';
  
      this._http.getDTproductsmap(item, type, this.headers).subscribe((data: any) => {
  
        this.selectproductData = data.data.map((names: any) => ({ ...names, BrandName: names.name }));
      
     
        if (this.selectproductData.length > 0) {
  
          for (let data of this.selectproductData) {  
  
            if (data.BrandName === item.brandName) {
              // this.noBrandFound = false;
              return;
            }
            else {
              // this.noBrandFound = true;
            }
          }
        }
  
      },
        error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        })
    }
  
  
    else if (source === 'Amazon' || source === 'FlipKart') {
  
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
  
      let type = 'AMAZON';
      if(source === 'FlipKart'){
        type = 'FlipKart';
      }
      this._http.getAmazonproducts(item, type, this.headers).subscribe((data: any) => {  
       
        this.selectproductData = data.data.map((names: any) => ({ ...names, BrandName: names.name }));
      
     
        if (this.selectproductData.length > 0) {
  
          for (let data of this.selectproductData) {
  
  
            if (data.BrandName === item.brandName) {
              // this.noBrandFound = false;
              return;
            }
            else {
              // this.noBrandFound = true;
            }
          }
        }
  
  
      },
        error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        })
  
    }
  
    else{

    }
  
  }


  getproductmaplist() {   


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;
    this.loader.start();

    this._http.getproductmaplist(this.productsku, this.limit, this.skip, this.type, this.headers).subscribe({
      next: (data: any) => {   

        this.loader.stop();                  
      
        this.productmapList = data.data;
  
        this.count = data.count;
        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }
       
        
        if (data) {
          this.apiLoading = false;  
          this.count = data.count;
          this.productmapList = data.data;
          this.toolTipInit();
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }


  getproductmaplistfilter() {   


    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    this.apiLoading = true;

    this._http.getproductmaplist(this.productsku, this.limit, this.skip, this.type, this.headers).subscribe({
      next: (data: any) => {     
                        
        this.productmapList = data.data;
  
        this.count = data.count;
        if (this.skip == 0) {
          this.lastvalue = this.skip + 25;
        }
       
        
        if (data) {
          this.apiLoading = false;   
          this.count = data.count;
          this.productmapList = data.data;
          this.toolTipInit();
        }
      },
      error: _error => {
        this.apiLoading = false;
        this.loader.stop();
      }
    })

  }


  pageChanged(pageNo: any) {

    this.d = pageNo;
    this.skip = (pageNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem('pageNo', pageNo);
    this.getproductmaplist();
  }

  filterList() {  
   
    if ( this.limit || this.skip || this.productsku ) {
      this.skip = 0;
      this.d = 1;
    }
    this.getproductmaplistfilter();
  }


  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, {trigger : 'hover'})
      })
    },500)
  }


  ngOnDestroy(){
    if(this.tooltipList){
    this.tooltipList.forEach( (tooltip: { dispose: () => void; })=>{
      tooltip.dispose();
    });
  }
  }

  clear() {

    this.productsku = '';
    this.productmapList = [];
    this.getproductmaplist();

    this.d = 1;
    let page = this.d;
    this.pageChanged(page);   

  }


  addNewProductMap() {  
  
    const element = document.getElementById('productmapModal') as HTMLElement;
    const addproductmapModal = new Modal(element,
      {
        backdrop: 'static',
        keyboard: false
      });
      addproductmapModal.show();   
    
  }


  
  AddProductmap(Addrequestproductmap: RequestProductmap) {   

    if (

      !this.Addrequestproductmap.rewardkart_sku || !this.Addrequestproductmap.product_name || !this.Addrequestproductmap.source || !this.Addrequestproductmap.product 
    
    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }  

   
    if(this.Addrequestproductmap.source === 'QwikCilver'){

      this.Addrequestproductmap.source = 'QC';
    }
    if(this.Addrequestproductmap.source === 'Ticket Express'){

      this.Addrequestproductmap.source = 'TE';
    }
    if(this.Addrequestproductmap.source === 'Amazon'){

      this.Addrequestproductmap.source = 'Amazon';
    }
    
    
    let postparams = {

      rewardkart_sku: this.Addrequestproductmap.rewardkart_sku,
      rewardkart_product_name: this.Addrequestproductmap.product_name,
      external_sku: this.skuinput,
      external_discount: this.discountinput,
      source: this.Addrequestproductmap.source,
      created_by: this.userDetails.UserID    

    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    
    this.loader.start();
      this._http.addNewproductmap(postparams, this.headers).subscribe({
        next: (data: any) => {

          this.loader.stop();
        if (data.status == 1) {
          this.loader.stop();
          this.closeProductmapBtn.nativeElement.click();
          this.toastr.success("product map added successfully");
          this.addForm.resetForm();
      
        }

        else if (data.status === 0) {
          this.loader.stop();
          this.toastr.error(data.msg);
          this.closeProductmapBtn.nativeElement.click();
         
      
        }

      },
       error: error => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
          this.closeProductmapBtn.nativeElement.click();        
        
        }
      })
    

  }

  productsClear(item:any){

    this.Addrequestproductmap.rewardkart_sku = '';
  }


  selectproductselectEvent(item:any){

let rowvalue = this.selectproductData.filter((x:any) => x.BrandName === item.BrandName);  
this.skuinput = rowvalue[0].sku;
this.discountinput = rowvalue[0].discount;

  }



  keyDownHandler(event: any) {

    if (event.code === 'Space' && event.which === 32) {
      event.preventDefault();
    }

  }


  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes('.')) {
      event.target.value = inputValue.substring(0, inputValue.indexOf('.'));
    }
  }

  letterOnly(event:any){
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }


  backclear() {

    this.getproductmaplist();    
    this.router.navigate(['/productmap'], { replaceUrl: false });
    this.addForm.resetForm();
    this.Edit = false;
 
  }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  onKeyPressspecialcharacters(event: KeyboardEvent) {
    const key = event.key;
    const isSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(key);
    if (isSpecialCharacter) {
      event.preventDefault();
    }
  }

  editbrand(list:any){
    console.log(list);
    if(list){
      this.productSku = list.product_sku;
      if(list.amazon_sku){
        this.amazonSku = true;
        this.amazonsku = list.amazon_sku;
        this.amazonproductSku = list.product_sku;
        this.amazonproductName = list.product_name;
        this.idss = list.ids;
      }
      if(list.te_sku){
        this.teSku = true;
        this.tesku = list.te_sku;
        this.teproductSku = list.product_sku;
        this.teproductName = list.product_name;
        this.idss = list.ids;
      }
      if(list.qc_sku){
        this.qcSku = true;
        this.qcsku = list.qc_sku;
        this.qcproductName = list.product_name;
        this.qcproductSku = list.product_sku;
        this.idss = list.ids;
      }
      const element = document.getElementById('categoryModal') as HTMLElement;
      const addcategoryModal = new Modal(element,
        {
          backdrop: 'static',
          keyboard: false
        });
      addcategoryModal.show();  
    }
    
  }

  saveChanges(id:any){
    if(this.amazonSku && !this.amazonsku){
      this.toastr.error("SKU Cannot be Empty");
      return;
    }
    if(this.teSku && !this.tesku){
      this.toastr.error("SKU Cannot be Empty");
      return;
    }
    if(this.qcSku && !this.qcsku){
      this.toastr.error("SKU Cannot be Empty");
      return;
    }
    this.loader.start();
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };

    let postParams = {
      "ids": id,
      "product_sku": this.productSku,
      "amazon_sku": this.amazonSku ? this.amazonsku : "",
      "qc_sku": this.qcSku ? this.qcsku : "",
      "te_sku": this.teSku ? this.tesku : ""
    }

    this._http.editNewproductmap(postParams,this.headers).subscribe((data:any) =>{
      if(data && (data.status === "1" || data.status === 1)){
        this.loader.stop();
        this.toastr.success(data.msg);
        this.getproductmaplist();
        this.close();
      }else{
        this.loader.stop();
        this.toastr.error(data.msg);
        this.close();
      }
    })
  }

  close(){
    this.amazonSku = false;
    this.teSku = false;
    this.qcSku = false;
  }

  TypeChange(types:string){
    this.type = types;
    this.getproductmaplist();
  }

}
