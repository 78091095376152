import { Component, ElementRef, ViewChild } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Clipboard } from "@angular/cdk/clipboard";
import { Supplier } from "../models/supplierList";
import { MultiDenomination } from "../models/MultiDenomination";
import { AppService } from "../restful-services/restful.service";
import { VoucherGeneration } from "../models/VoucherGeneration";
import { Modal } from "bootstrap";
import { environment, environmentfiles } from "../../environments/environment";
import { window } from "rxjs";

@Component({
  selector: "app-denomination-order-viewlist",
  templateUrl: "./denomination-order-viewlist.component.html",
  styleUrls: ["./denomination-order-viewlist.component.scss"],
})
export class DenominationOrderViewlistComponent {
  @ViewChild("inputFileManual") inputFileManual:
    | ElementRef<HTMLInputElement>
    | undefined;
    @ViewChild("closeBtn", { static: true }) closeBtn!: ElementRef;

  requestGroupVoucher!: MultiDenomination;
  private headers: any = {};
  public role: string = "company";
  paramValue: any = "";
  supplierDetail: Supplier;
  voucherList: any = [];
  showGroupVoucher: any = [];
  totalAmount: any = 0;
  userDetails: any;
  limit: number = 25;
  skip: number = 0;
  p: number = 1;
  count: number = 0;
  lastvalue: number = 0;
  voucherId: any;
  voucherGenerationList: VoucherGeneration[] | undefined;
  showAccordion: boolean = true;
  issuancedate: any;
  requestCouponVoucher: any;
  created_by_role: any;
  urlError: boolean = false;
  productURL: string = "";

  approvalStatus: string = "";
  getgroupid: any;
  approvalVoucherList: any = [];
  approvalVoucherMultiple: any = [];
  manualVoucherRequestId: any = [];
  supplierName: any;


  previewData: any[] = [];
  checkData: any[] = [];
  tableHeaders: string[] = [];
  fileurls: any;
  supplierType: any;
  showApproveReject: boolean = false;
  selectAll: boolean = false;
  rejectedStatus: string = "";

  user_type: any;
  apiLoading: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private _http: AppService,
    private router: Router,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService
  ) {
    this.requestGroupVoucher = new MultiDenomination();
    this.voucherGenerationList = new Array<VoucherGeneration>();
    this.supplierDetail = new Supplier();
    this.role = localStorage.getItem("role") || "";
  }

  ngOnInit() {
    this.fileurls = environmentfiles.ENVIRONMENT.baseURL;

    this.created_by_role = localStorage.getItem("role");
    this.getMultigroupList();
   }

  getMultigroupList(){
    this.route.queryParams.subscribe((params) => {
      this.paramValue = params["voucher_group_id"];
    });
    let user = localStorage.getItem("userDetails");
    if (typeof user === "string") {
      const parse = JSON.parse(user);
      this.userDetails = parse.data;
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this.loader.start();

    this._http
      .getMultigroupGenerationList(this.paramValue, this.headers)
      .subscribe({
        next: (data: any) => {
           this.loader.stop();
          if (data.status === 1) {
            this.requestGroupVoucher = data.data;
            this.voucherList = this.requestGroupVoucher.voucher_list;
            // console.log(this.voucherList);
            this.showGroupVoucher = this.voucherList.map((e: any) => {
              return false;
            });
            // console.log('showGroupVoucher', this.showGroupVoucher)
          }
        },
      });
  }

  toggleViewReport(id: number, idx: number, source: string, status: string) {
    if (status == "G") {
      if (this.showGroupVoucher[idx]) {
        this.showGroupVoucher[idx] = false;
        this.voucherGenerationList = [];
      } else {
        this.showGroupVoucher[idx] = true;
        this.viewReport(id, idx, source);
      }
    } else {
      this.toastr.info("Voucher Not Generated");
    }
  }

  approveshowModal(approvalStatus: string, voucher: MultiDenomination) {
    this.getgroupid = voucher.voucher_request_id;
    this.approvalVoucherList.push(this.getgroupid);

    this.approvalVoucherMultiple.push(voucher);
    console.log(this.approvalVoucherMultiple);
    this.requestGroupVoucher = voucher;

    this.approvalStatus = approvalStatus;
    this.supplierType = "external";

    const element = document.getElementById("approveModals") as HTMLElement;
    const approveModals = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    this.urlError = false;
    this.productURL = "";
    approveModals.show();
  }

    rejectshowModal(rejectedStatus: string, voucher: MultiDenomination) {
      this.approvalVoucherList.push(voucher.voucher_request_id);
      console.log(this.approvalVoucherList);
      this.rejectedStatus = rejectedStatus;
      this.supplierType = "Internal";
      const element = document.getElementById("rejectModal") as HTMLElement;
      const rejectModal = new Modal(element, {
        backdrop: "static",
        keyboard: false,
      });
      rejectModal.show();
    }

  manualUpload() {
    // console.log(requestCouponVoucher.voucher_request_id);

    const element = document.getElementById("manualModals") as HTMLElement;
    const manualUpload = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    this.urlError = false;
    this.productURL = "";
    manualUpload.show();
    this.tableHeaders = [];
    this.previewData = [];
    this.resetFileInput();
  }

  closeModule() {
    // this.router.navigate(["/denomination-dashboard"], { replaceUrl: false });
    this.getMultigroupList();
    this.tableHeaders = [];
    this.previewData = [];
    this.approvalVoucherMultiple = [];
    this.approvalVoucherList = [];
    this.resetFileInput();
  }
  resetFileInput() {
    if (this.inputFileManual) {
      this.inputFileManual.nativeElement.value = "";
    }
  }

  // Handle File Change
  onFileChange(event: any) {
    // alert();
    const file = event.target.files[0];
    const fileType = file.name.split(".").pop()?.toLowerCase();
    if (fileType === "csv") {
      this.parseCSV(file);
    }
    // else if (fileType === 'xlsx') {
    //   this.parseExcel(file);
    // }
    else {
      this.toastr.error(
        "Invalid file type! Please upload a .csv or .xlsx file."
      );
    }
  }

  allowedHeaders: string[] = ["Voucher Code", "Voucher Pin", "Validity"];

  // Parse CSV File
  parseCSV(file: File) {
    // alert();
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result as string;
      console.log(this.approvalVoucherMultiple);
      let quantity = this.approvalVoucherMultiple[0].quantity;
      console.log(quantity);
      // return;
      const rows = text
        .split("\n")
        .map((row) => row.trim())
        .filter((row) => row !== "");
      const data = rows.map((row) => row.split(","));

      if (data.length - 1 !== quantity) {
        this.toastr.error(
          `The number of rows (${
            data.length - 1
          }) does not match the expected quantity (${quantity}).`
        );
        this.resetFileInput();
        return;
      }

      if (this.validateHeaderLength(data[0])) {
        // this.tableHeaders = data[0];
        this.checkData = data.slice(1);
        if (this.validateData(this.checkData)) {
          this.tableHeaders = data[0]; // First row as headers
          this.previewData = data.slice(1); // Remaining rows as data
        }
        // Create payload based on the allowed headers
      } else {
        this.toastr.error(
          `Invalid column count in CSV file. The file must contain exactly ${this.allowedHeaders.length} columns.`
        );
      }

      this.resetFileInput();

      // const rows = text.split('\n').map((row) => row.split(','));
      // this.tableHeaders = data[0];

      // this.previewData = data.slice(1);
    };
    reader.readAsText(file);
  }

  validateHeaderLength(headers: string[]): boolean {
    // Check if the number of headers matches the allowedHeaders length
    return headers.length === this.allowedHeaders.length;
  }

  // validateDataNotEmpty(data: string[][]): boolean {
  //   // Iterate over each row and check if any cell is empty
  //   return data.every(row => row.every(cell => cell.trim() !== ''));
  // }

  validateData(data: string[][]): boolean {
    const currentDate = new Date(); // Get current date
    const voucherCodes = new Set<string>(); // To track duplicate voucher codes
    let invalidRows = [];
    for (let i = 0; i < data.length; i++) {
      const row = data[i];

      // Check if any cell is empty
      const emptyCellCount = row.filter((cell) => cell.trim() === "").length;

      if (emptyCellCount >= 2) {
        invalidRows.push(i + 1);
      }

      if (invalidRows.length > 0) {
        this.toastr.error(
          `Rows ${invalidRows.join(
            ", "
          )} contain empty data. Please ensure all fields are filled.`
        );
        return false;
      }

      // Validate the 'Validity' column format and date
      const validityIndex = this.allowedHeaders.indexOf("Validity");
      if (validityIndex >= 0) {
        const validityDate = row[validityIndex]?.trim();
        const isValidFormat = this.isValidDateFormat(validityDate);
        if (!isValidFormat) {
          this.toastr.error(
            `Invalid date format in row ${i + 1}. Please use 'dd/mm/yyyy'.`
          );
          return false;
        }

        const dateParts = validityDate.split("/"); // Split the date into parts
        const parsedDate = new Date(
          +dateParts[2],
          +dateParts[1] - 1,
          +dateParts[0]
        ); // Convert to Date object

        if (parsedDate < currentDate) {
          this.toastr.error(
            `Validity date in row ${i + 1} is earlier than the current date.`
          );

          // alert(`Validity date in row ${i + 1} is earlier than the current date.`);
          return false;
        }
      }
      const voucherCodeIndex = this.allowedHeaders.indexOf("Voucher Code");
      if (voucherCodeIndex >= 0) {
        const voucherCode = row[voucherCodeIndex]?.trim();
        if (voucherCodes.has(voucherCode)) {
          this.toastr.error(
            `Duplicate 'Voucher Code' found in row ${
              i + 1
            }. Each code must be unique.`
          );
          return false;
        }
        voucherCodes.add(voucherCode);
      }
    }
    return true;
  }

  // Helper function to validate the 'dd/mm/yyyy' format
  isValidDateFormat(date: string): boolean {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Regular expression for dd/mm/yyyy format
    if (!dateRegex.test(date)) {
      return false;
    }

    // Check if the parsed date is valid
    const dateParts = date.split("/");
    const day = +dateParts[0];
    const month = +dateParts[1];
    const year = +dateParts[2];

    // Months are 0-based in JavaScript's Date object
    const parsedDate = new Date(year, month - 1, day);
    return (
      parsedDate.getFullYear() === year &&
      parsedDate.getMonth() === month - 1 &&
      parsedDate.getDate() === day
    );
  }

  // Parse Excel File
  // parseExcel(file: File) {
  //   const reader = new FileReader();
  //   reader.onload = (event: any) => {
  //     const data = new Uint8Array(event.target.result);
  //     const workbook = XLSX.read(data, { type: 'array' });
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  //     this.tableHeaders = jsonData[0] as string[]; // First row as headers
  //     this.previewData = jsonData.slice(1); // Remaining rows as data
  //   };
  //   reader.readAsArrayBuffer(file);
  // }

  submitData() {
    if (this.previewData.length === 0) {
      alert("No data to submit!");
      return;
    }

    // Send data to API
    // const apiUrl = 'https://your-api-endpoint.com/upload';

    // const payload = this.previewData.map((row) =>
    //   this.tableHeaders.reduce((obj: { [key: string]: any }, key: string, index: number) => {
    //     obj[key] = row[index];
    //     return obj;
    //   }, {})
    // );
    // const quantity = this.requestCouponVoucher.quantity;
    // console.log(quantity);
    // return;
    console.log(this.approvalVoucherList[0].toString());
    // return;

    const voucher_request_id = this.approvalVoucherList[0].toString();
    const voucherData = this.approvalVoucherMultiple[0];
    const status = voucherData.status;
    const supplier_type = voucherData.supplier_type;
    const product_url = voucherData.product_url;
    const supplier_id = voucherData.supplier_id;
    const user_name = this.userDetails.firstName;
    const userId = this.userDetails.UserID;
    const approved_by_name = this.userDetails.UserName;

    // console.log(voucherData);
    // return;

    const formattedPayload = {
      request_id: voucher_request_id,
      status: status,
      supplier_type: supplier_type,
      product_url: product_url,
      supplier_id: supplier_id,
      user_name: user_name,
      userId: userId,
      approved_by_name: approved_by_name,

      data: this.previewData.map((row) =>
        this.tableHeaders.reduce(
          (obj: { [key: string]: any }, key: string, index: number) => {
            // Adjust key names if needed
            const normalizedKey = key.toLowerCase().replace(/\s+/g, "_"); // Normalize header keys (optional)
            obj[normalizedKey] = row[index];
            return obj;
          },
          {}
        )
      ),
    };

    console.log(formattedPayload);

    // return;
    //  const voucher_request_id  = this.manualVoucherRequestId;
    //  console.log(voucher_request_id);

    // return;
    // this["http"].post(apiUrl, payload).subscribe(
    //   (response: any) => {
    //     console.log('Data submitted successfully!', response);
    //   },
    //   (error: any) => {
    //     console.error('Error while submitting data:', error);
    //   }
    // );
    let token = localStorage.getItem("token");
    console.log(token);
    // return;
    this.headers = {
      headers: new HttpHeaders({
        //  'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
      }),
    };
    this.loader.start();

    this._http
      .importManualDataVoucher(formattedPayload, this.headers)
      .subscribe({
        next: (data: any) => {
          this.loader.stop();
          if (data.status === 1) {
            this.toastr.success(data.msg || "Your file uploaded successfully");
            this.getMultigroupList();
          } else if (data.status === 0) {
            this.toastr.error(
              data.msg || "An error occurred while processing the file."
            );
            this.getMultigroupList();
          }
        },
        error: (error: any) => {
          this.loader.stop();
          // Show a meaningful error message
          const errorMessage =
            error?.error?.msg ||
            error?.message ||
            "An unexpected error occurred.";
          this.toastr.error(errorMessage);
          console.error("Error details:", error);
          this.getMultigroupList();
          // location.reload();
          // this.closeBootstrapModal()
          // this.clear();
        },
      });
  }


  Rejectbrand(requestCouponVoucher: MultiDenomination) {
    const params = {
      voucherRequestIds: this.approvalVoucherList,
      status: this.rejectedStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      approved_by_name: this.userDetails.UserName,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this.loader.start();
    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            if (this.rejectedStatus == "R") {
              this.toastr.success("Voucher rejected successfully");
              this.selectAll = false;
              this.showApproveReject = false;
            } else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
            }
          } else {
            if (data.statu == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              this.selectAll = false;
              this.showApproveReject = false;
            }
          }
          this.approvalVoucherList = [];
          this.getMultigroupList();
        }
      },
      error: (error: any) => {
        this.loader.stop();
        this.toastr.error(error.Message);
      },
    });
  }

  reloadWindowAfterOneMinute() {
    // alert();
    setTimeout(() => {
      location.reload(); 
    }, 2000); 
  }

    approveVouchers(requestGroupVoucher: MultiDenomination) {
      // if (this.supplierType == "" || this.supplierType == undefined || this.supplierType == null) {
      //   this.toastr.error("Please select one supplier");
      //   return;
      // }
      // this.router.navigate(["/denomination-order-viewlist"], { replaceUrl: false });

      // alert();
      // return;
    
      const params = {
        voucherRequestIds: this.approvalVoucherList,
        status: this.approvalStatus,
        userId: this.userDetails.UserID,
        user_name: this.userDetails.Name,
        supplier_type: this.supplierType,
        supplier_id: this.supplierName,
        approved_by_name: this.userDetails.UserName,
      };
      // console.log(params);
      // return;
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };
      this.loader.start();
      this.apiLoading = true;
      this._http.voucherApproval(params, this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();
          this.apiLoading = false;
          this.closeBtn.nativeElement.click();
          this.selectAll = false;
          this.showApproveReject = false;
          if (data) {
            if (data.status == 1) {
              if (this.approvalStatus == "A" || this.approvalStatus == "Y") {
                this.toastr.success("Voucher approved successfully");
                this.selectAll = false;
                this.showApproveReject = false;
                // this.supplierForm.resetForm();
              } else if (this.rejectedStatus == "R") {
                this.toastr.error("Voucher rejected");
                this.selectAll = false;
                this.showApproveReject = false;
              } else {
                this.toastr.success("Vouchers approved successfully");
                this.selectAll = false;
                this.showApproveReject = false;
                // this.supplierForm.resetForm();
              }
            
              // alert();
              // this.closeBtn.nativeElement.click();
              // this.approvalVoucherList = [];
              this.getMultigroupList();
              // this.reloadWindowAfterOneMinute();
            } else {
              if (data.status == 2) {
                this.toastr.info(
                  "Voucher has been already approved or generated"
                );
                this.selectAll = false;
                this.showApproveReject = false;
                // this.supplierForm.resetForm();
              }
            }
            // this.closeBtn.nativeElement.click();
            // this.approvalVoucherList = [];
            this.getMultigroupList();

          }
        },
        error: (error) => {
          this.loader.stop();
          const errorMessage =
          error?.error?.msg ||
          error?.message ||
          "An unexpected error occurred.";
        this.toastr.error(errorMessage);
        },
      });

    
    }


    inventoryVouchers(requestGroupVoucher: MultiDenomination) {
      // if (this.supplierType == "" || this.supplierType == undefined || this.supplierType == null) {
      //   this.toastr.error("Please select one supplier");
      //   return;
      // }
     
      const params = {
        request_id: this.approvalVoucherList[0],
        status: this.approvalStatus,
        userId: this.userDetails.UserID,
        // user_name: this.userDetails.Name,
        supplier_type: this.supplierType,
        // supplier_id: this.supplierName,
        approved_by_name: this.userDetails.UserName,
      };
      // console.log(params);
      // return;
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };
      this.loader.start();
      this.apiLoading = true;
      this._http.inventoryVouchersApproval(params, this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();
          this.apiLoading = false;
          this.closeBtn.nativeElement.click();
          this.selectAll = false;
          this.showApproveReject = false;
          if (data) {
            if (data.status == 1) {
              if (this.approvalStatus == "A" || this.approvalStatus == "Y") {
                this.toastr.success("Voucher approved successfully");
                this.selectAll = false;
                this.showApproveReject = false;
                // this.supplierForm.resetForm();
              } else if (this.rejectedStatus == "R") {
                this.toastr.error("Voucher rejected");
                this.selectAll = false;
                this.showApproveReject = false;
              } else {
                this.toastr.success("Vouchers approved successfully");
                this.selectAll = false;
                this.showApproveReject = false;
                // this.supplierForm.resetForm();
              }
            } else {
              if (data.status == 2) {
                this.toastr.info(
                  "Voucher has been already approved or generated"
                );
                this.selectAll = false;
                this.showApproveReject = false;
                // this.supplierForm.resetForm();
              }
            }
            this.closeBtn.nativeElement.click();
            this.approvalVoucherList = [];
            this.getMultigroupList();
          }
        },
        error: (error) => {
          this.loader.stop();
          const errorMessage =
          error?.error?.msg ||
          error?.message ||
          "An unexpected error occurred.";
        this.toastr.error(errorMessage);
        console.error("Error details:", error);
        },
      });

    
    }


    exportVoucher(voucherRequestId: number) {
      if (this.role === "admin" || this.role === "SuperAdmin") {
        this.user_type = "ALL";
      } else if (
        this.role === "company" ||
        this.role === "brand" ||
        this.role === "Company"
      ) {
        this.user_type = "Restricted";
      }
  
      this._http.exportVoucherssDatas(
        voucherRequestId,
        this.user_type,
        this.userDetails.UserID,
        "voucher"
      );
    }

  viewReport(id: number, idx: number, source: string) {
    this.voucherId = id;
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    console.log("source", source);
    this.loader.start();
    let action = "GET";
    if (source == "QwikCilver") {
      action = "QC";
    } else if (source == "QwikCilver") {
      action = "QC";
    } else if (source == "FlipKart") {
      action = "FlipKart";
    } else if (source == "Amazon") {
      action = "AMAZON";
    } else if (source == "Ticket Express") {
      action = "TE";
    }
    this._http
      .getVoucherGenerationListone(
        this.voucherId,
        this.limit,
        this.skip,
        this.headers,
        action,
        this.created_by_role
      )
      .subscribe({
        next: (data: any) => {
          this.loader.stop();
          if (data) {
            this.count = data.count;
            this.voucherGenerationList = data.data;
            this.showGroupVoucher = this.voucherList.map(
              (e: any, index: number) => {
                return index === idx && this.voucherGenerationList?.length !== 0
                  ? true
                  : false;
              }
            );

            // console.log("sadsfds", this.voucherGenerationList)
            // this.toolTipInit();
            if (this.skip === 0) {
              this.lastvalue = this.skip + this.limit;
            }
          }
        },
        error: (_error) => {
          this.loader.stop();
        },
      });
  }

  getVoucherGenerationList(ids: number) {
    this._http.voucherDetailItem.subscribe((data) => {
      // this.requestCouponVoucher = data;
    });

    this.issuancedate = this.requestCouponVoucher.issuance_date;

    if (this.requestCouponVoucher.source == "QwikCilver") {
      let sourcevalue = this.requestCouponVoucher;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      this.loader.start();
      let action = "QC";

      this._http
        .getVoucherGenerationLists(
          ids,
          this.limit,
          this.skip,
          this.headers,
          action
        )
        .subscribe({
          next: (data: any) => {
            this.loader.stop();

            if (data) {
              this.count = data.count;
              this.voucherGenerationList = data.data;
              // this.toolTipInit();

              localStorage.setItem(
                "vouchergenerationlist",
                JSON.stringify(this.voucherGenerationList)
              );
              if (this.skip === 0) {
                this.lastvalue = this.skip + this.limit;
              }
            }
          },
          error: (_error) => {
            this.loader.stop();
          },
        });
    } else if (this.requestCouponVoucher.source == "Ticket Express") {
      let sourcevalue = this.requestCouponVoucher;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      this.loader.start();
      let action = "TE";

      this._http
        .getVoucherGenerationListTE(
          ids,
          this.limit,
          this.skip,
          this.headers,
          action
        )
        .subscribe({
          next: (data: any) => {
            this.loader.stop();

            if (data) {
              this.count = data.count;
              this.voucherGenerationList = data.data;
              // this.toolTipInit();

              localStorage.setItem(
                "vouchergenerationlist",
                JSON.stringify(this.voucherGenerationList)
              );
              if (this.skip === 0) {
                this.lastvalue = this.skip + this.limit;
              }
            }
          },
          error: (_error) => {
            this.loader.stop();
          },
        });
    } else if (this.requestCouponVoucher.source == "Amazon") {
      let sourcevalue = this.requestCouponVoucher;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      this.loader.start();
      let action = "AMAZON";

      this._http
        .getVoucherGenerationListAmazon(
          ids,
          this.limit,
          this.skip,
          this.headers,
          action
        )
        .subscribe({
          next: (data: any) => {
            this.loader.stop();

            if (data) {
              this.count = data.count;
              this.voucherGenerationList = data.data;
              // this.toolTipInit();

              localStorage.setItem(
                "vouchergenerationlist",
                JSON.stringify(this.voucherGenerationList)
              );
              if (this.skip === 0) {
                this.lastvalue = this.skip + this.limit;
              }
            }
          },
          error: (_error) => {
            this.loader.stop();
          },
        });
    } else if (this.requestCouponVoucher.source == "FlipKart") {
      let sourcevalue = this.requestCouponVoucher;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      this.loader.start();
      let action = "FlipKart";

      this._http
        .getVoucherGenerationListAmazon(
          ids,
          this.limit,
          this.skip,
          this.headers,
          action
        )
        .subscribe({
          next: (data: any) => {
            this.loader.stop();

            if (data) {
              this.count = data.count;
              this.voucherGenerationList = data.data;
              // this.toolTipInit();

              localStorage.setItem(
                "vouchergenerationlist",
                JSON.stringify(this.voucherGenerationList)
              );
              if (this.skip === 0) {
                this.lastvalue = this.skip + this.limit;
              }
            }
          },
          error: (_error) => {
            this.loader.stop();
          },
        });
    } else {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      this.loader.start();
      this._http
        .getVoucherGenerationList(ids, this.limit, this.skip, this.headers)
        .subscribe({
          next: (data: any) => {
            this.loader.stop();
            if (data) {
              this.count = data.count;
              this.voucherGenerationList = data.data;
              // this.toolTipInit();

              localStorage.setItem(
                "vouchergenerationlist",
                JSON.stringify(this.voucherGenerationList)
              );

              if (this.skip === 0) {
                this.lastvalue = this.skip + this.limit;
              }
            }
          },
          error: (_error) => {
            this.loader.stop();
          },
        });
    }
  }

  pageChanged(pageNo: number) {
    this.p = pageNo;
    this.skip = (pageNo - 1) * this.limit;
    this.lastvalue = this.skip + 25;
    this.viewReport(this.voucherId, 0, "");
    localStorage.removeItem("logvalue");
  }

  back() {
    this.router.navigate(["/denomination-dashboard"], { replaceUrl: false });
  }

  // toggleAccordian() {
  //   this.showAccordion = !this.showAccordion;
  // }
}
