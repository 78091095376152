<div class="card border-0 shadow-sm">
    <div class="card-body">
        <div class="row mx-0">
            <div class="col-md-12 text-end">
                <button class="btn btn-success p-3" (click)="export()"><i class="fa fa-download me-3"></i>Export </button>
            </div>
            <div class="col-12">
                <app-common-table [itemPerPage]="50"  (pagination)="page($event)" [columns]="columns" [primaryKeys]="primaryKey" [tableDatas]="datas" [action]="''" [skip]="skip" [limit]="limit" [d]="d" [lastvalue]="lastvalue" [count]="count" ></app-common-table>
            </div>
        </div>
    </div>
</div>

<ngx-ui-loader></ngx-ui-loader>