<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="table-scroll-container">
            <table class="table" [ngClass]="role.toLowerCase() === 'company' ? 'table-fixed' : ''">
                <thead class="py-4 px-3 mx-0 opacity-75" [ngClass]="role.toLowerCase() === 'company' ? 'companyColor' : 'adcolor'">
                    <tr>
                        <th scope="col" class="p-3 text-white" [ngClass]="cssClass ? cssClass : ''" *ngFor="let column of columns">{{column}}</th>
                        <th scope="col" class="p-3 text-white" [ngClass]="cssClass ? cssClass : ''" *ngIf="action !== ''">Action</th>
                    </tr>
                </thead>

                <tbody *ngIf="tableDatas.length > 0">
                    <tr *ngFor="let value of tableDatas | paginate: {itemsPerPage: limit, currentPage: d,totalItems:count};let i = index">
                        <td *ngIf="index">{{i + 1 + skip}}</td>
                        <td *ngFor="let key of keys(value)" [ngClass]="cssClass ? cssClass : ''">{{ value[key] !== null && value[key] !== undefined ? value[key] : '-' }}</td>
                        <td scope="col" [ngClass]="cssClass ? cssClass : ''" *ngIf="action !== ''">
                            <button type="button" class="btn btn-success" (click)="edit(value)">{{action}}</button>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="tableDatas.length === 0">
                    <tr>
                        <td [colSpan]="action ? columns.length + 1 : columns.length" class="p-4 fw-bold text-center border-0">No Records Found</td>
                    </tr>
                </tbody>

            </table>
        </div>
            <div>
                <div class="row" *ngIf="tableDatas.length > 0">
                    <div class="col-6 pt-3">
                        <p>Showing {{d > 1 ? skip + 1 :d}} to {{lastvalue > count ? count : lastvalue === 0 ? 10 : lastvalue}} of
                            {{count}} entries</p>
                    </div>
                    <div class="col-6">
                        <pagination-controls class="list-pagination" [ngClass]="role.toLowerCase() === 'company' ? 'companyColor' : 'adcolor'"
                            (pageChange)="pageChanged($event)">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>