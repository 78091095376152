import { Component, ElementRef, ViewChild } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { AppService } from "../../restful-services/restful.service";
import * as XLSX from "xlsx";
import { VoucherGeneration } from "../../models/VoucherGeneration";
import { RequestInventory } from "../../models/RequestInventory";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Supplier } from "../../models/supplierList";
import { Modal } from "bootstrap";
import { Clipboard } from "@angular/cdk/clipboard";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import {
  environment,
  environmentfiles,
} from "../../../environments/environment";
declare var bootstrap: any;

@Component({
  standalone: true,
  selector: "app-create-order",
  templateUrl:"./view-voucher.component.html",
  styleUrls: ["./view-voucher.component.scss"],
  imports: [NgxPaginationModule, NgxUiLoaderModule,FormsModule,CommonModule],
})
export class ViewVocherComponent {
  public showImageError: boolean = false;
  public showBatchData: boolean = false;
  public upldSucess: boolean = false;
  public batchData: any;
  @ViewChild("inputFile")
  myInputVariable!: ElementRef;
  voucherGenerationList: VoucherGeneration[];
  requestCouponVoucher: RequestInventory;
  requestInventoryVoucher: any[] = [];

  limit: number = 25;
  skip: number = 0;
  p: number = 1;
  count: number = 0;
  voucherId: any;
  userDetails: any;
  lastvalue: number = 0;
  @ViewChild("inputUserFile") inputUserFile: any;
  @ViewChild('inputFileManual') inputFileManual: ElementRef<HTMLInputElement> | undefined;


  private headers: any = {};

  public role: string = "company";
  approvalVoucherList: any = [];
  manualVoucherRequestId: any = [];
  approvalData: any = [];
  approvalStatus: string = "";
  rejectedStatus: string = "";
  supplierType: any;
  productURL: string = "";
  urlError: boolean = false;
  supplierName: any;
  supplierList: any;
  newSupplier: boolean = false;
  supplierDetail: Supplier;
  supplierID: any;
  supplierKeyword = "supplier_name";

  @ViewChild("closeApproveeBtn", { static: false })
  closeApproveeBtn!: ElementRef;
  @ViewChild("closeBtn", { static: true }) closeBtn!: ElementRef;
  @ViewChild("supplierForm", { static: true }) supplierForm: any;

  voucherList: RequestInventory[];

  hide: boolean = false;

  approvestatus: boolean = false;
  rejectstatus: boolean = false;
  approvehide: boolean = true;
  rejecthide: boolean = true;
  pending: boolean = true;

  issuancedate: any;
  suppliershow: boolean = false;
  issuedate: any;
  issuehide: boolean = false;
  statushide: boolean = false;
  logvalue: any;
  tooltipList: any;

  voucherkartvalue: any;
  fileurls: any;
  settingvalue: any;

  paramValue: any = "";

  previewData: any[] = [];
  checkData: any[] = [];
  tableHeaders: string[] = []; 

  constructor(
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private _http: AppService,
    private router: Router,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService
  ) {
    this.voucherGenerationList = new Array<VoucherGeneration>();
    this.requestCouponVoucher = new RequestInventory();
    this.supplierDetail = new Supplier();
    this.voucherList = new Array<RequestInventory>();
    this.role = localStorage.getItem("role") || "";
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.paramValue = params["Voucher_request_id"];
    });
    // console.log('AAAAAAAAAAAAA',this.paramValue)
    let user = localStorage.getItem("userDetails");
    if (typeof user === "string") {
      const parse = JSON.parse(user);
      this.userDetails = parse.data;
    }

    let idval = this.paramValue;
    const page = this.p || 1;
   
   this.getVoucherInvenRequestList(page,idval)
  }


  getVoucherInvenRequestList(page: number, idval: any){

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this._http.getviewlistInven(page,idval, this.headers).subscribe({
      next: (data: any) => {
        if (data.status === 1) {
          this.requestInventoryVoucher = data.data.map((item: any) => {
            return {
              ...item,
              validity: new Date(item.validity).toISOString().split('T')[0] // Extract only the date
            };
          });
          console.log(data);
          this.count = data.totalRecords;
          console.log(this.count);
          if (this.skip === 0) {
            this.lastvalue = this.skip + this.limit;
          }
        } 
         this.fileurls = environmentfiles.ENVIRONMENT.baseURL;
      },
    });
  }




  clear() {
    this.router.navigate(["/digital-order-view"], { replaceUrl: false });
    this.tableHeaders = [];
    this.previewData = [];
    this.resetFileInput();
  }
  resetFileInput() {
    if (this.inputFileManual) {
      this.inputFileManual.nativeElement.value = '';
    }
  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: "hover" });
      });
    }, 500);
  }

  backToSupplier() {
    this.newSupplier = false;
  }

  changeSupplier() {
    if (this.supplierType == "Internal") {
      this.newSupplier = false;
    } else {
      this.newSupplier = false;
    }
  }

  addSupplier() {
    if (
      this.supplierDetail.supplier_name === "" ||
      this.supplierDetail.api_url === "" ||
      this.supplierDetail.api_key === "" ||
      this.supplierDetail.api_authentication_code === ""
    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }
    const urlRegex =
      /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
    const result = this.supplierDetail.api_url.match(urlRegex);
    if (result == null) {
      this.toastr.error("Please enter valid API URL");
      return;
    }
    this.loader.start();
    this.supplierDetail.action = "CREATE";
    this.supplierDetail.created_by_name = this.userDetails.UserName;
    this.supplierDetail.created_by_id = this.userDetails.UserID;

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.addNewSupplier(this.supplierDetail, this.headers).subscribe(
      (data: any) => {
        this.loader.stop();
        if (data.status == 1) {
          this.toastr.success("Supplier request send successfully");
          this.newSupplier = false;
        } else if (data.status === 0) {
          this.toastr.error(data.msg);
        }
      },
      (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    );
  }

  addNewSupplier() {
    this.newSupplier = true;
    this.supplierDetail = new Supplier();
  }

  selectSupplierEvent(item: any) {
    this.supplierID = item.supplier_id;
    this.supplierName = item.supplier_name;
  }

  getVoucherGenerationList(ids: number) {
    this._http.voucherDetailItem.subscribe((data) => {
      // this.requestCouponVoucher = data;
    });

    this.issuancedate = this.requestCouponVoucher.issuance_date;

    if (this.requestCouponVoucher.source == "QwikCilver") {
      let sourcevalue = this.requestCouponVoucher;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      this.loader.start();
      let action = "QC";

      this._http
        .getVoucherGenerationLists(
          ids,
          this.limit,
          this.skip,
          this.headers,
          action
        )
        .subscribe({
          next: (data: any) => {
            this.loader.stop();

            if (data) {
              // this.count = data.count;
              this.voucherGenerationList = data.data;
              this.toolTipInit();

              localStorage.setItem(
                "vouchergenerationlist",
                JSON.stringify(this.voucherGenerationList)
              );
              if (this.skip === 0) {
                this.lastvalue = this.skip + this.limit;
              }
            }
          },
          error: (_error) => {
            this.loader.stop();
          },
        });
    } else if (this.requestCouponVoucher.source == "Ticket Express") {
      let sourcevalue = this.requestCouponVoucher;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      this.loader.start();
      let action = "TE";

      this._http
        .getVoucherGenerationListTE(
          ids,
          this.limit,
          this.skip,
          this.headers,
          action
        )
        .subscribe({
          next: (data: any) => {
            this.loader.stop();

            if (data) {
              // this.count = data.count;
              this.voucherGenerationList = data.data;
              this.toolTipInit();

              localStorage.setItem(
                "vouchergenerationlist",
                JSON.stringify(this.voucherGenerationList)
              );
              if (this.skip === 0) {
                this.lastvalue = this.skip + this.limit;
              }
            }
          },
          error: (_error) => {
            this.loader.stop();
          },
        });
    } else if (this.requestCouponVoucher.source == "Amazon"||this.requestCouponVoucher.source == "ValueDesign" || this.requestCouponVoucher.source == "Joyalukkas") {
      let sourcevalue = this.requestCouponVoucher;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      this.loader.start();
      let action = "";
      action = this.requestCouponVoucher.source == "Amazon" ? "AMAZON" :this.requestCouponVoucher.source=='ValueDesign' ? 'ValueDesign' : "Joyalukkas";
      // let action = "AMAZON";

      this._http
        .getVoucherGenerationListAmazon(
          ids,
          this.limit,
          this.skip,
          this.headers,
          action
        )
        .subscribe({
          next: (data: any) => {
            this.loader.stop();

            if (data) {
              this.count = data.count;
              this.voucherGenerationList = data.data;
              this.toolTipInit();

              localStorage.setItem(
                "vouchergenerationlist",
                JSON.stringify(this.voucherGenerationList)
              );
              if (this.skip === 0) {
                this.lastvalue = this.skip + this.limit;
              }
            }
          },
          error: (_error) => {
            this.loader.stop();
          },
        });
    } else if (this.requestCouponVoucher.source == "EZPIN") {
      let sourcevalue = this.requestCouponVoucher;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      this.loader.start();
      let action = "EZPIN";

      this._http
        .getVoucherGenerationListAmazon(
          ids,
          this.limit,
          this.skip,
          this.headers,
          action
        )
        .subscribe({
          next: (data: any) => {
            this.loader.stop();

            if (data) {
              this.count = data.count;
              this.voucherGenerationList = data.data;
              this.toolTipInit();

              localStorage.setItem(
                "vouchergenerationlist",
                JSON.stringify(this.voucherGenerationList)
              );
              if (this.skip === 0) {
                this.lastvalue = this.skip + this.limit;
              }
            }
          },
          error: (_error) => {
            this.loader.stop();
          },
        });
    } else if (this.requestCouponVoucher.source == "FlipKart") {
      let sourcevalue = this.requestCouponVoucher;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      this.loader.start();
      let action = "FlipKart";

      this._http
        .getVoucherGenerationListAmazon(
          ids,
          this.limit,
          this.skip,
          this.headers,
          action
        )
        .subscribe({
          next: (data: any) => {
            this.loader.stop();

            if (data) {
              this.count = data.count;
              this.voucherGenerationList = data.data;
              this.toolTipInit();

              localStorage.setItem(
                "vouchergenerationlist",
                JSON.stringify(this.voucherGenerationList)
              );
              if (this.skip === 0) {
                this.lastvalue = this.skip + this.limit;
              }
            }
          },
          error: (_error) => {
            this.loader.stop();
          },
        });
    } else if (this.requestCouponVoucher.source == "MEA") {
      let sourcevalue = this.requestCouponVoucher;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      this.loader.start();
      let action = "MEA";

      this._http
        .getVoucherGenerationListAmazon(
          ids,
          this.limit,
          this.skip,
          this.headers,
          action
        )
        .subscribe({
          next: (data: any) => {
            this.loader.stop();

            if (data) {
              this.count = data.count;
              this.voucherGenerationList = data.data;
              console.log("mea", this.voucherGenerationList);
              this.toolTipInit();

              localStorage.setItem(
                "vouchergenerationlist",
                JSON.stringify(this.voucherGenerationList)
              );
              if (this.skip === 0) {
                this.lastvalue = this.skip + this.limit;
              }
            }
          },
          error: (_error) => {
            this.loader.stop();
          },
        });
    } else {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      this.loader.start();
      this._http
        .getVoucherGenerationList(ids, this.limit, this.skip, this.headers)
        .subscribe({
          next: (data: any) => {
            this.loader.stop();
            if (data) {
              this.count = data.count;
              this.voucherGenerationList = data.data;

              this.toolTipInit();

              localStorage.setItem(
                "vouchergenerationlist",
                JSON.stringify(this.voucherGenerationList)
              );

              if (this.skip === 0) {
                this.lastvalue = this.skip + this.limit;
              }
            }
          },
          error: (_error) => {
            this.loader.stop();
          },
        });
    }
  }

  onChangeSupplier(item: any) {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.getSupplierItems(item, this.headers).subscribe((data: any) => {
      this.supplierList = data.data;
    });
  }

  pageChanged(pageNo: number) {
    this.p = pageNo;
    this.skip = (pageNo - 1) * this.limit;
    this.lastvalue = this.skip + 25;
    let idval = this.paramValue;
    this.getVoucherInvenRequestList(pageNo,idval);
    // this.getVoucherGenerationList(this.voucherId);
    localStorage.removeItem("logvalue");
  }

  importUserBulkApproval(event: any) {
    const getImg =
      event.srcElement !== undefined
        ? event.srcElement.value.substr(event.srcElement.value.indexOf("."))
        : "";
    if (getImg.toLowerCase() !== ".csv") {
      // this.showImageError = true;
      this.toastr.error("File not supported");
      return;
    } else {
      // this.showImageError = false;
    }
    // if (this.supplierType == "" || this.supplierType == undefined || this.supplierType == null) {
    //   alert("Please select one supplier");
    //   return;
    // }
    // const element = document.getElementById('myModal') as HTMLElement;
    // const myModal = new Modal(element,
    //   {
    //     backdrop: 'static',
    //     keyboard: false
    //   });
    // myModal.hide();
    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set("Accept", "multipart/form-data");
      const formData = new FormData();
      formData.append("filename", event.target.files[0]);
      formData.append("voucher_request_id", this.voucherId);
      formData.append("user_id", this.userDetails.UserID);
      formData.append("created_by_name", this.userDetails.UserName);
      // formData.append('supplier_type', this.supplierType);
      const selectedFile = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (e: any) => {
        let binaryDara = e.target.result;
        let workbook = XLSX.read(binaryDara, { type: "binary" });
        // workbook.SheetNames.forEach(sheet=>{
        //   this.batchData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        //   console.log(this.batchData)
        // })
      };
      // this.myInputVariable.nativeElement.value = "";
      // this.showBatchData = false;
      // this.supplierType = "Internal";

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          //  'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
        }),
      };

      this._http.importUserBulkApproval(formData, this.headers).subscribe(
        (data: any) => {
          if (data) {
            if (data.status === 1) {
              this.toastr.success("Your file uploaded successfully");
              setTimeout(()=>{
                window.location.reload();
              }, 100);
              this.getVoucherGenerationList(this.voucherId);
            } else if (data.status === 0) {
              this.toastr.error(data.msg);
            }
            this.inputUserFile.nativeElement.value = "";
          }
        },
        (error) => {
          this.toastr.error(error.error.Message);
        }
      );
    }
  }
  back() {
    this.router.navigate(["/inventory-voucher"], { replaceUrl: false });
    localStorage.removeItem("voucherDetail");
    localStorage.removeItem("logvalue");
  }
  view(data: any) {
    // console.log('data',data)
    this.router.navigate([`/wallet`], {
      queryParams: { voucher_id: data.voucher_generation_id },
      replaceUrl: false,
    });
  }

  assignVoucher() {
    if (
      this.supplierType == "" ||
      this.supplierType == undefined ||
      this.supplierType == null ||
      this.supplierName == "" ||
      this.supplierName == undefined ||
      this.supplierName == null
    ) {
      this.toastr.error("Please select one aggregator");
      return;
    }

    let sourceval: string | null = localStorage.getItem("voucherDetail");
    let val = JSON.parse(sourceval || "");
    let sourcevalue = val.voucher_request_id;

    const params = {
      voucherRequestIds: sourcevalue,
      status: this.approvalStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      supplier_id: this.supplierName,
      approved_by_name: this.userDetails.UserName,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this.loader.start();
    this._http.voucherApproval(params, this.headers).subscribe(
      (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        if (data) {
          if (data.status == 1) {
            // this.selectAll = false;
            if (this.approvalStatus == "A" || this.approvalStatus == "Y") {
              this.toastr.success("Voucher approved successfully");
              this.approvestatus = true;
              this.approvehide = false;
              this.rejectstatus = false;
              this.pending = false;
              this.supplierName = "";
              this.supplierList = "";
              // this.supplierForm.resetForm();
            } else if (this.rejectedStatus == "R") {
              this.toastr.error("Voucher rejected");
              // this.selectAll = false;
              // this.showApproveReject = false;
              this.supplierName = "";
              this.supplierList = "";
              // this.supplierForm.resetForm();
            } else {
              this.toastr.success("Vouchers approved successfully");
              // this.selectAll = false;
              // this.showApproveReject = false;
              this.supplierName = "";
              this.supplierList = "";
            }
          } else {
            if (data.status == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              // this.selectAll = false;
              // this.showApproveReject = false;
              this.supplierName = "";
              this.supplierList = "";
              // this.supplierForm.resetForm();
            }
          }
          this.closeBtn.nativeElement.click();
          this.approvalVoucherList = [];
          // this.getVoucherRequestList();
          this.supplierName = "";
          this.supplierList = "";
        }
      },
      (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    );
  }

  approveVoucher() {
    // if (this.supplierType == "" || this.supplierType == undefined || this.supplierType == null) {
    //   this.toastr.error("Please select one supplier");
    //   return;
    // }
    this.router.navigate(["/digital-dashboard"], { replaceUrl: false });

    // let sourceval: string | null = localStorage.getItem("voucherDetail");
    // let val = JSON.parse(sourceval || '');
    // let sourcevalue = val.voucher_request_id;

    const params = {
      voucherRequestIds: this.paramValue,
      status: this.approvalStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      supplier_id: this.supplierName,
      approved_by_name: this.userDetails.UserName,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.loader.start();
    this._http.voucherApproval(params, this.headers).subscribe(
      (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();

        if (data) {
          if (data.status == 1) {
            // this.selectAll = false;
            if (this.approvalStatus == "A" || this.approvalStatus == "Y") {
              this.toastr.success("Voucher approved successfully");

              this.approvestatus = true;
              this.approvehide = false;
              this.rejectstatus = false;
              this.rejecthide = false;
              this.pending = false;
              // this.supplierForm.resetForm();
            } else if (this.rejectedStatus == "R") {
              this.toastr.error("Voucher rejected");
              // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
              this.rejectstatus = true;
              this.pending = false;
              this.approvestatus = false;
              this.rejecthide = false;
            } else {
              this.toastr.success("Vouchers approved successfully");
              // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
              this.approvestatus = true;
              this.approvehide = false;
              this.rejectstatus = false;
              this.rejecthide = false;
              this.pending = false;
              // this.supplierForm.resetForm();
            }
          } else {
            if (data.status == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              // this.supplierForm.resetForm();
              // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
            }
          }
          this.closeBtn.nativeElement.click();
          this.approvalVoucherList = [];
          // this.getVoucherRequestList();
        }
      },
      (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    );
  }

  approveVouchers(requestCouponVoucher: RequestInventory) {
    this.router.navigate(["/digital-dashboard"], { replaceUrl: false });

    // let sourceval: string | null = localStorage.getItem("voucherDetail");
    // let val = JSON.parse(sourceval || '');
    // let sourcevalue = val.voucher_request_id;

    const params = {
      voucherRequestIds: this.paramValue,
      status: this.approvalStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      supplier_id: this.supplierName,
      approved_by_name: this.userDetails.UserName,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.loader.start();
    this._http.voucherApproval(params, this.headers).subscribe(
      (data: any) => {
        this.loader.stop();
        // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
        this.closeBtn.nativeElement.click();

        if (data) {
          if (data.status == 1) {
            // this.selectAll = false;
            if (this.approvalStatus == "A" || this.approvalStatus == "Y") {
              this.toastr.success("Voucher approved successfully");

              this.approvestatus = true;
              this.approvehide = false;
              this.rejectstatus = false;
              this.rejecthide = false;
              this.pending = false;
              // this.supplierForm.resetForm();
            } else if (this.rejectedStatus == "R") {
              this.toastr.error("Voucher rejected");
              // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
              this.rejectstatus = true;
              this.pending = false;
              this.approvestatus = false;
              this.rejecthide = false;
            } else {
              this.toastr.success("Vouchers approved successfully");
              // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
              this.approvestatus = true;
              this.approvehide = false;
              this.rejectstatus = false;
              this.rejecthide = false;
              this.pending = false;
              // this.supplierForm.resetForm();
            }
          } else {
            if (data.status == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              // this.supplierForm.resetForm();
              // this.router.navigate(['/digital-dashboard'], { replaceUrl: false });
            }
          }
          this.closeBtn.nativeElement.click();
          this.approvalVoucherList = [];
          // this.getVoucherRequestList();
        }
      },
      (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    );
  }

  Rejectbrand(requestCouponVoucher: RequestInventory) {
    // let sourceval: string | null = localStorage.getItem("voucherDetail");
    // let val = JSON.parse(sourceval || '');
    // let sourcevalue = val.voucher_request_id;

    const params = {
      voucherRequestIds: this.paramValue,
      status: this.rejectedStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      approved_by_name: this.userDetails.UserName,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.loader.start();
    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        if (data) {
          if (data.status == 1) {
            if (this.rejectedStatus == "R") {
              this.toastr.success("Voucher rejected successfully");
              this.rejectstatus = true;
              // localStorage.setItem('rejectTrue', 'true');

              // const rejectstatus = { rejectTrue: 'true', pending: 'false', approvestatus: 'false' , rejecthide: 'false'};
              // localStorage.setItem('rejectstatus', JSON.stringify(rejectstatus));

              this.pending = false;
              this.approvestatus = false;
              this.rejecthide = false;
              this.router.navigate(["/digital-dashboard"], {
                replaceUrl: false,
              });
            } else {
              this.toastr.success("Vouchers approved successfully");
              this.approvestatus = true;
              this.approvehide = false;
              this.rejectstatus = false;
              this.pending = false;
              this.router.navigate(["/digital-dashboard"], {
                replaceUrl: false,
              });
            }
          } else {
            if (data.statu == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              this.router.navigate(["/digital-dashboard"], {
                replaceUrl: false,
              });
            }
          }
          this.approvalVoucherList = [];
          // this.getVoucherRequestList();
        }
      },
      error: (error: any) => {
        this.loader.stop();
        this.toastr.error(error.Message);
      },
    });
  }

  showSupplierModal(approvalStatus: string) {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.getsettingslist(this.headers).subscribe({
      next: (res: any) => {
        if (res) {
          let setvalue = res.data.order_limit_amount;

          localStorage.setItem("settingslist", setvalue);
        }
      },
    });

    this.settingvalue = localStorage.getItem("settingslist");

    let qccount = 0;
    let othercount = 0;

    let listvalue = this.requestCouponVoucher;

    let sourceval: string | null = localStorage.getItem("voucherDetail");
    // console.log('ttt', JSON.parse(sourceval||''));
    let val: any = {};
    if (sourceval) {
      val = JSON.parse(sourceval);
    }
    let sourcevalue = val.source || listvalue.source;
    let totalval = val.total || listvalue.total;

    let discountval =
      val.voucher_approve_admin || listvalue.voucher_approve_admin;

    let nullcount = 0;

    if (sourcevalue! == "" || sourcevalue! == undefined) {
      nullcount = nullcount + 1;
    }

    if (nullcount > 0) {
      this.toastr.error("Please select source before approval.");
      return;
    }

    if (
      sourcevalue == "QwikCilver" ||
      sourcevalue == "MEA" ||
      sourcevalue == "Ticket Express" ||
      sourcevalue == "Amazon" || sourcevalue == "ValueDesign" ||
      sourcevalue == "FlipKart" ||
      sourcevalue == "EZPIN" ||
      sourcevalue == "YGG" ||
      sourcevalue == "Joyalukkas"
    ) {
      qccount = qccount + 1;
    } else {
      othercount = othercount + 1;
    }

    if (qccount > 0 && othercount > 0) {
      this.toastr.error("please select unique source rows");
      return;
    }

    if (discountval == "YES" && this.userDetails.role === "Admin") {
      this.toastr.error(
        "discount is more than 75% voucher approval by super admin"
      );
      return;
    } else if (
      totalval >= this.settingvalue &&
      this.userDetails.role === "Admin"
    ) {
      this.toastr.error(
        "voucher total amount is High. So, please get approval from super admin"
      );
      return;
    } else {
      if (qccount > 0) {
        // this.approvalbrandList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;
        this.supplierType = "external";
        const element = document.getElementById("approveModals") as HTMLElement;
        const approveModals = new Modal(element, {
          backdrop: "static",
          keyboard: false,
        });
        this.urlError = false;
        this.productURL = "";
        approveModals.show();
        // this.approveVouchers(this.requestCouponVoucher);
      } else {
        // this.approvalbrandList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;
        this.supplierType = "internal";
        const element = document.getElementById(
          "approveModalss"
        ) as HTMLElement;
        const approveModalss = new Modal(element, {
          backdrop: "static",
          keyboard: false,
        });
        this.urlError = false;
        this.productURL = "";
        approveModalss.show();
      }
    }
  }


  manualUpload(requestCouponVoucher: RequestInventory){
    // console.log(requestCouponVoucher.voucher_request_id);
    this.manualVoucherRequestId.push(requestCouponVoucher.voucher_request_id);
    this.approvalData.push(requestCouponVoucher);
    console.log(this.manualVoucherRequestId);
    const element = document.getElementById("manualModals") as HTMLElement;
    const approveModals = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    this.urlError = false;
    this.productURL = "";
    approveModals.show();
    this.tableHeaders = [];
    this.previewData = [];  
    this.resetFileInput();
  }

  // Handle File Change
  onFileChange(event: any) {
    // alert();
    const file = event.target.files[0];
    const fileType = file.name.split('.').pop()?.toLowerCase();
    if (fileType === 'csv') {
      this.parseCSV(file);
    } 
    // else if (fileType === 'xlsx') {
    //   this.parseExcel(file);
    // } 
    else {
      this.toastr.error('Invalid file type! Please upload a .csv or .xlsx file.');
    }
  }

  allowedHeaders: string[] = ['Voucher Code', 'Voucher Pin', 'Validity'];

  // Parse CSV File
  parseCSV(file: File) {
    // alert();
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result as string;
      let quantity = this.requestCouponVoucher.quantity;
      console.log(quantity);
      // return;

      const rows = text.split('\n').map((row) => row.trim()).filter((row) => row !== '');
      const data = rows.map((row) => row.split(','));

      if (data.length - 1 !== quantity) { 
        this.toastr.error(`The number of rows (${data.length - 1}) does not match the expected quantity (${quantity}).`);
        this.resetFileInput();
        return;
      }

     
      if (this.validateHeaderLength(data[0])) {
        // this.tableHeaders = data[0]; 
        this.checkData = data.slice(1);
        if (this.validateData(this.checkData)) {
          this.tableHeaders = data[0]; // First row as headers
          this.previewData = data.slice(1); // Remaining rows as data
        }

        // Create payload based on the allowed headers
      
      } else {
        this.toastr.error(`Invalid column count in CSV file. The file must contain exactly ${this.allowedHeaders.length} columns.`);
      }
  
      this.resetFileInput();

      // const rows = text.split('\n').map((row) => row.split(','));
      // this.tableHeaders = data[0]; 

      // this.previewData = data.slice(1);
    };
    reader.readAsText(file);
  }

  validateHeaderLength(headers: string[]): boolean {
    // Check if the number of headers matches the allowedHeaders length
    return headers.length === this.allowedHeaders.length;
  }

  // validateDataNotEmpty(data: string[][]): boolean {
  //   // Iterate over each row and check if any cell is empty
  //   return data.every(row => row.every(cell => cell.trim() !== ''));
  // }


  validateData(data: string[][]): boolean {
    const currentDate = new Date(); // Get current date
    const voucherCodes = new Set<string>(); // To track duplicate voucher codes

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
  
      // Check if any cell is empty
      if (row.some((cell) => cell.trim() === '')) {
        this.toastr.error(`Row ${i + 1} contains empty data. Please ensure all fields are filled.`);
        return false;
      }
  
      // Validate the 'Validity' column format and date
      const validityIndex = this.allowedHeaders.indexOf('Validity');
      if (validityIndex >= 0) {
        const validityDate = row[validityIndex]?.trim();
        const isValidFormat = this.isValidDateFormat(validityDate);
        if (!isValidFormat) {
          this.toastr.error(`Invalid date format in row ${i + 1}. Please use 'dd/mm/yyyy'.`);
          return false;
        }
  
        const dateParts = validityDate.split('/'); // Split the date into parts
        const parsedDate = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]); // Convert to Date object
  
        if (parsedDate < currentDate) {
          this.toastr.error(`Validity date in row ${i + 1} is earlier than the current date.`);

          // alert(`Validity date in row ${i + 1} is earlier than the current date.`);
          return false;
        }
      }
      const voucherCodeIndex = this.allowedHeaders.indexOf('Voucher Code');
        if (voucherCodeIndex >= 0) {
          const voucherCode = row[voucherCodeIndex]?.trim();
          if (voucherCodes.has(voucherCode)) {
            this.toastr.error(`Duplicate 'Voucher Code' found in row ${i + 1}. Each code must be unique.`);
            return false;
          }
          voucherCodes.add(voucherCode);
        }
    }
    return true;
  }
  
  
  // Helper function to validate the 'dd/mm/yyyy' format
  isValidDateFormat(date: string): boolean {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Regular expression for dd/mm/yyyy format
    if (!dateRegex.test(date)) {
      return false;
    }
  
    // Check if the parsed date is valid
    const dateParts = date.split('/');
    const day = +dateParts[0];
    const month = +dateParts[1];
    const year = +dateParts[2];
  
    // Months are 0-based in JavaScript's Date object
    const parsedDate = new Date(year, month - 1, day);
    return (
      parsedDate.getFullYear() === year &&
      parsedDate.getMonth() === month - 1 &&
      parsedDate.getDate() === day
    );
  }

  // Parse Excel File
  // parseExcel(file: File) {
  //   const reader = new FileReader();
  //   reader.onload = (event: any) => {
  //     const data = new Uint8Array(event.target.result);
  //     const workbook = XLSX.read(data, { type: 'array' });
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  //     this.tableHeaders = jsonData[0] as string[]; // First row as headers
  //     this.previewData = jsonData.slice(1); // Remaining rows as data
  //   };
  //   reader.readAsArrayBuffer(file);
  // }

  submitData() {
    if (this.previewData.length === 0) {
      alert('No data to submit!');
      return;
    }

    // Send data to API
    // const apiUrl = 'https://your-api-endpoint.com/upload';

    // const payload = this.previewData.map((row) =>
    //   this.tableHeaders.reduce((obj: { [key: string]: any }, key: string, index: number) => {
    //     obj[key] = row[index];
    //     return obj;
    //   }, {})
    // );
    // const quantity = this.requestCouponVoucher.quantity;
    // console.log(quantity);
    // return;

    const voucher_request_id = this.manualVoucherRequestId[0].toString(); 
    const voucherData = this.approvalData[0];
    const status = voucherData.status;
    const supplier_type = voucherData.supplier_type;
    const product_url = voucherData.product_url;
    const supplier_id = voucherData.supplier_id;
    const user_name = this.userDetails.firstName;
    const userId = this.userDetails.UserID;
    const approved_by_name = this.userDetails.UserName;


    const formattedPayload = {
      request_id: voucher_request_id, 
        status: status,
        supplier_type: supplier_type,
        product_url: product_url,
        supplier_id: supplier_id,
        user_name: user_name,
        userId: userId,
        approved_by_name: approved_by_name,
      data: this.previewData.map((row) =>
        this.tableHeaders.reduce((obj: { [key: string]: any }, key: string, index: number) => {
          // Adjust key names if needed
          const normalizedKey = key.toLowerCase().replace(/\s+/g, '_'); // Normalize header keys (optional)
          obj[normalizedKey] = row[index];
          return obj;
        }, {})
      ),
    };



    console.log(formattedPayload);

    // return;
    //  const voucher_request_id  = this.manualVoucherRequestId;
    //  console.log(voucher_request_id);

    // return;
    // this["http"].post(apiUrl, payload).subscribe(
    //   (response: any) => {
    //     console.log('Data submitted successfully!', response);
    //   },
    //   (error: any) => {
    //     console.error('Error while submitting data:', error);
    //   }
    // );
    let token = localStorage.getItem("token");
    console.log(token);
    // return;
    this.headers = {
      headers: new HttpHeaders({
        //  'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
      }),
    };
    this.loader.start();


   this._http.importManualDataVoucher(formattedPayload, this.headers).subscribe({
   next: (data: any) => {
    this.loader.stop();
    if (data.status === 1) {
      this.toastr.success(data.msg || "Your file uploaded successfully");
      // this.getVoucherRequestList();
     this.router.navigate(["/digital-dashboard"], { replaceUrl: false });

    } else if (data.status === 0) {
      this.toastr.error(data.msg || "An error occurred while processing the file.");
      // this.clear();
      this.router.navigate(["/digital-dashboard"], { replaceUrl: false });

    }
  },
  error: (error: any) => {
    this.loader.stop();
    // Show a meaningful error message
    const errorMessage =
      error?.error?.msg || error?.message || "An unexpected error occurred.";
    this.toastr.error(errorMessage);
    console.error("Error details:", error); // Log full error for debugging
    // this.clear();
    this.router.navigate(["/digital-dashboard"], { replaceUrl: false });
    // location.reload();

  },
});

  }




  showModa(rejectedStatus: string) {
    this.rejectedStatus = rejectedStatus;
    this.supplierType = "Internal";
    const element = document.getElementById("rejectModal") as HTMLElement;
    const rejectModal = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    rejectModal.show();
  }

  viewurl(url: any) {
    this.clipboard.copy(url);

    if (url) {
      this.toastr.success("Copied the text url");
      return;
    }
  }

  ngOnDestroy() {
    if (this.tooltipList) {
      this.tooltipList.forEach((tooltip: { dispose: () => void }) => {
        tooltip.dispose();
      });
    }
  }
}
