
export class RequestProductmap {

    productsku:any;
    product_sku:any;
    product_name:any;
    amazon_sku:any;
    amazon_discount:any;
    flipkart_discount:any;
    flipkart_sku: any;
    qc_sku:any;
    qc_discount:any;
    te_sku:any;
    te_discount:any;

 

    //add/edit

    rewardkart_sku:any;
    source:any;
    product:any;
    sku:any;
    price:any;
    discount:any;
    issuance_fee:any;
    clientDiscount:any;
    client_id:any;
    created:any;
    id:any;
    joyalukkas_discount: any;
    joyalukkas_sku: any;
    mea_sku: any;
    mea_discount: any;
    ygg_sku: any;
    ygg_discount: any;
    ezpin_sku: any;
    ezpin_discount: any;
    dtone_sku: any;
    dtone_discount: any;
    dt_sku: any;
    dt_discount: any;
    bamboo_discount: any;
    bamboo_sku: any;
    valuedesign_discount: any;
    valuedesign_sku: any;
    likecard_discount: any;
    likecard_sku: any;
}