import { Component, inject } from '@angular/core';
import { environmentfiles } from 'src/environments/environment';
import { AppService } from '../restful-services/restful.service';
import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-rk-catelogue',
  templateUrl: './rk-catelogue.component.html',
  styleUrls: ['./rk-catelogue.component.scss']
})
export class RkCatelogueComponent {
  skip: number = 0;
  limit: number = 50;
  lastvalue: number = 0;
  count: number = 0;
  d: number = 1;
  primaryKey : string[] =[];
  columns : string[] = [];
  datas : any[] = [];
  http = inject(AppService);
  toastr = inject(ToastrService);
  loader = inject(NgxUiLoaderService);
  constructor() {}

  ngOnInit() {
    this.columns = [
      'SKU Id', 'E-Gift Voucher Brand Name ', 'Category',  'Country' , 'Currency', 'Denomination', 'TAT'
    ]
    this.primaryKey = [
      'sku' , 'name', 'category_name' , 'region_full' , 'currency_code', 'Price' , 'expiry'
    ]
    this.getAllProductLists();
  }

  export() {
    const anchor = document.createElement('a');
    anchor.href = `${environmentfiles.ENVIRONMENT.baseURL}downloadAllProduct`; 
    anchor.target = '_self';
    anchor.download = 'sample-file.csv'; 
    anchor.click();
    anchor.remove();
  }
  
  getAllProductLists() {
    this.loader.start();
    const path = 'client/allproduct/list';
    let token = localStorage.getItem("token");
    const headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this.http.httpGet(path , headers).subscribe({
      next : (res:any) => {
        this.loader.stop();
        if (res?.status === 1) {
          this.datas = res.data;
          this.count = this.datas.length;
          if (this.skip === 0) {
            this.lastvalue = this.skip + 50;
          }
        } else {
          this.toastr.error('Something went wrong');
        }
      }, error : (err:any) => {
        this.loader.stop();
      }
    })
  }
  page(event:boolean) {
    if (event) {
      // this.getAllProductLists();
    }
  }
}
