
<div class="row mx-0">
    <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
            <div class="row mb-4">
                <div class="col-3">
                    <label class="texts">Product Mapping</label>
                </div>

                    
                <div class="text-end col-9">
                    <div class="dropdown">
                        <button class="btn btn-success mr-10 float-right" (click)= "addNewProductMap()">Add New</button>
                    </div>
                    </div>
            </div>

            <div class="row my-2">
                <div class="col-md-12">
                    <div class="d-flex">
                    <div>
                        <input type="radio" value="domestic" name="domesitc" id="domestic" class="m-2" [(ngModel)]="type" (change)="TypeChange(type)">
                        <label for="domestic">Domestic</label>
                    </div>
                    <div>
                        <input type="radio" value="internationl" name="international" id="international" class="m-2" [(ngModel)]="type" (change)="TypeChange(type)">
                        <label for="international">International</label>
                    </div>
                    </div>
                </div>
            </div>


            <div class="d-flex">                        
                                            

                <div class="form-floating me-3">
                    <input type="text" [(ngModel)]="productsku" name="productsku"
                        class="form-control border-0 bg-light" placeholder="Product Name / SKU"
                        style="width:210px;padding-top: 13px;" (keypress)="filterList();onKeyPresscomma($event)">
                        <label for="tnums">Product Name / SKU</label>
                </div>


                <button class="btn btn-success mr-5" style="width: 100px;" (click)="filterList()">Search</button>
                <button class="btn btn-secondary mr-5" style="width: 90px;" (click)="clear()">Clear</button>
           
            </div>


         

          

            <div class="mt-4 batch-data px-1">                            
                <div class="row mx-0 adcolor py-4 opacity-75 px-3">                
                    <div class="col labelText text-start">Rewardkart 
                        <br>
                        <span>Product Name / SKU</span>
                    </div>
                    <div class="col labelText text-center"> {{type === 'domestic' ? 'Qwikcilver' : 'Mea'}}
                        <br>  <br>
                        <div class="row colinside">
                            <div class="col border-end">
                                    SKU
                            </div>
                            <div class="col border-end">
                               Discount
                            </div>
                        </div>
                    </div>


                    <div class="col labelText text-center" [title]="type === 'domestic' ? 'Ticket Express' : 'YGG'"> {{type === 'domestic' ? 'TE' : 'YGG'}} 
                        <br>  <br>
                        <div class="row colinside">
                            <div class="col border-end">
                                    SKU
                            </div>
                            <div class="col border-end">
                               Discount
                            </div>
                        </div>
                    </div>     


                             
                    <div class="col labelText text-center"> {{type === 'domestic' ? 'Amazon' : 'EZPIN'}} 
                        <br>  <br>
                        <div class="row colinside">
                            <div class="col border-end">
                                    SKU
                            </div>
                            <div class="col border-end">
                               Discount
                            </div>
                        </div>
                    </div> 

                    <!-- <div class="col labelText text-center"> Action </div> -->

                    <div class="col labelText text-center pe-0"> {{type === 'domestic' ? 'FlipKart' : 'DT ONE'}} 
                        <br>  <br>
                        <div class="row colinside">
                            <div class="col border-end">
                                    SKU
                            </div>
                            <div class="col border-end">
                               Discount
                            </div>
                        </div>
                    </div> 
                    <div class="col labelText text-center pe-0"> {{type === 'domestic' ? 'Joyalukkas' : 'Bamboo'}}
                        <br>  <br>
                        <div class="row colinside">
                            <div class="col border-end">
                                    SKU
                            </div>
                            <div class="col border-end">
                               Discount
                            </div>
                        </div>
                    </div> 
                    <div class="col labelText text-center pe-0"> {{type === 'domestic' ? 'Joyalukkas' : 'Bamboo'}}
                        <br>  <br>
                        <div class="row colinside">
                            <div class="col border-end">
                                    SKU
                            </div>
                            <div class="col border-end">
                               Discount
                            </div>
                        </div>
                    </div> 
                    <div class="col labelText text-center pe-0">{{type === 'domestic' ? 'ValueDesign' : 'LikeCard'}} 
                        <br>  <br>
                        <div class="row colinside">
                            <div class="col border-end">
                                    SKU
                            </div>
                            <div class="col border-end">
                               Discount
                            </div>
                        </div>
                    </div> 


                   

                </div>


                <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light"
                *ngIf="productmapList && productmapList.length === 0 && !apiLoading">
                    <div class="card-body row mx-0 ps-0">
                        <div class="text-center">
                            No Record Found.
                        </div>
                    </div>
                </div>

                <div class=""
                *ngFor="let list of productmapList | paginate: {itemsPerPage: limit, currentPage: d,totalItems:count};let i = index">
                <div class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light" ng-reflect-ng-class="bg-light">
                    <div class="card-body row mx-0 p-0">

                       
                        <div class="col text-start border-end py-3" title="{{list.product_name}}"> {{list.product_name}} 
                            <br>
                            <span>({{list.product_sku}})</span>
                            <!-- <span *ngIf="!list.qc_sku">( - )</span> -->
                        </div>                          
                       
                        
                        <div class="col text-truncate text-start border-end py-3"> 
                            <div class="row">
                                <div class="col">
                                    {{type === 'domestic' && list.qc_sku ? list.qc_sku : type === 'internationl' && list.mea_sku ? list.mea_sku  : '-' }}
                                </div>
                                <div class="col text-end">
                                    {{(type === 'domestic' && list.qc_discount && list.qc_discount !== '-') ? list.qc_discount+'%' : type === 'internationl' && list.mea_discount && list.mea_discount !== '-' ? list.mea_discount+'%':'-'}}
                                </div>
                            </div>
                        </div>

                        <div class="col text-truncate text-start border-end py-3"> 
                           
                            <div class="row">
                                <div class="col">
                                    {{type === 'domestic' && list.te_sku ? list.te_sku : type === 'internationl' && list.ygg_sku ? list.ygg_sku  : '-' }}
                                </div>
                                <div class="col text-end">
                                    {{(type === 'domestic' && list.te_discount && list.te_discount !== '-') ? list.te_discount+'%' : type === 'internationl' && list.ygg_discount && list.ygg_discount !== '-' ? list.ygg_discount+'%':'-'}}
                                </div>
                            </div>
                        </div>

                        <div class="col text-truncate text-start border-end py-3"> 
                           
                            <div class="row">
                                <div class="col">
                                    {{type === 'domestic' && list.amazon_sku ? list.amazon_sku : type === 'internationl' && list.ezpin_sku ? list.ezpin_sku  : '-' }}
                                </div>
                                <div class="col text-end">
                                    {{(type === 'domestic' && list.amazon_discount && list.amazon_discount !== '-') ? list.amazon_discount+'%' : type === 'internationl' && list.ezpin_discount && list.ezpin_discount !== '-' ? list.ezpin_discount+'%':'-'}}
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col text-truncate text-center border-end py-3"> 
                           
                            <div class="row">
                                <div class="col">
                                    <a class="linkColor downloadIcon" #Edit (click)="editbrand(list)">
                                        <i class="fa fa-edit mr-10" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"></i>
                                    </a>
                                </div>
                            </div>
                        </div> -->

                        <div class="col text-truncate text-start border-end py-3"> 
                            <div class="row">
                                <div class="col">
                                    {{type === 'domestic' && list.flipkart_sku ? list.flipkart_sku : type === 'internationl' && list.dt_sku ? list.dt_sku  : '-' }}
                                </div>
                                <div class="col text-end">
                                    {{(type === 'domestic' && list.flipkart_discount && list.flipkart_discount !== '-') ? list.flipkart_discount+'%' : type === 'internationl' && list.dt_discount && list.dt_discount !== '-' ? list.dt_discount+'%':'-'}}
                                </div>
                            </div>
                        </div>
                        <div class="col text-truncate text-start border-end py-3"> 
                            <div class="row">
                                <div class="col">
                                    {{ (type === 'domestic'  && list.joyalukkas_sku) ? list.joyalukkas_sku : (type === 'internationl' && list.bamboo_sku) ? list.bamboo_sku : '-' }}
                                </div>
                                <div class="col text-end">
                                    {{ (type === 'domestic' && list.joyalukkas_discount && list.joyalukkas_discount !== '-') ? list.joyalukkas_discount + '%' : (type === 'internationl' && list.bamboo_discount && list.bamboo_discount !== '-') ? list.bamboo_discount + '%' : '-' }}
                                </div>
                                
                            </div>
                        </div>
                        <div class="col text-truncate text-start border-end py-3"> 
                            <div class="row">
                                <div class="col">
                                    {{ (type === 'domestic'  && list.valuedesign_sku) ? list.valuedesign_sku : (type === 'internationl' && list.likecard_sku) ? list.likecard_sku : '-' }}
                                </div>
                                <div class="col text-end">
                                    {{ (type === 'domestic' && list.valuedesign_discount && list.valuedesign_discount !== '-') ? list.valuedesign_discount + '%' : (type === 'internationl' && list.likecard_discount && list.likecard_discount !== '-') ? list.likecard_discount + '%' : '-' }}
                                </div>
                                
                            </div>
                        </div>

                     
                      </div>
                </div>
            </div>



            <div>

                <div class="row" *ngIf="productmapList && productmapList.length > 0">
                    <div class="col-6 pt-3">
                        <p>Showing {{d > 1 ? skip + 1 :d}} to {{lastvalue > count ? count : lastvalue}} of
                            {{count}} entries</p>
                    </div>
                    <div class="col-6">
                        <pagination-controls class="list-pagination adpage" (pageChange)="pageChanged($event)">
                        </pagination-controls>
                    </div>
                </div>



            </div>








            </div> 
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>

<div class="modal fade" id="productmapModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true" style="position: fixed;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body row">                   
                
                <p class="texthead">Add New Product :</p>

                <form #addForm="ngForm" name="form" (ngSubmit)="AddProductmap(Addrequestproductmap)" class="row
                ">   
                    
                    <div class="col-3 md-form form-sm">                      
                            <label for="product" class="mb-2">Product Name<span class="text-danger">*</span></label>
                            <ng-autocomplete [data]="productData" [searchKeyword]="productKeyword" placeholder="Select Product Name" (inputCleared)='productsClear($event)'
                                [itemTemplate]="itemTemplateproduct" (keydown)="restrictEnter($event)"  (selected)='selectproductEvent($event)' (keypress)="onKeyPresscomma($event)"
                                [(ngModel)]="Addrequestproductmap.product_name" name="product_name" #product_name="ngModel" required
                                mdbInput autocomplete="on"  (inputChanged)='onChangeProduct($event)' 
                                required [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                            <ng-template #itemTemplateproduct let-item>
                                <a [innerHTML]="item"></a>                           
                            </ng-template>
    
                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                            <span class="text-danger" *ngIf="(product_name.touched || addForm.submitted) &&  product_name.errors?.['required']">
                                product name is required
                            </span>
                      
                    </div> 


                    <div class="col-3 md-form form-sm" *ngIf = "skuhide">
                        <div class="md-form form-sm">
                            <label  class="mb-2">Rewardkart SKU :
                                <span class="text-danger">*</span>
                            </label>                      
                            
                            <input class="form-control"                        
                                [(ngModel)]="Addrequestproductmap.rewardkart_sku" name="rewardkart_sku" #rewardkart_sku="ngModel" disabled>                             
                         
                                <span class="text-danger" *ngIf="(rewardkart_sku.touched || addForm.submitted) &&  rewardkart_sku.errors?.['required']">
                                    rewardkart SKU is required
                                </span>
                        </div>
                    </div>


                    
                        <div class="col-3 md-form form-sm">
                            <label for="source" class="mb-2">Source 
                                <span class="text-danger">*</span>
                            </label>
                            <select id="source" class="form-select" placeholder="Select Source" [(ngModel)]="Addrequestproductmap.source"
                                name="source" #source="ngModel" (change)='selectsourceEvent(Addrequestproductmap.source)'required>
                                 
                                <option value="" selected hidden>Select Source</option>
                                <option value="Amazon">Amazon</option>                 
                                <option value="FlipKart">FlipKart</option>                       
                                <option value="QwikCilver">Qwikcilver</option>
                                <option value="Ticket Express">Ticket Express</option>
                                <option value="EZPIN">EZPIN</option>  
                                <option value="MEA">MEA</option>  
                                <option value="DT">DT</option> 
                                <option value="YGG">YOU GOTA GIFT</option>      
                                <option value="Bamboo">Bamboo</option>  
                                <option value="ValueDesign">Value Design</option>    
                                <option value="LikeCard">LikeCard</option>                     
                            </select>
                            <span class="text-danger" *ngIf="
                                (source.touched || addForm.submitted) &&
                                source.errors?.['required']
                              ">
                              source is required
                            </span>
                        </div>                               

                 
                        <div class="col-3 md-form form-sm my-input-class">
                            <label for="product" class="mb-2">Product 
                                <span class="text-danger">*</span>
                            </label>
                            <ng-autocomplete  [data]="selectproductData" [searchKeyword]="selectproductKeyword" placeholder="Select Product"
                                [itemTemplate]="itemTemplatebrand" (keypress)="onKeyPresscomma($event)"
                                [(ngModel)]="Addrequestproductmap.product" name="product" #product="ngModel"  (selected)='selectproductselectEvent($event)' 
                                mdbInput autocomplete="on" (inputChanged)='onChangeProductselect($event)' (keydown)="restrictEnter($event)"
                              required [notFoundTemplate]="notFoundTemplate" required>
                            </ng-autocomplete>
                            <ng-template #itemTemplatebrand let-item>
                                <a [innerHTML]="item.BrandName"></a>
                            </ng-template>
    
                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                            <span class="text-danger" *ngIf="
                                (product.touched || addForm.submitted) &&
                                product.errors?.['required']
                              ">
                              product is required
                            </span>
                        </div>
                  
                  
                 
                    <p class="mt-3 btnalig">
                        <button class="btn btn-secondary mr-5 px-3 py-2" data-bs-dismiss="modal" #closeProductmapBtn
                            type="button"  (click)="backclear()">Cancel</button>
                            <button class="btn btn-success px-4 py-2" type="submit">Save</button>                           
                    </p>


                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="categoryModal" tabindex="-1" aria- labelledby="exampleModalLabel" aria-hidden="true" style="position: fixed;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content h-100">
            <div class="modal-body text-center">    
                <div class="row mx-0" *ngIf="qcSku">
                    <div class="col-md-4">
                        <div class="md-form form-sm my-3 leftAlign">
                            <label class="mb-2">Product Name
                                <span class="text-danger">*</span>
                            </label>
                             <input type="text" [(ngModel)]="qcproductName" 
                                class="form-control" placeholder="Enter Product Name" mdbInput
                                autocomplete="off" name="productName"
                                 [disabled]="true">
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="md-form form-sm my-3 leftAlign">
                            <label class="mb-2">Product SKU
                                <span class="text-danger">*</span>
                            </label>
                             <input type="text" [(ngModel)]="qcproductSku" 
                                class="form-control" placeholder="Enter ProductSku" mdbInput
                                autocomplete="off" name="productSku"
                                 [disabled]="true">
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="md-form form-sm my-3 leftAlign">
                            <label class="mb-2">Qwikcilver SKU
                                <span class="text-danger">*</span>
                            </label>
                             <input type="text" [(ngModel)]="qcsku" 
                                class="form-control" placeholder="Enter SKU" mdbInput
                                autocomplete="off" name="sku">
                        </div> 
                    </div>
                    
                </div>
                <div class="row mx-0" *ngIf="teSku">
                    <div class="col-md-4">
                        <div class="md-form form-sm my-3 leftAlign">
                            <label class="mb-2">Product Name
                                <span class="text-danger">*</span>
                            </label>
                             <input type="text" [(ngModel)]="teproductName" 
                                class="form-control" placeholder="Enter Product Name" mdbInput
                                autocomplete="off" name="productName"
                                 [disabled]="true">
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="md-form form-sm my-3 leftAlign">
                            <label class="mb-2">Product SKU
                                <span class="text-danger">*</span>
                            </label>
                             <input type="text" [(ngModel)]="teproductSku" 
                                class="form-control" placeholder="Enter ProductSku" mdbInput
                                autocomplete="off" name="productSku"
                                 [disabled]="true">
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="md-form form-sm my-3 leftAlign">
                            <label class="mb-2">Ticket Express SKU
                                <span class="text-danger">*</span>
                            </label>
                             <input type="text" [(ngModel)]="tesku" 
                                class="form-control" placeholder="Enter SKU" mdbInput
                                autocomplete="off" name="sku">
                        </div> 
                    </div>
                    
                </div>
                <div class="row mx-0" *ngIf="amazonSku">
                    <div class="col-md-4">
                        <div class="md-form form-sm my-3 leftAlign">
                            <label class="mb-2">Product Name
                                <span class="text-danger">*</span>
                            </label>
                             <input type="text" [(ngModel)]="amazonproductName" 
                                class="form-control" placeholder="Enter Product Name" mdbInput
                                autocomplete="off" name="productName"
                                 [disabled]="true">
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="md-form form-sm my-3 leftAlign">
                            <label class="mb-2">Product SKU
                                <span class="text-danger">*</span>
                            </label>
                             <input type="text" [(ngModel)]="amazonproductSku" 
                                class="form-control" placeholder="Enter ProductSku" mdbInput
                                autocomplete="off" name="productSku"
                                 [disabled]="true">
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="md-form form-sm my-3 leftAlign">
                            <label class="mb-2">Amazon SKU
                                <span class="text-danger">*</span>
                            </label>
                             <input type="text" [(ngModel)]="amazonsku" 
                                class="form-control" placeholder="Enter SKU" mdbInput
                                autocomplete="off" name="amazonsku">
                        </div> 
                    </div>
                    
                </div>
            
                <div class="mt-3 row mx-0">
                    <div class="col-md-12 text-end">
                        <button class="btn btn-secondary mx-3 px-4 py-2" data-bs-dismiss="modal" #closeCategoryBtn
                        type="button" (click)="close()">Close</button>
                        <button class="btn btn-primary mx-3 px-4 py-2" data-bs-dismiss="modal" #closeCategoryBtn
                        type="button" (click)="saveChanges(idss)">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





