import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { INgxMyDpOptions, IMyDateModel } from "@mksasi/ngx-mydatepicker";
import { DatePipe, CommonModule } from "@angular/common";
import { AppService } from "../restful-services/restful.service";
import { ChartData, ChartOptions, ChartType } from "chart.js";
import { RequestInventory } from "../models/RequestInventory";
import { saveAs } from "file-saver";
// import { formatDate } from '@angular/common';
import { HttpHeaders } from "@angular/common/http";
import * as XLSX from "xlsx";
import { Modal } from "bootstrap";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subscription } from "rxjs";
import { browserRefresh } from "../app.component";
import { Supplier } from "../models/supplierList";
import { environment, environmentfiles } from "../../environments/environment";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { FormsModule, NgForm } from "@angular/forms";

declare var bootstrap: any;

@Component({
  selector: "vocuher-dashboard",
  templateUrl: "./inventory-voucher.component.html",
  styleUrls: ["./inventory-voucher.component.scss"],
  standalone: true,
  imports: [NgxPaginationModule, NgxUiLoaderModule, CommonModule, FormsModule],
})
export class InventoryVoucherComponent implements OnInit {
  [x: string]: any;
  @ViewChild("pdflist", { static: true }) pdflist!: ElementRef;
  @ViewChild("closeBtn", { static: true }) closeBtn!: ElementRef;
  @ViewChild("inputFileManual") inputFileManual:
    | ElementRef<HTMLInputElement>
    | undefined;

  @ViewChild("closeApproveeBtn", { static: false })
  closeApproveeBtn!: ElementRef;
  @ViewChild("closeImportBtn", { static: true }) closeImportBtn!: ElementRef;
  @ViewChild("closeModal") closeModal!: ElementRef;

  public doughnutChartLabels: string[] = [];
  public doughnutChartData: ChartData<"doughnut"> = {
    datasets: [],
  };
  public doughnutChartType: ChartType = "doughnut";

  public startOptions: INgxMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
  };
  public endOptions: INgxMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
  };
  public trackData: any;
  public trackCount: any;
  public trackDatas: any = [];
  public getstartDate: any;
  public getendDate: any;
  public startDate: any;
  public endDate: any;
  public showChart: boolean = false;
  public role: any = "company";
  voucherList: RequestInventory[];
  public company: string = "";
  public brand: string = "";
  public approved: string = "";
  public type: string = "";
  public modal: string = "";

  public category: string = "";
  limit: number = 25;

  skip: number = 0;
  p: number = 1;
  count: number = 0;
  user_type: any;
  userDetails: any;
  supplierType: any;
  approvalVoucherList: any = [];
  approvalData: any = [];
  manualVoucherRequestId: any = [];
  voucherStatus: string = "";
  selectAll: boolean = false;
  showApproveReject: boolean = false;
  approvalStatus: string = "";
  supplierImportType: string = "";
  selectAllChecked: boolean = false;
  lastvalue: number = 0;
  @ViewChild("inputFile") inputFile: any;
  browserRefresh: boolean = false;
  showRemoveImport: boolean = true;
  rejectedStatus: string = "";
  supplierList: any;
  supplierName: any;
  supplierKeyword = "supplier_name";
  supplierID: any;
  supplierDetail: Supplier;
  newSupplier: boolean = false;
  tooltipList: any;
  apiLoading: boolean = false;
  private headers: any = {};
  requestCouponVoucher: RequestInventory;
  urlError: boolean = false;
  productURL: string = "";

  @ViewChild("supplierForm", { static: true }) supplierForm: any;
  fileurls: any;

  settingvalue: any;

  disticon: any;
  distsicon: any;
  filename: any;
  productname: any;
  vochercode: any;
  previewData: any[] = [];
  checkData: any[] = [];
  tableHeaders: string[] = [];

  constructor(
    public datePipe: DatePipe,
    private _http: AppService,
    private router: Router,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService
  ) {
    this.voucherList = new Array<RequestInventory>();
    this.supplierDetail = new Supplier();
    this.requestCouponVoucher = new RequestInventory();
  }

  ngOnInit(): void {
    const d: Date = new Date();
    this.role = localStorage.getItem("role");
    let user = localStorage.getItem("userDetails");
    if (typeof user === "string") {
      const parse = JSON.parse(user);
      this.userDetails = parse.data;
    }
    this.startOptions = {
      dateFormat: "dd/mm/yyyy",
      disableSince: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate() + 1,
      },
    };
    this.getstartDate = {
      date: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
    };

    this.endOptions = {
      dateFormat: "dd/mm/yyyy",
      disableUntil: {
        year: this.getstartDate.date.year,
        month: this.getstartDate.date.month,
        day: this.getstartDate.date.day,
      },
    };
    this.getendDate = {
      date: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate(),
      },
    };

    this.startDate =
      d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    this.endDate =
      d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();

    this.category = "Digital";

    let page: any = localStorage.getItem("pagesNo");

    if (page !== null) {
      this.pageChanged(page);
    } else {
      this.getVoucherRequestList();
    }

    this.browserRefresh = browserRefresh;
    if (this.browserRefresh === true) {
      this.p = 1;
      this.pageChanged(this.p);
    }

    if (this.role === "company") {
      this.type = "ID";
    }
    if (this.role === "brand") {
      this.type = "I";
    }

    this.fileurls = environmentfiles.ENVIRONMENT.baseURL;

    this.disticon = "distribution_icon.svg";
  }

  addNewCoupon() {
    this.voucherList = [];
    localStorage.removeItem("voucherDetail");
    localStorage.removeItem("edit");

    localStorage.removeItem("authboolean");
    localStorage.removeItem("pdthide");
    // location.reload();
    if (this.role === "Company") {
      this.router.navigate(["/client-add-digital"], { replaceUrl: false });
    } else {
      this.router.navigate(["/add-digital"], { replaceUrl: false });
    }
  }

  // filterList() {
  //   // if (
  //   //   this.filename
  //   // ) {
  //     let fileName = this.filename;
  //     console.log(fileName);
  //     return;
  //     this.skip = 0;
  //     this.p = 1;
  //   // }
  //   this.getVoucherRequestListfilter();
  // }

  filterList() {

      this.skip = 0;
      this.p = 1;
    
    this.showRemoveImport = true;

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this.apiLoading = true;
    const query: any = {};
  if (this.filename) {
    query.file_name = this.filename;
  }
  if (this.productname) {
    query.product_name = this.productname;
  }
  if (this.vochercode) {
    query.voucher_code = this.vochercode;
  }
    this._http
      .getInventoryListFilter(
        query,
        this.headers
      )
      .subscribe({
        next: (data: any) => {
          if (data) {
            // console.log(data);
            this.count = data.totalRecords;
            // console.log(this.count);
            this.voucherList = data.data;

            console.log(this.voucherList);
            this.voucherList = data.data.map((item: any) => {
              return {
                ...item,
                create_date: new Date(item.create_date).toISOString().split('T')[0] 
              };
            });


            this.apiLoading = false;
            this.toolTipInit();
            if (this.skip == 0) {
              this.lastvalue = this.skip + 25;
            }
          }
        },
        error: (_error) => {
          this.apiLoading = false;
          this.loader.stop();
        },
      });
  }

  getVoucherRequestList() {
    if (this.role === "admin" || this.role === "SuperAdmin") {
      this.user_type = "ALL";
    } else if (
      this.role === "company" ||
      this.role === "brand" ||
      this.role === "Company"
    ) {
      this.user_type = "Restricted";
    }
    if (this.role === "company" || this.role === "Company") {
      this.type = "ID";
      this.company = this.userDetails.entity_name;
    } else if (this.role === "brand") {
      this.type = "";
      this.brand = this.userDetails.UserName;
    }

    this.showRemoveImport = true;
    let action = "international";

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this.loader.start();
    this.apiLoading = true;
    this._http
    .getInventoryList(
      this.p,
      this.limit,
      this.headers
    )
      .subscribe({
        next: (data: any) => {
          this.loader.stop();
          if (data) {
            console.log(data);
            this.count = data.totalRecords;
            this.voucherList = data.data;

            this.voucherList = data.data.map((item: any) => {
              return {
                ...item,
                create_date: new Date(item.create_date).toISOString().split('T')[0] 
              };
            });
            // console.log(this.voucherList);
            this.apiLoading = false;
            this.toolTipInit();
            if (this.skip == 0) {
              this.lastvalue = this.skip + 25;
            }
          }
        },
        error: (_error) => {
          this.apiLoading = false;
          this.loader.stop();
        },
      });
  }

  editVoucher(voucherList: RequestInventory) {
    // let currentDate =new Date(voucherList.validity);
    // let dateSent =new Date(voucherList.start_date);

    //  let tday = currentDate.getDate();
    //  let tdays = dateSent.getDate();

    //  voucherList.validity = tday-tdays;

    let dat: any = voucherList.source;
    let validity = voucherList.validity;

    if (
      dat !== "QwikCilver" &&
      dat !== "Ticket Express" &&
      dat !== "Amazon" &&
      dat !== "ValueDesign" &&
      dat !== "FlipKart" &&
      dat !== "EZPIN" &&
      dat !== "Joyalukkas"
    ) {
      const start = new Date(voucherList.start_date);
      const end = new Date(voucherList.validity);
      const timeDiff = Math.abs(end.getTime() - start.getTime());
      let difference = Math.ceil(timeDiff / (1000 * 3600 * 24));
      voucherList.validity = difference;
    }

    this._http.voucherDetails(voucherList);
    const id = voucherList.voucher_request_id;
    this.router.navigate(
      [this.role === "Company" ? "/client-add-digital" : "/add-digital"],
      {
        state: { id, validity },
        replaceUrl: true,
      }
    );
  }

  pageChanged(pagesNo: any) {
    this.p = pagesNo;
    this.skip = (pagesNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    localStorage.setItem("pagesNo", pagesNo);
    this.getVoucherRequestList();

    this.selectAllChecked = false;
    this.showApproveReject = false;
    this.showRemoveImport = true;
    this.approvalVoucherList = [];
    this.manualVoucherRequestId = [];
  }

  clear() {
    this.company = "";
    if (this.role === "company") {
      this.type = "ID";
    } else if (this.role === "brand") {
      this.type = "I";
    } else {
      this.type = "";
    }
    this.brand = "";
    this.category = "Digital";
    this.approved = "";
    this.selectAllChecked = false;
    this.showApproveReject = false;
    this.voucherList = [];
    this.approvalVoucherList = [];
    this.manualVoucherRequestId = [];
    this.getVoucherRequestList();
    this.p = 1;
    let page = this.p;
    this.pageChanged(page);
    this.tableHeaders = [];
    this.previewData = [];
    this.resetFileInput();
  }


  clearform(voucherRequestForm: NgForm) {
    this.company = "";
    if (this.role === "company") {
      this.type = "ID";
    } else if (this.role === "brand") {
      this.type = "I";
    } else {
      this.type = "";
    }
    this.brand = "";
    this.category = "Digital";
    this.approved = "";
    this.selectAllChecked = false;
    this.showApproveReject = false;
    this.voucherList = [];
    this.approvalVoucherList = [];
    this.manualVoucherRequestId = [];
    this.getVoucherRequestList();
    this.p = 1;
    let page = this.p;
    this.pageChanged(page);
    this.tableHeaders = [];
    this.previewData = [];
    this.resetFileInput();
    voucherRequestForm.resetForm();

  }


  closeModule() {
    this.router.navigate(["/inventory-voucher"], { replaceUrl: false });
    this.tableHeaders = [];
    this.previewData = [];
    this.resetFileInput();
  }

  resetFileInput() {
    if (this.inputFileManual) {
      this.inputFileManual.nativeElement.value = "";
    }
  }

  viewGenerationList(voucherList: RequestInventory) {
    this._http.voucherDetails(voucherList);
    const id = voucherList.id;
    console.log(id);
    // return;
    localStorage.setItem("voucherDetail", JSON.stringify(voucherList));
    this.router.navigate(["/view-voucher"], {
      queryParams: { Voucher_request_id: id },
      state: { id },
      replaceUrl: true,
    });

    localStorage.removeItem("logvalue");
  }

  exportVoucher(voucherRequestId: number) {
    let token = localStorage.getItem("token");
    // console.log(token);
    this.headers = {
      headers: new HttpHeaders({
        //  'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
      }),
    };
    this._http.exportInventoryDatas(
      voucherRequestId
    );
  }

  exportAllVoucher() {
    if (this.role === "admin" || this.role === "SuperAdmin") {
      this.user_type = "domestic";
    } else if (
      this.role === "company" ||
      this.role === "brand" ||
      this.role === "Company"
    ) {
      this.user_type = "Restricted";
    }

    // this.loader.start();
    this._http.exportAllVouchers(
      this.user_type,
      this.userDetails.UserID,
      "voucher"
    );
  }

  // exportVoucher(voucherRequestId: number) {

  //   if (this.role === "admin") {
  //     this.user_type = "ALL"
  //   }
  //   else if (this.role === "company" || this.role === "brand") {
  //     this.user_type = "Restricted";
  //   }

  //   let token = localStorage.getItem("token");
  //   this.headers = {
  //      headers: new HttpHeaders({
  //        'Content-Type': 'application/json',
  //        'Authorization': 'Bearer ' + token
  //      })
  //    };

  //   this.loader.start();
  //   this._http.exportVoucherData(voucherRequestId,this.user_type,this.userDetails.UserID,'voucher',this.headers).subscribe({
  //     next: (data: any) => {
  //       this.loader.stop();
  //       saveAs(data, `voucher.csv`)
  //     },
  //     error: (_error) => {
  //       this.loader.stop();
  //     }
  //   })
  // }

  // exportAllVoucher() {
  //   if (this.role === "admin") {
  //     this.user_type = "ALL"
  //   }
  //   else if (this.role === "company" || this.role === "brand") {
  //     this.user_type = "Restricted";
  //   }

  //   let token = localStorage.getItem("token");
  //   this.headers = {
  //      headers: new HttpHeaders({
  //        'Content-Type': 'application/json',
  //        'Authorization': 'Bearer ' + token
  //      })
  //    };

  //   this.loader.start();
  //   this._http.exportAllVoucher(this.user_type, this.userDetails.UserID, 'voucher',this.headers).subscribe(
  //     (data: any) => {
  //       this.loader.stop();
  //       saveAs(data, `AllVouchers.csv`)
  //     }, _error => {
  //       this.loader.stop();
  //     })
  // }

  showSupplierImportModal() {
    this.supplierImportType = "Internal";
    const element = document.getElementById("importModal") as HTMLElement;
    const importModal = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    importModal.show();
  }

  importVoucher(event: any) {
    const getImg =
      event.srcElement !== undefined
        ? event.srcElement.value.substr(event.srcElement.value.indexOf("."))
        : "";
    if (getImg.toLowerCase() !== ".csv") {
      // this.showImageError = true;
      this.toastr.error("File not supported");
      return;
    } else {
      // this.showImageError = false;
    }
    // if (this.supplierImportType == "" || this.supplierImportType == undefined || this.supplierImportType == null) {
    //   this.toastr.error("Please select one supplier");
    //   return;
    // }
    // this.closeImportBtn.nativeElement.click();
    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set("Accept", "multipart/form-data");
      const formData: any = new FormData();
      formData.append("filename", event.target.files[0]);
      // formData.append('supplier_type', this.supplierImportType);
      formData.append("created_by", this.userDetails.UserID);
      formData.append("created_by_name", this.userDetails.UserName);
      // const selectedFile = event.target.files[0];
      // const fileReader = new FileReader();
      // fileReader.readAsBinaryString(selectedFile);
      // fileReader.onload = (e: any) => {
      //   let binaryDara = e.target.result;
      //   let workbook = XLSX.read(binaryDara, { type: 'binary' });
      //   // workbook.SheetNames.forEach(sheet=>{
      //   //   this.batchData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
      //   //   console.log(this.batchData)
      //   // })
      // }
      // this.myInputVariable.nativeElement.value = "";
      // this.showBatchData = false;

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          //  'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
        }),
      };

      // this.loader.start();

      this._http.importUserDataVoucher(formData, this.headers).subscribe({
        // this._http.importUserBulkApproval(formData,this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();
          if (data.status === 1) {
            this.toastr.success("Your file uploaded successfully");
            this.getVoucherRequestList();
          } else if (data.status === 0) {
            this.toastr.error(data.msg);
          }
          this.inputFile.nativeElement.value = "";
        },
        error: (error: any) => {
          this.loader.stop();
          this.toastr.error(error.Message);
        },
      });
    }
  }

  groupbulkimportfileupload(event: any) {
    // Validate the uploaded file type
    const fileExtension =
      event?.target?.files[0]?.name.split('.').pop()?.toLowerCase();
  
    if (fileExtension !== 'csv') {
      this.toastr.error("File not supported");
      return;
    }
  
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (e: any) => {
        const csvContent = e.target.result;
        console.log('CSV Content:', csvContent);
  
        // Parse CSV content
        const rows = csvContent
          .split("\n")
          .filter((row: string) => row.trim() !== "");
  
        if (rows.length < 2) {
          this.toastr.error("CSV file does not contain valid data");
          return;
        }
  
        const headers = rows[0].split(",").map((header: string) => header.trim());
  
        const data = rows.slice(1).map((row: string) => {
          const values = row.split(",");
          const obj: Record<string, any> = {}; // Explicitly type the object
          headers.forEach((header: string, index: number) => {
            obj[header] = values[index]?.trim();
          });
          return obj;
        });
  
        const payload = {
          file_name: "voucher_upload",
          data,
        };
  
  
        const token = localStorage.getItem("token");
        this.headers = {
          headers: new HttpHeaders({
            Authorization: "Bearer " + token,
          }),
        };
  
        this._http.groupinventoryfileupload(payload, this.headers).subscribe({
          next: (response: any) => {
            this.loader.stop();
            if (response.status === 1) {
              this.toastr.success(response.msg);
          this.getVoucherRequestList();     

            } else {
              this.toastr.error(response.msg);
              this.getVoucherRequestList();     

            }
  
            this.inputFile.nativeElement.value = "";
          },
          error: (error: any) => {
            this.loader.stop();
            const errorMessage =
            error?.error?.msg ||
            error?.message ||
            "An unexpected error occurred.";
          this.toastr.error(errorMessage);  
          this.getVoucherRequestList();     
           },
        });

      this.resetFileInput();

      };
  
      reader.readAsText(file); // Read the CSV file
    }
  }
  

  // Handle File Change
  onFileChange(event: any) {
    // alert();
    const file = event.target.files[0];
    const fileType = file.name.split(".").pop()?.toLowerCase();

    this.resetFileInput();

    if (!file) {
      this.toastr.error("No file selected");
      return;
    }

    const fileName = file.name.replace(/\.[^/.]+$/, "").trim();

    const fileExists = this.voucherList.some(
      (voucher: any) => voucher.file_name === fileName
    );
  
    if (fileExists) {
      this.toastr.error("A file with this name has already been uploaded.");
      return;
    }
    // else if (fileType === 'xlsx') {
    //   this.parseExcel(file);
    // }

    if (fileType === "csv") {
      this.parseCSV(file);
    }
    else {
      this.toastr.error(
        "Invalid file type! Please upload a .csv file."
      );
    }
  
  }

  allowedHeaders: string[] = ["product_name", "product_sku", "voucher_code","voucher_pin","denomination","validity"];
  // Parse CSV File
  parseCSV(file: File) {
    // alert();
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result as string;
      // let quantity = this.requestCouponVoucher.quantity;

      const rows = text
        .split("\n")
        .map((row) => row.trim())
        .filter((row) => row !== "");
      const data = rows.map((row) => row.split(","));

      if (this.validateHeaderLength(data[0])) {
        // this.tableHeaders = data[0];
        this.checkData = data.slice(1);
        if (this.validateData(this.checkData)) {
          this.tableHeaders = data[0]; // First row as headers
          this.previewData = data.slice(1); // Remaining rows as data
          this.filename = file.name.replace(/\.[^/.]+$/, ""); // Remaining rows as data
        }
        // Create payload based on the allowed headers
      } else {
        this.toastr.error(
          `Invalid column count in CSV file. The file must contain exactly ${this.allowedHeaders.length} columns.`
        );
      }

      this.resetFileInput();

      // const rows = text.split('\n').map((row) => row.split(','));
      // this.tableHeaders = data[0];

      // this.previewData = data.slice(1);
    };
    reader.readAsText(file);
  }

  validateHeaderLength(headers: string[]): boolean {
    // Check if the number of headers matches the allowedHeaders length
    return headers.length === this.allowedHeaders.length;
  }

  validateData(data: string[][]): boolean {
    const currentDate = new Date(); // Get current date
    const voucherCodes = new Set<string>(); // To track duplicate voucher codes
    let invalidRows = [];
    for (let i = 0; i < data.length; i++) {
      const row = data[i];

      // Check if any cell is empty
      const emptyCellCount = row.filter((cell, index) => {
        const header = this.allowedHeaders[index]?.trim();
        return header !== "voucher_pin" && cell.trim() === "";
      }).length;
    
      if (emptyCellCount > 0) {
        invalidRows.push(i + 1);
      }

      if (invalidRows.length > 0) {
        this.toastr.error(
          `Rows ${invalidRows.join(
            ", "
          )} contain empty data. Please ensure all fields are filled.`
        );
        return false;
      }

      // Validate the 'Validity' column format and date
      const validityIndex = this.allowedHeaders.indexOf("validity");
      if (validityIndex >= 0) {
        const validityDate = row[validityIndex]?.trim();
        const isValidFormat = this.isValidDateFormat(validityDate);
        if (!isValidFormat) {
          this.toastr.error(
            `Invalid date format in row ${i + 1}. Please use 'dd/mm/yyyy'.`
          );
          return false;
        }

        const dateParts = validityDate.split("/"); // Split the date into parts
        const parsedDate = new Date(
          +dateParts[2],
          +dateParts[1] - 1,
          +dateParts[0]
        ); // Convert to Date object

        if (parsedDate < currentDate) {
          this.toastr.error(
            `Validity date in row ${i + 1} is earlier than the current date.`
          );

          // alert(`Validity date in row ${i + 1} is earlier than the current date.`);
          return false;
        }
      }
      const voucherCodeIndex = this.allowedHeaders.indexOf("voucher_code");
      if (voucherCodeIndex >= 0) {
        const voucherCode = row[voucherCodeIndex]?.trim();
        if (voucherCodes.has(voucherCode)) {
          this.toastr.error(
            `Duplicate 'Voucher Code' found in row ${
              i + 1
            }. Each code must be unique.`
          );
          return false;
        }
        voucherCodes.add(voucherCode);
      }
    }
    return true;
  }

  // Helper function to validate the 'dd/mm/yyyy' format
  isValidDateFormat(date: string): boolean {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Regular expression for dd/mm/yyyy format
    if (!dateRegex.test(date)) {
      return false;
    }

    // Check if the parsed date is valid
    const dateParts = date.split("/");
    const day = +dateParts[0];
    const month = +dateParts[1];
    const year = +dateParts[2];

    // Months are 0-based in JavaScript's Date object
    const parsedDate = new Date(year, month - 1, day);
    return (
      parsedDate.getFullYear() === year &&
      parsedDate.getMonth() === month - 1 &&
      parsedDate.getDate() === day
    );
  }

  submitData() {
    if (this.previewData.length === 0) {
      alert("No data to submit!");
      return;
    }

    // Send data to API
    // const apiUrl = 'https://your-api-endpoint.com/upload';

    // const payload = this.previewData.map((row) =>
    //   this.tableHeaders.reduce((obj: { [key: string]: any }, key: string, index: number) => {
    //     obj[key] = row[index];
    //     return obj;
    //   }, {})
    // );
    // const quantity = this.requestCouponVoucher.quantity;
    // console.log(quantity);
    // return;

    // const voucher_request_id = this.approvalVoucherList[0].toString();
    // const voucherData = this.approvalData[0];
    // const status = voucherData.status;
    // const supplier_type = voucherData.supplier_type;
    // const product_url = voucherData.product_url;
    // const supplier_id = voucherData.supplier_id;
    // const user_name = this.userDetails.firstName;
    // const userId = this.userDetails.UserID;
    // const approved_by_name = this.userDetails.UserName;

    // console.log(voucherData,approved_by_name,userId,status,user_name,supplier_type,product_url,supplier_id,voucher_request_id);
    // return;

    const formattedPayload = {
      file_name: this.filename,
      data: this.previewData.map((row) =>
        this.tableHeaders.reduce(
          (obj: { [key: string]: any }, key: string, index: number) => {
            // Adjust key names if needed
            const normalizedKey = key.toLowerCase().replace(/\s+/g, "_"); // Normalize header keys (optional)
            obj[normalizedKey] = row[index];
            return obj;
          },
          {}
        )
      ),
    };

    console.log(formattedPayload);
    // return;

    //  const voucher_request_id  = this.manualVoucherRequestId;
    //  console.log(voucher_request_id);

    // return;
    // this["http"].post(apiUrl, payload).subscribe(
    //   (response: any) => {
    //     console.log('Data submitted successfully!', response);
    //   },
    //   (error: any) => {
    //     console.error('Error while submitting data:', error);
    //   }
    // );
    let token = localStorage.getItem("token");
    console.log(token);
    // return;
    this.headers = {
      headers: new HttpHeaders({
        //  'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
      }),
    };
    // this.loader.start();

    this._http
      .groupinventoryfileupload(formattedPayload, this.headers)
      .subscribe({
        next: (data: any) => {
          // this.loader.stop();
          if (data.status === 1) {
            this.toastr.success(data.msg || "Your file uploaded successfully");
            this.getVoucherRequestList();
          } else if (data.status === 0) {
            this.toastr.error(
              data.msg || "An error occurred while processing the file."
            );
            this.getVoucherRequestList();
          }
        },
        error: (error: any) => {
          // this.loader.stop();
          // Show a meaningful error message
          const errorMessage =
            error?.error?.msg ||
            error?.message ||
            "An unexpected error occurred.";
          this.toastr.error(errorMessage);
          console.error("Error details:", error);
          this.getVoucherRequestList();
          // location.reload();
          // this.closeBootstrapModal()
          // this.clear();
        },
      });
  }

  // closeBootstrapModal() {
  //   const modalElement = this.closeModal.nativeElement;

  //   if (modalElement) {
  //     const bootstrapModal = bootstrap.Modal.getInstance(modalElement); // Get Bootstrap modal instance
  //     if (bootstrapModal) {
  //       bootstrapModal.hide(); // Properly hide the modal using Bootstrap
  //     }
  //   }
  // }

  onStartDateChanged(event: IMyDateModel): void {
    this.startDate = event.formatted;
    this.endOptions = {
      dateFormat: "dd/mm/yyyy",
      disableUntil: {
        year: event.date.year,
        month: event.date.month,
        day: event.date.day,
      },
      enableDates: [
        { year: event.date.year, month: event.date.month, day: event.date.day },
      ],
    };
  }

  onEndDateChanged(event: IMyDateModel): void {
    this.endDate = event.formatted;
  }

  assignVoucher() {
    if (
      this.supplierType == "" ||
      this.supplierType == undefined ||
      this.supplierType == null ||
      this.supplierName == "" ||
      this.supplierName == undefined ||
      this.supplierName == null
    ) {
      this.toastr.error("Please select one aggregator");
      return;
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    const params = {
      voucherRequestIds: this.approvalVoucherList,
      status: this.approvalStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      supplier_id: this.supplierName,
      approved_by_name: this.userDetails.UserName,
    };

    this.loader.start();

    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            this.selectAll = false;
            if (this.approvalStatus == "A" || this.approvalStatus == "Y") {
              this.toastr.success("Voucher approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierName = "";
              this.supplierList = "";
              this.supplierForm.resetForm();
            } else if (this.rejectedStatus == "R") {
              this.toastr.error("Voucher rejected");
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierName = "";
              this.supplierList = "";
              this.supplierForm.resetForm();
            } else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierName = "";
              this.supplierList = "";
            }
          } else {
            if (data.status == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierName = "";
              this.supplierList = "";
              this.supplierForm.resetForm();
            }
          }
          this.closeBtn.nativeElement.click();
          this.approvalVoucherList = [];
          this.getVoucherRequestList();
          this.supplierName = "";
          this.supplierList = "";
        }
      },
      error: (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      },
    });
  }

  approveVoucher() {
    // if (this.supplierType == "" || this.supplierType == undefined || this.supplierType == null) {
    //   this.toastr.error("Please select one supplier");
    //   return;
    // }

    const params = {
      voucherRequestIds: this.approvalVoucherList,
      status: this.approvalStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      supplier_id: this.supplierName,
      approved_by_name: this.userDetails.UserName,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            this.selectAll = false;
            if (this.approvalStatus == "A" || this.approvalStatus == "Y") {
              this.toastr.success("Voucher approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            } else if (this.rejectedStatus == "R") {
              this.toastr.error("Voucher rejected");
              this.selectAll = false;
              this.showApproveReject = false;
            } else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            }
          } else {
            if (data.status == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            }
          }
          this.closeBtn.nativeElement.click();
          this.approvalVoucherList = [];
          this.getVoucherRequestList();
        }
      },
      error: (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      },
    });
  }

  approveVouchers(requestCouponVoucher: RequestInventory) {
    // if (this.supplierType == "" || this.supplierType == undefined || this.supplierType == null) {
    //   this.toastr.error("Please select one supplier");
    //   return;
    // }
    const params = {
      voucherRequestIds: this.approvalVoucherList,
      status: this.approvalStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      supplier_id: this.supplierName,
      approved_by_name: this.userDetails.UserName,
    };

    // console.log(params);
    // return;

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            if (this.approvalStatus == "A" || this.approvalStatus == "Y") {
              this.toastr.success("Voucher approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            } else if (this.rejectedStatus == "R") {
              this.toastr.error("Voucher rejected");
              this.selectAll = false;
              this.showApproveReject = false;
            } else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
              // this.supplierForm.resetForm();
            }
          } else {
            if (data.status == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              this.selectAll = false;
              this.showApproveReject = false;
              this.supplierForm.resetForm();
            }
          }
          this.closeBtn.nativeElement.click();
          this.approvalVoucherList = [];
          this.getVoucherRequestList();
        }
      },
      error: (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      },
    });
  }

  Rejectbrand(requestCouponVoucher: RequestInventory) {
    const params = {
      voucherRequestIds: this.approvalVoucherList,
      status: this.rejectedStatus,
      userId: this.userDetails.UserID,
      user_name: this.userDetails.Name,
      supplier_type: this.supplierType,
      approved_by_name: this.userDetails.UserName,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    this.loader.start();
    this._http.voucherApproval(params, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();

        this.closeBtn.nativeElement.click();
        this.selectAll = false;
        this.showApproveReject = false;
        if (data) {
          if (data.status == 1) {
            if (this.rejectedStatus == "R") {
              this.toastr.success("Voucher rejected successfully");
              this.selectAll = false;
              this.showApproveReject = false;
            } else {
              this.toastr.success("Vouchers approved successfully");
              this.selectAll = false;
              this.showApproveReject = false;
            }
          } else {
            if (data.statu == 2) {
              this.toastr.info(
                "Voucher has been already approved or generated"
              );
              this.selectAll = false;
              this.showApproveReject = false;
            }
          }
          this.approvalVoucherList = [];
          this.getVoucherRequestList();
        }
      },
      error: (error: any) => {
        this.loader.stop();
        this.toastr.error(error.Message);
      },
    });
  }

  approvalVoucherId(event: any, voucher: RequestInventory) {
    this.toolTipInit();
    this.selectAll = false;
    this.voucherStatus = voucher.status;
    if (event.target.checked == true) {
      this.approvalVoucherList.push(voucher.voucher_request_id);
    } else {
      if (this.approvalVoucherList.length > 0) {
        const index = this.approvalVoucherList.findIndex(
          (ch: any) => ch === voucher.voucher_request_id
        );
        this.approvalVoucherList.splice(index, 1);
        const listIndex = this.voucherList.findIndex(
          (ch: any) => ch.voucher_request_id === voucher.voucher_request_id
        );
        this.voucherList[listIndex].isSelected = false;
      }
    }
    if (this.approvalVoucherList.length > 0) {
      this.showApproveReject = true;
      this.showRemoveImport = false;
    } else {
      this.showRemoveImport = true;
      this.showApproveReject = false;
    }
  }

  selectAllFn() {
    this.toolTipInit();
    this.selectAllChecked = true;
    let voucher: any = this.voucherList;
    if (this.selectAll) {
      this.voucherList.forEach((c: any) => (c.isSelected = true));
      // console.log("test5",this.voucherList.filter((v: any) =>v.status == 'R' || v.status == 'Y'));
      this.voucherList
        .filter((v: any) => v.status == "R" || v.status == "Y")
        .forEach((c: any) =>
          this.approvalVoucherList.push(c.voucher_request_id)
        );
      this.selectAllChecked = true;
    } else {
      this.approvalVoucherList = [];
      this.voucherList.forEach((c: any) => (c.isSelected = false));
      // this.selectAll = false;
      // this.selectAllChecked = false;
    }
    if (this.approvalVoucherList.length > 0) {
      this.showApproveReject = true;
      this.showRemoveImport = false;
      this.toolTipInit();
    } else {
      this.showApproveReject = false;
      this.showRemoveImport = true;
    }
  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: "hover" });
      });
    }, 500);
  }

  companySync() {
    this.loader.start();
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.companySync(this.headers).subscribe((data: any) => {
      this.loader.stop();
      if (data.status == 1) {
        this.toastr.success("Company sync successfully");
      }
    });
  }

  brandSync() {
    this.loader.start();

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.brandSync(this.headers).subscribe((data: any) => {
      this.loader.stop();
      if (data.status == 1) {
        this.toastr.success("Brand sync successfully");
      }
    });
  }

  blockVoucher(voucher: RequestInventory, blockStatus: any) {
    this.loader.start();
    let requestParams = {
      voucher_request_id: voucher.voucher_request_id,
      status: blockStatus,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.blockVoucher(requestParams, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();
        if (data.status == 1 && blockStatus == 1) {
          this.toastr.success("Voucher blocked successfully");
          this.getVoucherRequestList();
        } else if (data.status == 1 && blockStatus == 0) {
          this.toastr.success("Voucher unblocked successfully");
          this.getVoucherRequestList();
        } else {
          this.toastr.error(data.msg);
        }
      },
      error: (_error) => {
        this.loader.stop();
      },
    });
  }

  showModa(rejectedStatus: string) {
    this.rejectedStatus = rejectedStatus;
    this.supplierType = "Internal";
    const element = document.getElementById("rejectModal") as HTMLElement;
    const rejectModal = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    rejectModal.show();
  }

  rejectshowModal(rejectedStatus: string, voucher: RequestInventory) {
    this.approvalVoucherList.push(voucher.voucher_request_id);
    this.rejectedStatus = rejectedStatus;
    this.supplierType = "Internal";
    const element = document.getElementById("rejectModal") as HTMLElement;
    const rejectModal = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    rejectModal.show();
  }

  approveshowModal(approvalStatus: string, brand: RequestInventory) {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.getsettingslist(this.headers).subscribe({
      next: (res: any) => {
        if (res) {
          let setvalue = res.data.order_limit_amount;

          localStorage.setItem("settingslist", setvalue);
        }
      },
    });

    this.settingvalue = localStorage.getItem("settingslist");

    this.requestCouponVoucher = brand;
    let sourcevalue = brand;

    let totalval = sourcevalue.total;

    let discountval = sourcevalue.voucher_approve_admin;

    let nullcount = 0;

    if (sourcevalue!.source == "" || sourcevalue!.source == undefined) {
      nullcount = nullcount + 1;
    }

    if (nullcount > 0) {
      this.toastr.error("Please select source before approval.");
      return;
    }

    if (discountval == "YES" && this.userDetails.role === "Admin") {
      this.toastr.error(
        "discount is more than 75% voucher approval by super admin"
      );
      return;
    } else if (
      totalval >= this.settingvalue &&
      this.userDetails.role === "Admin"
    ) {
      this.toastr.error(
        "voucher total amount is High. So, please get approval from super admin"
      );
      return;
    } else {
      if (
        sourcevalue.source === "QwikCilver" ||
        sourcevalue.source === "MEA" ||
        sourcevalue.source === "Ticket Express" ||
        sourcevalue.source === "Amazon" ||
        sourcevalue.source === "ValueDesign" ||
        sourcevalue.source === "FlipKart" ||
        sourcevalue.source === "EZPIN" ||
        sourcevalue.source === "Joyalukkas"
      ) {
        // this.approvalVoucherList = [brand.voucher_request_id];
        // console.log(this.approvalVoucherList);
        this.approvalStatus = approvalStatus;
        this.supplierType = "external";
        // this.approveVouchers(this.requestCouponVoucher);
        this.approvalVoucherList.push(brand.voucher_request_id);
        this.approvalData.push(brand);
        this.approvalStatus = approvalStatus;

        const element = document.getElementById("approveModals") as HTMLElement;
        const approveModals = new Modal(element, {
          backdrop: "static",
          keyboard: false,
        });
        this.urlError = false;
        this.productURL = "";
        approveModals.show();
      } else {
        this.approvalVoucherList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;
        this.supplierType = "internal";
        const element = document.getElementById(
          "approveModalss"
        ) as HTMLElement;
        const approveModalss = new Modal(element, {
          backdrop: "static",
          keyboard: false,
        });
        this.urlError = false;
        this.productURL = "";
        approveModalss.show();
      }
    }
  }

  manualUpload() {
     const element = document.getElementById("manualModals") as HTMLElement;
    const manualUpload = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    this.urlError = false;
    this.productURL = "";
    manualUpload.show();
    this.tableHeaders = [];
    this.previewData = [];
    this.resetFileInput();
  }

  showSupplierModal(approvalStatus: string) {
    let qccount = 0;
    let othercount = 0;
    let nullcount = 0;

    let disArray: any = [];

    this.approvalVoucherList.forEach((e: any) => {
      let selectrow = this.voucherList.find(
        (a: any) => a.voucher_request_id == e
      );

      let discountval: any = this.voucherList.find((a: any) => {
        // console.log(a, a.voucher_approve_admin, a.voucher_request_id)
        return a.voucher_approve_admin == "YES" && a.voucher_request_id == e;
      });

      if (discountval) {
        disArray.push(discountval);
      }

      if (selectrow!.source == "" || selectrow!.source == undefined) {
        nullcount = nullcount + 1;
      }

      if (
        selectrow!.source == "QwikCilver" ||
        selectrow!.source == "MEA" ||
        selectrow!.source == "Ticket Express" ||
        selectrow!.source == "Amazon" ||
        selectrow!.source == "ValueDesign" ||
        selectrow!.source == "FlipKart" ||
        selectrow!.source == "Joyalukkas"
      ) {
        qccount = qccount + 1;
      } else {
        othercount = othercount + 1;
      }
    });

    if (nullcount > 0) {
      this.toastr.error("Please select source before approval.");
      return;
    }

    if (qccount > 0 && othercount > 0) {
      this.toastr.error("please select unique source rows");
      return;
    }

    let discountvalue: any = disArray.find((a: any) => {
      return a.voucher_approve_admin;
    });

    let disvalue: string = "";
    if (discountvalue) {
      disvalue = discountvalue.voucher_approve_admin;
    }

    if (disvalue === "YES" && this.userDetails.role === "Admin") {
      this.toastr.error(
        "discount is more than 75% voucher approval by super admin"
      );
      return;
    } else {
      if (qccount > 0) {
        // this.approvalbrandList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;
        this.supplierType = "external";
        const element = document.getElementById("approveModals") as HTMLElement;
        const approveModals = new Modal(element, {
          backdrop: "static",
          keyboard: false,
        });
        this.urlError = false;
        this.productURL = "";
        approveModals.show();
        // this.approveVouchers(this.requestCouponVoucher);
      } else {
        // this.approvalbrandList.push(brand.voucher_request_id);
        this.approvalStatus = approvalStatus;
        this.supplierType = "internal";
        const element = document.getElementById(
          "approveModalss"
        ) as HTMLElement;
        const approveModalss = new Modal(element, {
          backdrop: "static",
          keyboard: false,
        });
        this.urlError = false;
        this.productURL = "";
        approveModalss.show();
      }
    }
  }

  onChangeSupplier(item: any) {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.getSupplierItems(item, this.headers).subscribe((data: any) => {
      this.supplierList = data.data;
    });
  }

  selectSupplierEvent(item: any) {
    this.supplierID = item.supplier_id;
    this.supplierName = item.supplier_name;
  }

  addNewSupplier() {
    this.newSupplier = true;
    this.supplierDetail = new Supplier();
  }

  addSupplier() {
    if (
      this.supplierDetail.supplier_name === "" ||
      this.supplierDetail.api_url === "" ||
      this.supplierDetail.api_key === "" ||
      this.supplierDetail.api_authentication_code === ""
    ) {
      this.toastr.error("Please fill all the mandatory fields");
      return;
    }
    const urlRegex =
      /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
    const result = this.supplierDetail.api_url.match(urlRegex);
    if (result == null) {
      this.toastr.error("Please enter valid API URL");
      return;
    }
    this.loader.start();
    this.supplierDetail.action = "CREATE";
    this.supplierDetail.created_by_name = this.userDetails.UserName;
    this.supplierDetail.created_by_id = this.userDetails.UserID;

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.addNewSupplier(this.supplierDetail, this.headers).subscribe({
      next: (data: any) => {
        this.loader.stop();
        if (data.status == 1) {
          this.toastr.success("Supplier request send successfully");
          this.newSupplier = false;
        } else if (data.status === 0) {
          this.toastr.error(data.msg);
        }
      },
      error: (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      },
    });
  }

  backToSupplier() {
    this.newSupplier = false;
  }

  changeSupplier() {
    if (this.supplierType == "Internal") {
      this.newSupplier = false;
    } else {
      this.newSupplier = false;
    }
  }

  ngOnDestroy() {
    if (this.tooltipList) {
      this.tooltipList.forEach((tooltip: { dispose: () => void }) => {
        tooltip.dispose();
      });
    }
    localStorage.removeItem("pagesNo");
  }

  // downloadPdf(list: RequestInventory) {

  //   let doc = new jsPDF('p', 'pt', 'a4');

  //   let specialElementHandlers = {
  //     '#editor': function(element:any, renderer:any) {
  //       return true;
  //     }
  //   };

  //   let content = this.pdflist.nativeElement;

  //   doc.html(content.innerHTML, {
  //     'width': 190
  //   });

  //   doc.save('test.pdf');
  // }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  formatDate(responseDate: string) {
    if (responseDate) {
      const date = new Date(responseDate);
      const formattedDate = date
        .toLocaleString("en-GB", { month: "short", year: "numeric" })
        .replace(",", "");
      return `${formattedDate} - `;
    } else {
      return "";
    }
  }

  formatGift1to1(shop: string, domain: string) {
    let regEx = /voucherkart/gi;
    if (
      shop &&
      shop.toLowerCase() === "childshop" &&
      domain &&
      regEx.test(domain)
    ) {
      return domain.replace(/voucherkart/gi, "Gift1to1");
    } else {
      return domain;
    }
  }
}
