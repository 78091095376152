<h6 class="font-18 font-weight-400 mt-4 mb-3 text-left product-heading title">
  Internal Product {{ Edit ? "Update" : "Creation" }}
</h6>

<div class="card">
  <div class="card-body">
    <form
      #productEditForm="ngForm"
      name="form"
      (ngSubmit)="AddProduct(requestproductedit)">
      <div class="row flex-wrap">
        <div class="col-4">
          <div class="md-form form-sm my-3">
            <label for="varient" class="mb-2"
              >Voucher Type
              <span class="text-danger">*</span>
            </label>

            <select
              id="varient"
              class="form-select"
              placeholder="Enter Voucher Type"
              [(ngModel)]="requestproductedit.varient"
              name="varient"
              #varient="ngModel"
              (change)="selectVarientEvent(requestproductedit.varient)"
              required>
              <option value="" selected disabled hidden>
                Select Voucher Type
              </option>
              <option value="Digital">Digital</option>
              <option value="Product">Product Voucher</option>
              <option value="Deal">Deal Voucher</option>
            </select>
            <span
              class="text-danger"
              *ngIf="
                            (varient.touched || productEditForm.submitted) &&
                            varient.errors?.['required']
                          ">
              voucher type is required
            </span>
          </div>
        </div>

        <div class="col-4">
          <div class="md-form form-sm my-3">
            <label for="usage_mode" class="mb-2"
              >Usage Mode
              <span class="text-danger">*</span>
            </label>

            <select
              id="usage_mode"
              class="form-select"
              placeholder="Enter Usagemode"
              [(ngModel)]="requestproductedit.usage_mode"
              name="usage_mode"
              #usage_mode="ngModel"
              required>
              <option value="" selected disabled hidden>
                Select Usagemode
              </option>
              <option value="Online">Online</option>
              <option value="Offline">In-Store</option>
              <option value="Omni Channel">Online + In-Store</option>
            </select>
            <span
              class="text-danger"
              *ngIf="
                            (usage_mode.touched || productEditForm.submitted) &&
                            usage_mode.errors?.['required']
                          ">
              usage mode is required
            </span>
          </div>
        </div>

        <div class="col-4">
          <div class="md-form form-sm my-3">
            <label for="brand" class="mb-2"
              >Brand
              <span class="text-danger">*</span>
            </label>

            <select
              id="brand"
              class="form-select"
              placeholder="Enter Brand"
              [(ngModel)]="requestproductedit.brand"
              name="brand"
              #brand="ngModel"
              (change)="selectbrandEvent(requestproductedit.brand)"
              required>
              <option value="" selected disabled hidden>Select Brand</option>
              <option *ngFor="let option of brandData" [value]="option.id">
                {{ option.name }}
              </option>
            </select>
            <span
              class="text-danger"
              *ngIf="
                            (brand.touched || productEditForm.submitted) &&
                            brand.errors?.['required']
                          ">
              brand is required
            </span>
          </div>
        </div>

        <div class="col-4 no-icon" *ngIf="!productDPDwon">
          <div class="md-form form-sm my-3">
            <label for="product_name" class="mb-2"
              >Product Name
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="requestproductedit.product_name"
              placeholder="Enter Product Name"
              name="product_name"
              (keypress)="onKeyPresscomma($event)"
              #product_name="ngModel"
              mdbInput
              autocomplete="off"
              required
              (input)="restrictDecimals($event)" />
            <span
              class="text-danger"
              *ngIf="(!productDPDwon) && (product_name.touched || productEditForm.submitted) && product_name.errors?.['required']">
              product name is required
            </span>
          </div>
        </div>
        
        <div class="col-4 no-icon" *ngIf="productDPDwon">
          <div class="md-form form-sm my-3">
            <label for="product_name" class="mb-2"
              >Product Name
              <span class="text-danger">*</span>
            </label>
            <!-- <select
            id="product"
            class="form-select"
            placeholder="Enter Brand"
            [(ngModel)]="requestproductedit.product_name"
            name="product"
            #product="ngModel"
            (change)="selectProduct_Name(requestproductedit.product_name)"
            required>
            <option value="" selected disabled hidden>Select Brand</option>
            <option *ngFor="let optionVal of brandData_name" [value]="optionVal.name">
              {{ optionVal.name }}
            </option>
          </select> -->
            <ng-autocomplete  [data]="brandData_name" [searchKeyword]="productKeyword" placeholder="Select Client"
            [itemTemplate]="itemTemplate"  (keydown)="restrictEnter($event)"
            [(ngModel)]="requestproductedit.product_name" name="product" mdbInput autocomplete="on" (inputCleared)='productClear($event)'
             (selected)='selectProduct_Name($event)' #product="ngModel" required
            [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
        </ng-template>

       
            <!-- <span
              class="text-danger"
              *ngIf="(productDPDwon) && (productEditForm.submitted) && product_name.errors?.['required'] ">
              product name is required
            </span> -->
          </div>
        </div>

        <div class="col-4 no-icon" *ngIf="facehide">
          <div class="md-form form-sm my-3">
            <label for="face_value" class="mb-2"
              >Face Value
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              (input)="restrictDecimals($event)"
              (keypress)="
                onKeyPressspecialcharacters($event);
                onKeyPresscomma($event);
                numberOnly($event);
                restrictAlphabets($event)
              "
              (keydown)="keyDownHandler($event)"
              [(ngModel)]="requestproductedit.face_value"
              placeholder="Enter Face Value"
              name="face_value"
              #face_value="ngModel"
              mdbInput
              autocomplete="off"
              restrictChars
              [disabled]="DisabledFace"
              required />

            <span
              class="text-danger"
              *ngIf="
                            (face_value.touched || productEditForm.submitted) &&
                            face_value.errors?.['required']
                          ">
              facevalue is required
            </span>
          </div>
        </div>

        <div class="col-4 no-icon" *ngIf="faceshow">
          <div class="md-form form-sm my-3">
            <label for="minface_value" class="mb-2">
              Min Face Value
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              (input)="restrictDecimals($event)"
              (keypress)="
                onKeyPressspecialcharacters($event);
                onKeyPresscomma($event);
                numberOnly($event);
                restrictAlphabets($event)
              "
              (keydown)="keyDownHandler($event)"
              [(ngModel)]="requestproductedit.minface_value"
              placeholder="Enter Min Face Value"
              name="minface_value"
              #minface_value="ngModel"
              mdbInput
              autocomplete="off"
              restrictChars
              [disabled]="Disabledvalue"
              required />

            <span
              class="text-danger"
              *ngIf="
                            (minface_value.touched || productEditForm.submitted) &&
                            minface_value.errors?.['required']
                          ">
              min facevalue is required
            </span>
          </div>
        </div>

        <div class="col-4 no-icon" *ngIf="faceshow">
          <div class="md-form form-sm my-3">
            <label for="maxface_value" class="mb-2">
              Max Face Value
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              (input)="restrictDecimals($event)"
              (keypress)="
                onKeyPressspecialcharacters($event);
                onKeyPresscomma($event);
                numberOnly($event);
                restrictAlphabets($event)
              "
              (keydown)="keyDownHandler($event)"
              [(ngModel)]="requestproductedit.maxface_value"
              placeholder="Enter Max Face Value"
              name="maxface_value"
              #maxface_value="ngModel"
              mdbInput
              autocomplete="off"
              restrictChars
              [disabled]="Disabledvalue"
              required />

            <span
              class="text-danger"
              *ngIf="
                            (maxface_value.touched || productEditForm.submitted) &&
                            maxface_value.errors?.['required']
                          ">
              max facevalue is required
            </span>
          </div>
        </div>

        <div class="col-4 no-icon">
          <div class="md-form form-sm my-3">
            <label class="mb-2">
              Discount
              <span class="text-danger">*</span>
              <span style="color: blue; font-weight: 600" class="minmax"
                >( % )</span
              >
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Discount"
              (keypress)="allowOnlyNumbers($event)"
              (keydown)="keyDownHandler($event)"
              [(ngModel)]="requestproductedit.discount"
              name="discount"
              #discount="ngModel"
              mdbInput
              autocomplete="off"
              restrictChars
              required />
            <span
              class="text-danger"
              *ngIf="
                            (discount.touched || productEditForm.submitted) &&
                            discount.errors?.['required']
                          ">
              discount is required
            </span>
          </div>
        </div>
        <!-- 
                <div class="col-4">
                    <div class="md-form form-sm my-3 position-relative">

                        <label for="validity" class="mb-2">Validity
                             <span class="text-danger">*</span>
                            </label>

                        <input (click)="di.toggleCalendar()" [(ngModel)]="getValidityDate" name="issuance"
                            placeholder="Select A Date" readonly class="form-control datepicker" ngx-mydatepicker
                            [options]="validityOptions" #di="ngx-mydatepicker" type="text" #productEditForm="ngModel"
                            (dateChanged)="onValidityChanged($event)" (keypress)="$event.preventDefault()" required>
                        <span class="position-absolute" style="top: 36px; right: 0">
                            <a href="javascript:void(0)" (focus)="di.toggleCalendar()" (click)="di.openCalendar()"><img
                                    src="../../../assets/images/icons/calendar.svg" width="32"></a>
                        </span>
                        <span class="text-danger" *ngIf="(productEditForm.submitted) && ValidityDate.errors?.['required']">
                            validity is required
                        </span>
                    </div>
                </div> -->

        <div class="col-4 no-icon">
          <div class="md-form form-sm my-3">
            <label for="validity" class="mb-2"
              >Validity
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              min="1"
              [attr.maxlength]="3"
              placeholder="60 Days"
              [(ngModel)]="requestproductedit.validity"
              name="validity"
              #validity="ngModel"
              (keypress)="
                onKeyPresscomma($event);
                numberOnly($event);
                restrictAlphabets($event)
              "
              (input)="restrictDecimals($event)"
              mdbInput
              autocomplete="off"
              required />
            <span
              class="text-danger"
              *ngIf="
                            (validity.touched || productEditForm.submitted) &&
                            validity.errors?.['required']
                          ">
              validity is required
            </span>
          </div>
        </div>

        <div class="col-4 no-icon">
          <div class="md-form form-sm my-3">
            <label for="processing_fee" class="mb-2">
              Processing Fee
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Processing Fee"
              (input)="restrictDecimals($event)"
              (keypress)="
                onKeyPressspecialcharacters($event);
                onKeyPresscomma($event);
                numberOnly($event);
                restrictAlphabets($event)
              "
              (keydown)="keyDownHandler($event)"
              [(ngModel)]="requestproductedit.processing_fee"
              name="processing_fee"
              #processing_fee="ngModel"
              mdbInput
              autocomplete="off"
              restrictChars
              required />

            <span
              class="text-danger"
              *ngIf="
                            (processing_fee.touched || productEditForm.submitted) &&
                            processing_fee.errors?.['required']
                          ">
              processing fee is required
            </span>
          </div>
        </div>

        <div class="col-4 no-icon">
          <div class="md-form form-sm my-3">
            <label for="selectdealvalue" class="mb-2">
              Issuance Fee
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Issuance Fee"
              (input)="restrictDecimals($event)"
              (keypress)="
                onKeyPressspecialcharacters($event);
                onKeyPresscomma($event);
                numberOnly($event);
                restrictAlphabets($event)
              "
              (keydown)="keyDownHandler($event)"
              [(ngModel)]="requestproductedit.issuance_fee"
              name="issuance_fee"
              #issuance_fee="ngModel"
              mdbInput
              autocomplete="off"
              restrictChars
              required />

            <span
              class="text-danger"
              *ngIf="
                            (issuance_fee.touched || productEditForm.submitted) &&
                            issuance_fee.errors?.['required']
                          ">
              issuance fee is required
            </span>
          </div>
        </div>

        <div class="col-4 no-icon">
          <div class="md-form form-sm my-3">
            <label for="authentication_charge" class="mb-2">
              Authentication Charges
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Authentication Charges"
              (input)="restrictDecimals($event)"
              (keypress)="
                onKeyPressspecialcharacters($event);
                onKeyPresscomma($event);
                numberOnly($event);
                restrictAlphabets($event)
              "
              (keydown)="keyDownHandler($event)"
              [(ngModel)]="requestproductedit.authentication_charge"
              name="authentication_charge"
              #authentication_charge="ngModel"
              mdbInput
              autocomplete="off"
              restrictChars
              required />

            <span
              class="text-danger"
              *ngIf="
                            (authentication_charge.touched || productEditForm.submitted) &&
                            authentication_charge.errors?.['required']
                          ">
              authentication charge is required
            </span>
          </div>
        </div>

        <!-- 
                <div class="col-4 no-icon">
                    <div class="md-form form-sm my-3">
                        <label for="countryList" class="mb-2">Validity
                            <span class="text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" min="1" [attr.maxlength]="3" placeholder="60 Days" (input)="restrictDecimals($event)"
                            [(ngModel)]="requestproductedit.validity" name="validity" #validity="ngModel"
                            (keypress)="numberOnly($event);restrictAlphabets($event)" 
                            mdbInput autocomplete="off"
                            required>

                       
                    </div>
                </div>            -->

        <div class="col-4 no-icon">
          <div class="md-form form-sm my-3">
            <label for="sku" class="mb-2">
              SKU
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter SKU"
              class="form-control"
              [attr.maxlength]="64"
              [(ngModel)]="requestproductedit.sku"
              name="sku"
              #sku="ngModel"
              mdbInput
              autocomplete="off"
              (keypress)="onKeyPresscomma($event)"
              (input)="restrictDecimals($event)"
              required />

            <span
              class="text-danger"
              *ngIf="
                            (sku.touched || productEditForm.submitted) &&
                            sku.errors?.['required']
                          ">
              sku is required
            </span>
          </div>
        </div>

        <div class="col-4 no-icon">
          <div class="md-form form-sm my-3">
            <label for="description" class="mb-2"
              >Description :
              <span class="text-danger">*</span>
            </label>
             <textarea
              type="text"
              [(ngModel)]="requestproductedit.description"
              class="form-control"
              placeholder="Enter Description"
              mdbInput
              autocomplete="off"
              name="description"
              (keypress)="onKeyPresscomma($event)"
              #description="ngModel"
              required>
            </textarea>

            <span
              class="text-danger"
              *ngIf="
                            (description.touched || productEditForm.submitted) &&
                            description.errors?.['required']
                          ">
              description is required
            </span>
          </div>
        </div>

        <div class="col-4 no-icon">
          <div class="md-form form-sm my-3">
            <label for="terms_condition" class="mb-2"
              >Terms & Conditions :
              <span class="text-danger">*</span>
            </label>
             <textarea
              type="text"
              [(ngModel)]="requestproductedit.terms_condition"
              class="form-control"
              placeholder="Enter Terms&condition"
              mdbInput
              autocomplete="off"
              name="terms_condition"
              #terms_condition="ngModel"
              (keypress)="onKeyPresscomma($event)"
              required>
            </textarea>

            <span
              class="text-danger"
              *ngIf="
                            (terms_condition.touched || productEditForm.submitted) &&
                            terms_condition.errors?.['required']
                          ">
              terms & condition is required
            </span>
          </div>
        </div>

        <div class="col-2">
          <div class="md-form form-sm my-3 css">
            <label
              class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10"
              for="inputFile">
              <input
                type="file"
                accept="image/*"
                class="form-control d-none"
                id="inputFile"
                #inputFile
                (change)="selectfile($event)"
                [(ngModel)]="requestproductedit.file" />
              <i class="fa fa-upload"></i> Upload Image
            </label>
          </div>
        </div>

        <div class="col-1 prealig">
          <label *ngIf="preshow">Preview Image :</label>
          <br />
          <img [src]="this.previews" class="preview" />

          <img
            *ngIf="Edit && !this.previews"
            class="preview"
            [src]="this.pathurl + this.imagevalue" />
        </div>
        <div class="col-9 ps-5">
          <div class="md-form form-sm my-3 css" *ngIf="!Edit">
            <div clas="md-form form-sm my-3 css ">
              <input
                type="checkbox"
                id="isCheck"
                class="filled-in form-check-input"
                [(ngModel)]="isCheck" name="ischeck"
                (change)="onCheckboxChange($event)" />&nbsp;
              <label for="isCheck">Display In B2C </label>
            </div>
          </div>

          <div class="md-form form-sm my-3 css" *ngIf="Edit">
            <div clas="md-form form-sm my-3 css ">
              <input
                type="checkbox"
                id="iseditCheck"
                [checked]="flagHide"
                class="filled-in form-check-input"
                [(ngModel)]="iseditCheck" name="ideditcheck"
                (change)="onCheckboxChange($event)" />&nbsp;
              <label for="iseditCheck">Display In B2C </label>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="b2cflag =='Y'"> -->
        <div class="col-4 no-icon" *ngIf="isCheck">
          <div class="md-form form-sm my-3">
            <label class="mb-2"
              >Reward Points
              <span class="text-danger">*</span>
              <span style="color: blue; font-weight: 600" class="minmax"
                >( % )</span
              >
            </label>
             <input
              type="text"
              [(ngModel)]="requestproductedit.reward"
              class="form-control"
              placeholder="Enter Reward Points"
              mdbInput
              autocomplete="off"
              name="rewardpoint"
              #rewardpoint="ngModel"
              (keypress)="allowOnlyNumbers($event)"
              required />

            <span
              class="text-danger"
              *ngIf="(rewardpoint.touched || productEditForm.submitted) && rewardpoint.errors?.['required']">
              Reward Points is required
            </span>
          </div>
        </div>
        <div class="col-4 no-icon" *ngIf="isCheck">
          <div class="md-form form-sm my-3">
            <label class="mb-2"
              >How to use
              <!-- <span class="text-danger">*</span>    -->
            </label>
             <input
              type="text"
              [(ngModel)]="requestproductedit.howtouse"
              class="form-control"
              placeholder="Enter How to use"
              mdbInput
              autocomplete="off"
              name="how"
              #how="ngModel"
              (keypress)="onKeyPresscomma($event)"
              (input)="restrictDecimals($event)"
              required />

            <!-- <span class="text-danger" *ngIf="(how.touched || productEditForm.submitted) && how.errors?.['required']">
                            How to use is required
                        </span> -->
          </div>
        </div>
        <div class="col-4 no-icon" *ngIf="isCheck">
          <div class="md-form form-sm my-3">
            <label class="mb-2"
              >Store Locator
              <span class="text-danger">*</span>
            </label>
             <input
              type="text"
              [(ngModel)]="requestproductedit.storelocator"
              class="form-control"
              placeholder="Enter Store Locator"
              mdbInput
              autocomplete="off"
              name="store"
              #store="ngModel"
              (keypress)="onKeyPresscomma($event)"
              required />

            <span
              class="text-danger"
              *ngIf="(store.touched || productEditForm.submitted) && store.errors?.['required']">
              StoreLocator is required
            </span>
          </div>
        </div>
        <!-- <div class="col-4 no-icon"  *ngIf="isCheck">
                    <div class="md-form form-sm my-3">
                        <label class="mb-2">Important Instruction 
                        </label>
                        <div class="card-body pb-4">
                            <angular-editor [(ngModel)]="requestproductedit.instrction" [config]="config"  mdbInput autocomplete="off" name="instrction" #instrction="ngModel" required></angular-editor>
                        </div>
                        <span class="text-danger" *ngIf="(instrction.touched || productEditForm.submitted) && instrction.errors?.['required']">
                            Important Instruction  is required
                        </span>

                    </div>
                </div> -->

        <div class="col-4 no-icon" *ngIf="isCheck">
          <div class="md-form form-sm my-3">
            <label class="mb-2"
              >Price
              <span class="text-danger">*</span>
            </label>
             <input
              type="text"
              [(ngModel)]="requestproductedit.price"
              class="form-control"
              placeholder="Enter Price"
              mdbInput
              autocomplete="off"
              name="priceList"
              #priceList="ngModel"
              (keypress)="allowOnlyNumbers($event)"
              required />

            <span
              class="text-danger"
              *ngIf="(priceList.touched || productEditForm.submitted) && priceList.errors?.['required']">
              Price is required
            </span>
          </div>
        </div>
        <div class="col-4 no-icon" *ngIf="isCheck">
          <div class="md-form form-sm my-3">
            <label for="instrction" class="mb-2"
              >Important Instruction :
              <span class="text-danger">*</span>
            </label>
             <textarea
              type="text"
              [(ngModel)]="requestproductedit.instrction"
              class="form-control"
              placeholder="Enter instruction"
              mdbInput
              autocomplete="off"
              name="instrction"
              #instrction="ngModel"
              (keypress)="onKeyPresscomma($event)"
              required>
            </textarea>

            <span
              class="text-danger"
              *ngIf="
                            (instrction.touched || productEditForm.submitted) &&
                            instrction.errors?.['required']
                          ">
              Important Instruction is required
            </span>
          </div>
        </div>
        <!-- </div>  -->

        <!-- <div class="col-4">
                    <div>
                        <header>
                          <h1>AngularEditor</h1>
                          
                        </header>
                          <angular-editor [(ngModel)]="requestproductedit.htmlContent" config ="config"></angular-editor>
                        <h5 class="html-header">HTML Output</h5>
                        <div class="html">
                          {{ htmlContent }}
                        </div>
                 
                      </div>
                </div> -->

        <div class="col-4 no-icon" *ngIf="flagHide && !isCheck">
          <div class="md-form form-sm my-3">
            <label class="mb-2"
              >Reward Points
              <span class="text-danger">*</span>
              <span style="color: blue; font-weight: 600" class="minmax"
                >( % )</span
              >
            </label>
             <input
              type="text"
              [(ngModel)]="requestproductedit.reward"
              class="form-control"
              placeholder="Enter Reward Points"
              mdbInput
              autocomplete="off"
              name="rewardpoint"
              #rewardpoint="ngModel"
              (keypress)="allowOnlyNumbers($event)"
              required />

            <span
              class="text-danger"
              *ngIf="(rewardpoint.touched || productEditForm.submitted) && rewardpoint.errors?.['required']">
              Reward Points is required
            </span>
          </div>
        </div>
        <div class="col-4 no-icon" *ngIf="flagHide && !isCheck">
          <div class="md-form form-sm my-3">
            <label class="mb-2"
              >How to use
              <!-- <span class="text-danger">*</span>    -->
            </label>
             <input
              type="text"
              [(ngModel)]="requestproductedit.howtouse"
              class="form-control"
              placeholder="Enter How to use"
              mdbInput
              autocomplete="off"
              name="how"
              #how="ngModel"
              (keypress)="onKeyPresscomma($event)"
              (input)="restrictDecimals($event)"
              required />

            <!-- <span class="text-danger" *ngIf="(how.touched || productEditForm.submitted) && how.errors?.['required']">
                            How to use is required
                        </span> -->
          </div>
        </div>
        <div class="col-4 no-icon" *ngIf="flagHide && !isCheck">
          <div class="md-form form-sm my-3">
            <label class="mb-2"
              >Store Locator
              <span class="text-danger">*</span>
            </label>
             <input
              type="text"
              [(ngModel)]="requestproductedit.storelocator"
              class="form-control"
              placeholder="Enter Store Locator"
              mdbInput
              autocomplete="off"
              name="store"
              #store="ngModel"
              (keypress)="onKeyPresscomma($event)"
              required />

            <span
              class="text-danger"
              *ngIf="(store.touched || productEditForm.submitted) && store.errors?.['required']">
              StoreLocator is required
            </span>
          </div>
        </div>

        <div class="col-4 no-icon" *ngIf="flagHide && !isCheck">
          <div class="md-form form-sm my-3">
            <label class="mb-2"
              >Price
              <span class="text-danger">*</span>
            </label>
             <input
              type="text"
              [(ngModel)]="requestproductedit.price"
              class="form-control"
              placeholder="Enter Price"
              mdbInput
              autocomplete="off"
              name="priceList"
              #priceList="ngModel"
              (keypress)="allowOnlyNumbers($event)"
              required />

            <span
              class="text-danger"
              *ngIf="(priceList.touched || productEditForm.submitted) && priceList.errors?.['required']">
              Price is required
            </span>
          </div>
        </div>
        <div class="col-4 no-icon" *ngIf="flagHide && !isCheck">
          <div class="md-form form-sm my-3">
            <label for="instrction" class="mb-2"
              >Important Instruction :
              <span class="text-danger">*</span>
            </label>
             <textarea
              type="text"
              [(ngModel)]="requestproductedit.instrction"
              class="form-control"
              placeholder="Enter instruction"
              mdbInput
              autocomplete="off"
              name="instrction"
              #instrction="ngModel"
              (keypress)="onKeyPresscomma($event)"
              required>
            </textarea>

            <span
              class="text-danger"
              *ngIf="
                            (instrction.touched || productEditForm.submitted) &&
                            instrction.errors?.['required']
                          ">
              Important Instruction is required
            </span>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-end justify-content-end backdiv">
        <button
          class="btn btn-secondary mr-2"
          type="button"
          (click)="backclear()">
          Cancel
        </button>
        <button class="btn btn-success mr-2" type="submit">
          {{ Edit ? "Update" : "Save" }}
        </button>
      </div>
    </form>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
