export class RequestInventory {
    voucher_request_id: any;
    product_name: any;
    product_sku: any;
    id: any;
    brand_name: any = "";
    type: any = "ID";
    category: any = "Digital";
    denomination: any;
    validity: any = "";
    quantity: any = "";
    voucher_count: any = "";
    allocated: any = "";
    not_allocated: any = "";
    create_date: any = "";
    payment_status: string = "";
    created_by: string = "";
    createdOn: string = "";
    modified_by: any = "";
    modifieddate: any;
    status: string = "";
    action: string = "";
    user_name: string = "";
    points: any = "";
    isSelected: boolean = false;
    modiedfy_by_name: string = "";
    created_by_name: string = "";
    brandId: any;
    supplier_type: any;
    start_date: any;
    issuance_date: any;
    block_status: any;
    voucher_type: string = "voucher";
    issuance_fee: any;
    total: any;
    discount: any;
    payment_received: string = "No";
    kart_type: any = "";
    otp: any;
    authentication_type: any = "";
    voucher_redemption_type: any = "";
    voucher_redemption_url: any = "";
    log: any;
    product_id: any;
    card_number: any;
    card_pin: any;
  
    source: string = "";
    source_id: any = "";
    company_name: string = "";
    file_name: string = "";
    companyId: any;
    modal: string = "";
    product: any;
    usagemode: any;
    minPrice: any;
    maxPrice: any;
    usage_mode: any;
    totalfacevalue: any;
    maxdiscount: any;
    voucher_approve_admin: any;
    brand_total: any;
    product_discount: any;
    created_by_role: any;
    minface_value: any;
    maxface_value: any;
    internal_product_discount: any;
    internal_face_value: any;
    internal_usage_mode: any;
    order_recevied_from: any;
    LOG: any;
    voucher_delivery_email: any;
    voucher_delivery_type: string = "";
  voucher_option: string = "";
    
    currency_symbol: any = "";
    currency: any= "AED";
    product_fee: any= "";
    currency_code: any="";
    campaign_name?: string = "";
    points_allocated_on?: string = "";
    // newBrand?:string = "";
  }
  