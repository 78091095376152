import { Component, ViewChild } from "@angular/core";
import { AppService } from "./../restful-services/restful.service";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { RequestProduct } from "../models/Requestproduct";
import { Modal } from "bootstrap";
import { Subscription } from "rxjs";
import {
  apiurl,
  environment,
  environmentfiles,
} from "src/environments/environment";
import { filesapiurl } from "src/environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { AngularEditorConfig } from "@kolkov/angular-editor";
declare var bootstrap: any;

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent {
  @ViewChild("inputFile2") inputFile2: any;
  selectinternal: string = "Internal";
  selectexternal: string = "External";
  Internal: any;
  selectedValue: any;
  public supplierName: string = "";
  public status: string = "";
  limit: number = 25;
  supplierType: any;
  skip: number = 0;
  p: number = 1;
  search: any;
  test: any;
  count: number = 0;
  lastvalue: number = 0;
  preshow: boolean = false;
  file: any;

  role: any;
  public external = "external";
  userDetails: any;

  backendValue: any;
  private headers: any = {};
  public brandtype: string = "";
  apiLoading: boolean = false;

  public company: string = "";
  public brand: string = "";
  source: any = "";
  sourceParam: any = "";
  brand_name: any = "";
  category: any = "";
  category_name: any = "";
  sourcevalue: any;

  storelocator: any = "";
  instrction: any = "";
  howtouse: any = "";
  terms: any = "";
  reward: any;
  price: any;
  usage_mode: any = "";

  productList: RequestProduct[];
  sourceData: any = [];
  brandData: any = [];
  categoryData: any = [];
  // selectvoucherkart : string = 'Voucherkart';
  // selectrewardkart : string = 'Rewardkart';

  selectvoucherkart: string = "";
  selectrewardkart: string = "";

  fieldDisabled: boolean = true;
  external_id: any;

  selectAll: boolean = false;
  selectAlls: boolean = false;
  selectAllChecked: boolean = false;
  catid: any;

  tooltipList: any;
  product: any;

  roletype: any;
  displayhide: boolean = true;
  digitArray: any;

  user_type: any;
  fileurls: any;
  @ViewChild("inputFile") inputFile: any;
  @ViewChild("inputSKUFile") inputSKUFile: any;
  thumbnail: any;
  currentBody: any;
  values: any;
  pathurl: any;
  previews: any;
  sku: any;
  updatedData: any;
  idvalue: any;
  Edit: boolean;
  nameList: any;
  discount: any;
  userName: boolean = false;
  usageMode: boolean = false;
  descriptionList: any;
  b2cname: any;
  inputStr: any = [];
editedSku: any;
  editedSkuId: any;

  constructor(
    private sanitizer: DomSanitizer,
    private _http: AppService,
    private router: Router,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService
  ) {
    this.Edit = false;
    this.productList = new Array<RequestProduct>();
  }

  ngOnInit() {
    this.role = localStorage.getItem("role");
    let user = localStorage.getItem("userDetails");
    if (typeof user === "string") {
      const parse = JSON.parse(user);
      this.userDetails = parse.data;

      this.roletype = this.userDetails.role;

      if (this.roletype === "Admin") {
        this.displayhide = false;
      } else {
        this.displayhide = true;
      }
    }

    this.getproductlist();
    this.getsource();

    //  this.category = '1';
    this.fileurls = environmentfiles.ENVIRONMENT.baseURL;
  }

  getproductlist() {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    if (this.source === "Ticket Express") {
      this.sourceParam = "TE";
      // this.category =  this.catid;
    }

    if (this.source === "QwikCilver") {
      this.sourceParam = "QC";
    }

    if (this.source === "Amazon") {
      this.sourceParam = "AMAZON";
    }
    if (this.source === "Joyalukkas") {
      this.sourceParam = "Joyalukkas";
    }

    if (this.source === "FlipKart") {
      this.sourceParam = "FlipKart";
    }
    if (this.source === "YGG") {
      this.sourceParam = "YGG";
    }
    if(this.source === "Bamboo") {
      this.sourceParam = "Bamboo";
    }
    if(this.source === "ValueDesign") {
      this.sourceParam = "ValueDesign";
    }
    if(this.source === "LikeCard") {
      this.sourceParam = "LikeCard";
    }
    // if(this.source === 'Voucherkart'){

    //   this.sourceParam = 'Voucherkart';
    // }
    this.apiLoading = true;
    this.loader.start();

    this._http
      .getproductList(
        this.limit,
        this.skip,
        this.sourceParam,
        this.brand_name,
        this.category,
        this.product,
        this.headers
      )
      .subscribe({
        next: (data: any) => {
          this.loader.stop();

          this.productList = data.data;

          if (data) {
            this.apiLoading = false;
            this.count = data.count;
            this.productList = data.data;
            this.productList = this.productList.map((x: any) => {
              if (
                x.thumbnail &&
                (!x.thumbnail.includes("http") ||
                  !x.thumbnail.includes("https"))
              ) {
                x.thumbnail = filesapiurl + x.thumbnail;
              }
              return x;
            });

            this.toolTipInit();

            if (this.skip == 0) {
              this.lastvalue = this.skip + 25;
            }
          }
        },
        error: (_error) => {
          this.apiLoading = false;
          this.loader.stop();
        },
      });
  }

  editedDatas() {
    if (this.price) {
      this.digitArray = this.price.split(",").map(Number);
      if (this.digitArray[0] === this.digitArray[1]) {
        this.toastr.error("Values Cannot be same");
        return;
      }
    }

    let postParams = {
      id: this.idvalue,
      // name: this.b2cname,
      description: this.descriptionList,
      name: this.nameList,
      store_locator: this.storelocator,
      important_instruction: this.instrction,
      how_to_use: this.howtouse,
      terms_and_conditon: this.terms,
      b2c_discount: this.discount,
      reward_points: this.reward,
      price: this.digitArray ? this.digitArray : "",
      usage_mode: this.usage_mode,
    };
    this.apiLoading = true;

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.getExternalProductlist(postParams, this.headers).subscribe(
      (data: any) => {
        this.productList = data;
        this.getproductlistfilter();

        if (data.status == 1) {
          this.toastr.success(data.msg);
        } else if (data.status == 0) {
          this.toastr.error("Data updated failure");
        }
        // this.getproductlist();

        // data = this.updatedDatas;
      },

      (error) => {
        this.toastr.error(error.error.Message);
        // console.log("error",error);
      }
    );
  }

  editProduct(list: any) {
    this.Edit = true;
    // this.storelocator= "";
    // this.instrction= "";
    // this.howtouse  = "";
    // this.discount='';
    // this.terms = "";
    // this.reward ='';
    // this.price = '';
    const element = document.getElementById("categoryModal") as HTMLElement;
    const addcategoryModal = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    addcategoryModal.show();
    this.idvalue = list.id;
    this.currentBody = this.productList.find((p: any) => {
      return p.id == list.id;
    });
    // console.log(this.currentBody.body);
    if (this.currentBody.usage_mode !== null) {
      this.usageMode = true;
    } else {
      this.usageMode = false;
    }
    this.descriptionList = this.currentBody.b2c_description;
    this.nameList = this.currentBody.b2c_name;
    this.discount = this.currentBody.b2c_discount;
    this.storelocator = this.currentBody.store_locator;
    this.instrction = this.currentBody.important_instruction;
    this.howtouse = this.currentBody.how_to_use;
    this.terms = this.currentBody.terms_and_conditon;
    this.reward = this.currentBody.reward_points;
    this.price = this.currentBody.b2c_price;
    console.log("Usage Mode :", this.currentBody.usage_mode);
    if (this.currentBody.usage_mode == "Omni Channel") {
      this.usage_mode = "Online + In-Store";
    } else if (this.currentBody.usage_mode == "Online") {
      this.usage_mode = "Online";
    } else if (this.currentBody.usage_mode == "Offline") {
      this.usage_mode = "In-Store";
    }
    this.userName = true;

    // this.currentId = this.currentBody.id;
    // this.getcategoryeditlist(this.idvalue);
  }

  restrictDecimals(event: any) {
    const inputValue = event.target.value;
    if (inputValue.includes(".")) {
      event.target.value = inputValue.substring(0, inputValue.indexOf("."));
    }
  }

  allowOnlyNumbers(event: KeyboardEvent): void {
    const allowedCharacters = "0123456789.,"; // Define the allowed characters
    const inputChar = event.key;

    if (
      inputChar === " " ||
      (inputChar !== "." && allowedCharacters.indexOf(inputChar) === -1)
    ) {
      event.preventDefault(); // Prevent the input
    }
  }

  getproductlistfilter() {
    this.loader.start();
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    if (this.source === "Ticket Express") {
      this.sourceParam = "TE";
      // this.category =  this.catid;
    }

    if (this.source === "QwikCilver") {
      this.sourceParam = "QC";
    }

    if (this.source === "Amazon") {
      this.sourceParam = "AMAZON";
    }
    if (this.source === "Joyalukkas") {
      this.sourceParam = "Joyalukkas";
    }

    if (this.source === "FlipKart") {
      this.sourceParam = "FlipKart";
    }

    if (this.source === "EZPIN") {
      this.sourceParam = "EZPIN";
    }
    if (this.source === "MEA") {
      this.sourceParam = "MEA";
    }
    if (this.source === "DT") {
      this.sourceParam = "DT";
    }
    if (this.source === "YGG") {
      this.sourceParam = "YGG";
    }
    if(this.source === "Bamboo"){
      this.sourceParam = "Bamboo";
    }
    if(this.source === "ValueDesign") {
      this.sourceParam = "ValueDesign";
    }
    if(this.source === "LikeCard"){
      this.sourceParam = "LikeCard";
    }
    // if(this.source === 'Voucherkart'){

    //   this.sourceParam = 'Voucherkart';
    // }
    this.apiLoading = true;

    this._http
      .getproductList(
        this.limit,
        this.skip,
        this.sourceParam,
        this.brand_name,
        this.category,
        this.product,
        this.headers
      )
      .subscribe({
        next: (data: any) => {
          this.loader.stop();
          this.productList = data.data;

          if (data) {
            this.apiLoading = false;
            this.count = data.count;
            this.productList = data.data;
            this.productList = this.productList.map((x: any) => {
              // console.log("eeee",x.thumbnail)
              if (
                x.thumbnail &&
                (!x.thumbnail.includes("http") ||
                  !x.thumbnail.includes("https"))
              ) {
                x.thumbnail = filesapiurl + x.thumbnail;
              }
              return x;
            });
            this.toolTipInit();

            if (this.skip == 0) {
              this.lastvalue = this.skip + 25;
            }
          }
        },
        error: (_error) => {
          this.apiLoading = false;
          this.loader.stop();
        },
      });
  }

  selectcategoryEvent(event: any) {
    // console.log(event.target.value)
    // this.catid = event.target.value;
    // console.log(this.catid)
  }

  getsource() {
    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    let item = "";
    this._http.getSource(item, this.headers).subscribe(
      (data: any) => {
        this.sourceData = data.data;
      },
      (error) => {
        this.loader.stop();
        this.toastr.error(error.error.Message);
      }
    );
  }

  getbrand(value: any) {
    if (value === "QwikCilver") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const source = "QC";
      // let type = 'QC';

      this._http.getBrands(source, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data;
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (value === "Ticket Express") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const source = "TE";
      // let type = 'TE';
      this._http.getBrands(source, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data;
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (value === "Amazon" || value === "FlipKart" || value === "Joyalukkas") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      let source = "AMAZON";
      if (value === "FlipKart") {
        source = "FlipKart";
      }else if (value === "Joyalukkas") {
        source = "Joyalukkas";
      }
      this._http.getBrands(source, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data;
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (value === "EZPIN") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const source = "EZPIN";
      // let type = 'TE';
      this._http.getBrands(source, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data;
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (value === "MEA") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const source = "MEA";
      // let type = 'TE';
      this._http.getBrands(source, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data;
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    }
    else if (value === "DT") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const source = "DT";
      // let type = 'TE';
      this._http.getBrands(source, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data;
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    }
    else if (value === "Bamboo" || value === "ValueDesign" || value === "LikeCard") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };
      const source = value === "Bamboo" ? "Bamboo" : value === "ValueDesign" ? 'ValueDesign' : "LikeCard"  ;
      this._http.getBrands(source, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data;
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const source = "";
      // let type = value.type;

      this._http.getBrands(source, this.headers).subscribe(
        (data: any) => {
          this.brandData = data.data;
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    }
  }

  getcategory(value: any) {
    if (value === "QwikCilver") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const source = "QC";

      this._http.getCategory(source, this.headers).subscribe(
        (data: any) => {
          this.categoryData = data.data;
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (value === "Ticket Express") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const source = "TE";
      this._http.getCategory(source, this.headers).subscribe(
        (data: any) => {
          this.categoryData = data.data;
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else if (value === "Amazon" || value === "FlipKart" || value === "Joyalukkas") {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      let source = "AMAZON";
      if (value === "FlipKart") {
        source = "FlipKart";
      }else if(value === "Joyalukkas") {
        source = "Joyalukkas";
      }
      this._http.getCategory(source, this.headers).subscribe(
        (data: any) => {
          this.categoryData = data.data;
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    } else {
      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }),
      };

      const source = value;

      this._http.getCategory(source, this.headers).subscribe(
        (data: any) => {
          this.categoryData = data.data;
        },
        (error) => {
          this.loader.stop();
          this.toastr.error(error.error.Message);
        }
      );
    }
  }

  filterList() {
    if (
      this.limit ||
      this.skip ||
      this.source ||
      this.brand_name ||
      this.category
    ) {
      this.skip = 0;
      this.p = 1;
    }
    this.getproductlistfilter();
  }

  selectsourceEvent(value: any) {
    this.fieldDisabled = false;
    this.brand_name = "";
    this.category = "";
    this.sourcevalue = value;
    this.getcategory(value);
    this.getbrand(value);
    this.categoryData = [];
    this.brandData = [];
    this.filterList();
    // this.productList = [];
    this.getproductlist();
  }

  clear() {
    this.productList = [];
    this.source = "";
    this.brand_name = "";
    this.category = "";
    this.product = "";

    this.sourceParam = "";
    this.brand_name = "";
    this.category = "";
    this.product = "";

    this.fieldDisabled = true;
    this.getproductlistfilter();

    this.p = 1;
    let page = this.p;
    this.pageChanged(page);
  }

  pageChanged(pageNo: any) {
    this.p = pageNo;
    this.skip = (pageNo - 1) * 25;
    this.lastvalue = this.skip + 25;
    // localStorage.setItem('pageNo', pageNo);
    this.getproductlist();
  }

  exportAllVoucher() {
    // if (this.role === "admin" || this.role === "SuperAdmin") {
    //   this.user_type = "domestic";
    // } else if (
    //   this.role === "company" ||
    //   this.role === "brand" ||
    //   this.role === "Company"
    // ) {
    //   this.user_type = "Restricted";
    // }

    // this._http.exportAllVouchers(
    //   this.user_type,
    //   this.userDetails.UserID,
    //   "voucher"
    // );
    // if (this.role === "admin" || this.role === "SuperAdmin") {
    //   this.user_type = "ALL"
    // }
    // else if (this.role === "company" || this.role === "brand" || this.role === "Company") {
    //   this.user_type = "Restricted";
    // }
    // this._http.exportAllVouchers(this.user_type, this.userDetails.UserID, 'voucher')
    // this.loader.start();
    if(this.source === "QwikCilver"){
      this.source = "QC";
    }
    if(this.source === "Ticket Express"){
      this.source = "TE";
    }
    this._http.getproductDownload(this.source,this.category,this.brand_name,this.product)
  }

  importVoucher(event: any) {
    const getImg =
      event.srcElement !== undefined
        ? event.srcElement.value.substr(event.srcElement.value.indexOf("."))
        : "";

    if (getImg.toLowerCase() !== ".csv") {
      this.toastr.error("File not supported");
      return;
    } else {
    }

    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set("Accept", "multipart/form-data");
      const formData: any = new FormData();
      formData.append("filename", event.target.files[0]);
      formData.append("created_by", this.userDetails.UserID);
      formData.append("created_by_name", this.userDetails.UserName);

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          //  'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
        }),
      };

      this._http.importproduct(formData, this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();

          if (data.status === 1) {
            this.toastr.success(data.msg);
            this.getproductlist();
          } else if (data.status === 1) {
            this.toastr.success("Your file uploaded successfully");
            this.getproductlist();
          } else if (data.status === 0) {
            this.toastr.error(data.msg);
          }

          this.inputFile.nativeElement.value = "";
        },
        error: (error: any) => {
          this.loader.stop();
          this.toastr.error(error.Message);
        },
      });
    }
  }

  onKeyPresscomma(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  onKeyPressspecialcharacters(event: KeyboardEvent) {
    const key = event.key;
    const isSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
      key
    );
    if (isSpecialCharacter) {
      event.preventDefault();
    }
  }

  //   type(productList:RequestProduct,status:any,){

  // var vval = "";
  // var rval = "";
  //     console.log(productList)
  //     if(status==0 ||status=="0"){
  //       vval = "ON";
  //       rval = "OFF";

  //     }
  //     else if(status==1 || status=="1"){
  //       vval = "OFF";
  //       rval = "ON";

  //     }
  //     else{
  //       this.toastr.error();
  //     }

  //     const params = {

  //      id: productList ,
  //       display_voucherkart: vval,
  //       display_rewartkart: rval,

  //     }

  //     let token = localStorage.getItem("token");
  //     this.headers = {
  //        headers: new HttpHeaders({
  //          'Content-Type': 'application/json',
  //          'Authorization': 'Bearer ' + token
  //        })
  //      };

  //     this._http.updateproduct(params,this.headers).subscribe({
  //       next: (data: any)=>{

  //         console.log(data)

  //         if((data.status == 1 && status == 1) ||status=="1"){
  //           this.supplierType="Rewardkart";
  //           this.toastr.success("Product Status Changed");
  //           this.getproductlist();

  //         }
  //         else if((data.status == 1 && status == 0) || status=="0"){
  //           this.supplierType="Voucherkart";
  //           this.toastr.success("Product Status Changed");
  //           this.getproductlist();
  //         }

  //     },

  //   });
  // }

  type(productList: RequestProduct, status: any, value: any) {
    var vval = "";
    var rval = "";

    if (status == 0 || status == "0") {
      vval = value;
      if (value == "OFF") {
        rval = "OFF";
      } else {
        rval = productList.display_rewartkart;
      }
    } else if (status == 1 || status == "1") {
      vval = productList.display_voucherkart;
      rval = value;
    } else {
      this.toastr.error();
    }

    const params = {
      id: productList.id,
      display_voucherkart: vval,
      display_rewartkart: rval,
    };

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };

    this._http.updateproduct(params, this.headers).subscribe({
      next: (data: any) => {
        if ((data.status == 1 && status == 1) || status == "1") {
          this.selectrewardkart = "Rewardkart";
          // this.toastr.success("Product Status Changed");
          this.getproductlist();
        } else if ((data.status == 0 && status == 0) || status == "0") {
          this.selectvoucherkart = "Voucherkart";
          // this.toastr.success("Product Status Changed");
          this.getproductlist();
        }
      },
    });
  }

  toolTipInit() {
    setTimeout(() => {
      let tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, { trigger: "hover" });
      });
    }, 500);
  }

  ngOnDestroy() {
    if (this.tooltipList) {
      this.tooltipList.forEach((tooltip: { dispose: () => void }) => {
        tooltip.dispose();
      });
    }
  }

  selectfile(event: any, id: any, filepath: string) {
    if (
      event.target.files &&
      event.target.files.length &&
      event.target.files[0]
    ) {
      this.pathurl = apiurl;
      const blobUrl = URL.createObjectURL(event.target.files[0]);
      const safeblobUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
      this.previews = safeblobUrl;
      let httpHeaders = new HttpHeaders();
      httpHeaders.set("Accept", "multipart/form-data");
      const formData: any = new FormData();
      formData.append("id", id);
      formData.append("product_image", event.target.files[0]);
      formData.append("product_image_path", filepath);

      this._http.updateImage(formData).subscribe(
        (data: any) => {
          this.loader.stop();

          if (data.status == 1) {
            this.loader.stop();
            this.updatedData = data;
            this.getproductlist();
            this.toastr.success(data.msg);
          } else if (data.status == 0) {
            this.loader.stop();
            this.toastr.error("Data updated failure");
          }
        },

        (error) => {
          this.toastr.error(error.error.Message);
        }
      );
      return;
    }
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"], ["toggleEditorMode"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  importVoucherSKU(event: any) {
    const getImg =
      event.srcElement !== undefined
        ? event.srcElement.value.substr(event.srcElement.value.indexOf("."))
        : "";

    if (getImg.toLowerCase() !== ".csv") {
      this.toastr.error("File not supported");
      return;
    } else {
    }

    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set("Accept", "multipart/form-data");
      const formData: any = new FormData();
      formData.append("filename", event.target.files[0]);
      formData.append("created_by", this.userDetails.UserID);
      formData.append("created_by_name", this.userDetails.UserName);

      let token = localStorage.getItem("token");
      this.headers = {
        headers: new HttpHeaders({
          //  'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
        }),
      };

      this._http.importproductsku(formData, this.headers).subscribe({
        next: (data: any) => {
          this.loader.stop();

          if (data.status === 1) {
            this.toastr.success(data.msg);
            this.getproductlist();
          } else if (data.status === 1) {
            this.toastr.success("Your file uploaded successfully");
            this.getproductlist();
          } else if (data.status === 0) {
            this.toastr.error(data.msg);
          }

          this.inputSKUFile.nativeElement.value = "";
        },
        error: (error: any) => {
          this.loader.stop();
          this.toastr.error(error.Message);
        },
      });
    }
  }

  editSKU(list:any){
    // console.log(list);
    if(list && list.id){
      this.editedSkuId = list.id;
    }
    if(list && list.sku){
      this.editedSku = list.sku;
    }
    const element = document.getElementById("skuEdit") as HTMLElement;
    const addcategoryModal = new Modal(element, {
      backdrop: "static",
      keyboard: false,
    });
    addcategoryModal.show();
  }

  updateSKU(){
    if(!this.editedSku){
      this.toastr.error("Please Provide SKU");
      return;
    }

    let token = localStorage.getItem("token");
    this.headers = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    let params = {
      "sku":this.editedSku
    }
    this.loader.start();
    this._http.updateSku(params,this.headers,this.editedSkuId).subscribe((data:any) => {
      this.loader.stop();
      if(data && (data.status === 1 || data.status === "1")){
        this.toastr.success(data.msg);
        this.getproductlist();
      }else{
        this.toastr.error(data.msg);
      }
    })

  }
}
