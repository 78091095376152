export class RequestBrand {
  // voucher_request_id: any;
  // source: string = '';
  // brand_name: string = '';
  // type: string = '';
  // validity: any;
  // quantity: any;
  // payment_status: string = '';
  // payment_method: string = '';
  // created_by: string = '';
  // createdOn: string = '';
  // modifiedby: any = '';
  // modifieddate: any;
  // status: string = '';
  // action: string = '';
  // user_name: string = '';
  // product: string = '';
  // isSelected: boolean = false;
  // modiedfy_by_name: string = '';
  // created_by_name: string = '';
  // companyId:any;
  // brandId:any;
  // start:any;
  // evalidity:any
  // ivalidity:any;
  // end:any;
  // issuance:any;
  // Expiry:any;
  // supplier_type:any;
  // issuance_date: any;
  // start_date: any;
  // block_status:any;
  // voucher_type: string = 'voucher_type';
  // issuance_fee:any;
  productURL: string = "";
  // total:any;
  // discount:any;
  // payment_received:string = 'No';
  // kart_type : any;
  // denomination: any;
  // log:any;
  // external_product_id : any;
  // product_id : any;
  // otp:any;
  supplierType: any;
  // authentication_type:any;
  // card_number : any ;
  // card_pin : any;

  voucher_request_id: any;
  brand_name: any = "";
  brandId: any;
  type: any = "ID";
  category: any = "Digital";
  denomination: any;
  validity: any;
  quantity: any = "";
  payment_status: string = "";
  created_by: string = "";
  createdOn: string = "";
  modified_by: any = "";
  modifieddate: any;
  status: string = "";
  action: string = "";
  user_name: string = "";
  points: any = "";
  isSelected: boolean = false;
  modiedfy_by_name: string = "";
  created_by_name: string = "";
  supplier_type: any;
  start_date: any;
  issuance_date: any;
  block_status: any;
  voucher_type: string = "voucher";
  issuance_fee: any;
  voucher_delivery_email: any;
  total: any;
  discount: any;
  payment_received: string = "No";
  kart_type: any = "";
  otp: any;
  authentication_type: any = "";
  voucher_redemption_type: any = "";
  log: any;
  product_id: any;
  card_number: any;
  card_pin: any;
  source: string = "";
  source_id: any = "";
  company_name: string = "";
  companyId: any;
  modal: string = "";
  product: any;
  usagemode: any;
  minPrice: any;
  maxPrice: any;
  usage_mode: any;
  totalfacevalue: any;
  maxdiscount: any;
  voucher_approve_admin: any;
  brand_total: any;
  product_discount: any;
  created_by_role: any;
  minface_value: any;
  maxface_value: any;
  internal_product_discount: any;
  internal_face_value: any;
  internal_usage_mode: any;
  product_url: any;
  order_recevied_from: any;
  LOG: any;
  voucher_redemption_url: any;
  voucher_delivery_type: string = "";
  voucher_option: any;
  currency_symbol: any = "";
  currency: any= "";
  product_fee: any= "";
  currency_code: any="";
}
