<div class="dashboard-section">
    <div class="row mx-0">
      <div class="col-12 card border-0 shadow-sm px-0">
        <div class="card-body pb-4">
          <div class="row mb-4">
            <div class="col-3">
              <label class="texts">Inventory Voucher</label>
            </div>
            <div class="text-end col-9">
            
  
              <a
                href="{{ this.fileurls }}/sample/sample_inventory_voucher.csv"
                class="add_bt btn btn-success mr-10"
                type="button"
                target="_self"
                download="sample-file.csv"
                *ngIf="
                  role === 'admin' || (role === 'SuperAdmin' && showRemoveImport)
                "
              >
                <i class="fa fa-download pr-1"></i> Sample file
              </a>
              <label
                class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10"
                for="inputFileInvendory"
                *ngIf="
                  role === 'admin' || (role === 'SuperAdmin' && showRemoveImport)
                "
              >
                <input
                 
                  class="form-control d-none"
                  id="inputFileInvendory"
                  data-bs-dismiss="modal"
                  (click)="manualUpload()"
                />
                <i class="fa fa-upload pr-1"></i> Import</label
              >

            </div>
          </div>

          <form
          #voucherRequestForm="ngForm"
          name="form"
          (ngSubmit)="filterList()">
          <div class="d-flex">
            
            <div
              class="form-floating me-3"
              *ngIf="
                role === 'admin' || role === 'SuperAdmin' || role === 'brand'
              "
            >
              <input
                type="text"
                class="form-control border-0 bg-light"
                [(ngModel)]="filename"
                name="filename"
                *ngIf="
                  role === 'admin' || role === 'SuperAdmin' || role === 'brand'
                "
                id="mname"
                placeholder="File Name"
               
              />
              <label for="mname">File Name</label>
            </div>
  
            
            <div
            class="form-floating me-3"
            *ngIf="
              role === 'admin' || role === 'SuperAdmin' || role === 'brand'
            "
          >
            <input
              type="text"
              class="form-control border-0 bg-light"
              [(ngModel)]="productname"
              name="productname"
              *ngIf="
                role === 'admin' || role === 'SuperAdmin' || role === 'brand'
              "
              id="mname"
              placeholder="Product Name"
              
            />
            <label for="mname">Product Name</label>
          </div>

         
          <div
          class="form-floating me-3"
          *ngIf="
            role === 'admin' || role === 'SuperAdmin' || role === 'brand'
          "
        >
          <input
            type="text"
            class="form-control border-0 bg-light"
            [(ngModel)]="vochercode"
            name="vochercode"
            *ngIf="
              role === 'admin' || role === 'SuperAdmin' || role === 'brand'
            "
            id="mname"
            placeholder="Vocher Code"
            
          />
          <label for="mname">Vocher Code</label>
        </div>

              <button
              class="btn btn-success mr-5"
              type="submit"
              style="width: 90px"
            >
              Submit
            </button>
          
            <button
              class="btn btn-secondary mr-5"
              (click)="clearform(voucherRequestForm)"
              style="width: 90px"
            >
              Clear
            </button>
          
          </div>
        </form>
  
          <div class="mt-4 batch-data px-1">
            <div
              class="row mx-0 adcolor py-4 opacity-75"
              *ngIf="role === 'admin' || role === 'SuperAdmin'"
            >
          
              <div
                class="col labelText test-class"
                *ngIf="role === 'admin' || role === 'SuperAdmin'"
                [ngClass]="approved === 'G' ? 'pe-2' : 'pe-0'"
              >
              File Name
              </div>
            
            
              <div class="col labelText px-0 text-center">Voucher Count</div>
              <div class="col labelText px-0 text-center">Allocated</div>
              <div class="col labelText px-0 text-center">Not Allocated</div>
              <div class="col labelText px-0 text-center">Create Date</div>
           
              <!-- <div class="col labelText"> Order Receive From</div> -->
              <div class="col labelText px-0 text-center test-cl">Action</div>
            </div>
  
  
            <div
              class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
              ng-reflect-ng-class="bg-light"
              *ngIf="voucherList && voucherList.length === 0 && !apiLoading"
            >
              <div class="card-body row mx-0 ps-0">
                <div class="text-center">No Record Found.</div>
              </div>
            </div>
  
            <div>
              <div
                *ngFor="
                  let list of voucherList
                    | paginate
                      : {
                          itemsPerPage: limit,
                          currentPage: p,
                          totalItems: count
                        };
                  let i = index
                "
              >
                <div
                  class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                  ng-reflect-ng-class="bg-light"
                >
                  <div class="card-body row mx-0 px-0">
                 
  
                    <div
                      class="col text-start px-2 test-class textsiz text-truncate"
                      *ngIf="
                        role === 'admin' ||
                        role === 'SuperAdmin' ||
                        role === 'brand'
                      "
                      [ngClass]="
                        approved === 'G' ||
                        role !== 'admin' ||
                        role !== 'SuperAdmin'
                          ? 'ps-2'
                          : 'px-0'
                      "
                      title="{{ list.file_name }}"
                    >
                      {{ list.file_name }}
                    </div>
              
  
                    <div
                      class="col"
                      [ngClass]="
                        role === 'admin' || role === 'SuperAdmin'
                          ? 'text-center'
                          : 'text-center col-1 px-0'
                      "
                    >
                      {{ list.voucher_count }}
                    </div>
  
                    <div
                    class="col"
                    [ngClass]="
                      role === 'admin' || role === 'SuperAdmin'
                        ? 'text-center'
                        : 'text-center col-1 px-0'
                    "
                  >
                    {{ list.allocated }}
                  </div>

                  <div
                  class="col"
                  [ngClass]="
                    role === 'admin' || role === 'SuperAdmin'
                      ? 'text-center'
                      : 'text-center col-1 px-0'
                  "
                >
                  {{ list.not_allocated }}
                </div>

                  <div
                  class="col"
                  [ngClass]="
                    role === 'admin' || role === 'SuperAdmin'
                      ? 'text-center'
                      : 'text-center col-1 px-0'
                  "
                >
                  {{ list.create_date }}
                </div>
  
                    <div
                      class="col"
                      [ngClass]="
                        role === 'admin' || role === 'SuperAdmin'
                          ? 'text-center test-view px-0'
                          : 'text-end col-1 px-0'
                      "
                    >
                      <a class="linkColor" (click)="viewGenerationList(list)">
                        <i
                          class="fa fa-eye cursor col"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="View"
                        ></i>
                      </a>
                      <a
                        class="linkColor"
                        
                        (click)="exportVoucher(list.id)"
                      >
                        <i
                          class="fa fa-download cursor col"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="Download"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="row" *ngIf="voucherList && voucherList.length > 0">
                <div class="col-6 pt-3">
                  <p>
                    Showing {{ p > 1 ? skip + 1 : p }} to
                    {{ lastvalue > count ? count : lastvalue }} of
                    {{ count }} entries
                  </p>
                </div>
                <div
                  class="col-6"
                  *ngIf="role === 'admin' || role === 'SuperAdmin'"
                >
                  <pagination-controls
                    class="list-pagination adpage"
                    (pageChange)="pageChanged($event)"
                  >
                  </pagination-controls>
                </div>
  
                <div
                  class="col-6"
                  *ngIf="role === 'company' || role === 'Company'"
                >
                  <pagination-controls
                    class="list-pagination companypage"
                    (pageChange)="pageChanged($event)"
                  >
                  </pagination-controls>
                </div>
  
                <div class="col-6" *ngIf="role === 'brand'">
                  <pagination-controls
                    class="list-pagination brandpage"
                    (pageChange)="pageChanged($event)"
                  >
                  </pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ngx-ui-loader></ngx-ui-loader>
     
  
        <div
          class="modal fade"
          id="rejectModal"
          tabindex="-1"
          aria-
          labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body text-center">
                <p>Are you sure to reject the voucher?</p>
                <p class="mt-5">
                  <button
                    class="btn btn-secondary mr-5"
                    data-bs-dismiss="modal"
                    (click)="clear()"
                    #closeBtn
                  >
                    Cancel
                  </button>
                  <button
                    class="btn btn-success"
                    data-bs-dismiss="modal"
                    (click)="Rejectbrand(requestCouponVoucher)"
                  >
                    Confirm
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
  
        <div
          class="modal fade"
          id="approveModals"
          tabindex="-1"
          aria-
          labelledby="approveModals"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body text-center">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    position: relative;
                    padding: 10px;
                  "
                >
                  <div style="text-align: center; flex-grow: 1">
                    <label>Supplier Type: </label>
                    <span class="tex text-primary" style="color: red"
                      >External</span
                    >
                  </div>
                  <div
                    style="
                      position: absolute;
                      top: 5px;
                      right: 5px;
                      cursor: pointer;
                      font-weight: bold;
                    "
                    data-bs-dismiss="modal"
                    (click)="closeModule()"
                    #closeBtn
                  >
                    <i
                      class="fa fa-times cursor col"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Close"
                    ></i>
                  </div>
                </div>
  
                <p class="mt-5">
                  <button
                    class="btn btn-success mr-5"
                    data-bs-dismiss="modal"
                    (click)="approveVouchers(requestCouponVoucher)"
                    #closeBtn
                  >
                    API
                  </button>
                  <button
                    class="btn btn-success mr-5"
                    data-bs-dismiss="modal"
                    (click)="manualUpload()"
                  >
                    Manual Upload
                  </button>
                  <button
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                  (click)="manualUpload()"
                >
                  Inventory
                </button>
                </p>
              </div>
            </div>
          </div>
        </div>
  
        <div
          #closeModal
          class="modal fade"
          id="manualModals"
          tabindex="-1"
          aria-
          labelledby="exampleModalLabel"
          aria-hidden="true"  
        >
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-body text-center">
                <div class="d-flex">
                  <div>
                    <h6 style="font-weight: bold">Inventory Voucher
                    </h6>
                  </div>
                  <div style="margin-left: auto; font-weight: bold">
                    <a
                      href="{{ this.fileurls }}/sample/sample_inventory_voucher.csv"
                      class="add_bt btn btn-success mr-10"
                      type="button"
                      target="_self"
                      download="sample-file.csv"
                    >
                      <i class="fa fa-download pr-1"></i> Sample file
                    </a>
                    <label
                      class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10"
                      for="inputFileManual"
                    >
                      <input
                        type="file"
                        accept=".csv"
                        class="form-control d-none"
                        id="inputFileManual"
                        #inputFileManual
                        (change)="onFileChange($event)"
                      />
                      <i class="fa fa-upload pr-1"></i> Import</label
                    >
                  </div>
                  <div
                    style="
                      position: absolute;
                      top: 5px;
                      right: 5px;
                      cursor: pointer;
                      font-weight: bold;
                    "
                    data-bs-dismiss="modal"
                    (click)="closeModule()"
                    #closeBtn
                  >
                    <i
                      class="fa fa-times cursor col"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Close"
                    ></i>
                  </div>
                </div>
  
                <div class="mt-4 batch-data px-1">
                  <div class="row mx-0 adcolor py-4 opacity-75">
                    <div class="col labelText test-class">Product Name</div>
                    <div class="col labelText test-class">Product Sku </div>
                    <div class="col labelText test-class">Voucher Code</div>
                    <div class="col labelText test-class">Voucher Pin</div>
                    <div class="col labelText test-class">Denomination</div>
                    <div class="col labelText test-class">Validity</div>
                  </div>
                  <div
                    *ngIf="previewData.length > 0"
                    [ngStyle]="{ 'height': previewData.length > 5 ? '300px' : 'auto', 'overflow-y': previewData.length > 5 ? 'auto' : 'visible' }"
                  >
                    <div
                      *ngFor="let row of previewData"
                      class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                    >
                      <div class="card-body row mx-0 px-0">
                        <div
                          *ngFor="let cell of row"
                          class="col text-star px-2 test-class"
                        >
                          {{ cell }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
                    ng-reflect-ng-class="bg-light"
                    *ngIf="!previewData?.length"
                  >
                    <div class="card-body row mx-0 ps-0">
                      <div class="text-center">
                        No data imported. Please upload a file.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <p *ngIf="previewData.length > 0">
                    <!-- No of Data: {{ previewData?.length }} -->
                  </p>
                  <button
                    *ngIf="previewData?.length"
                    class="btn btn-success"
                    style="margin-left: auto; padding: 5px 20px"
                    data-bs-dismiss="modal"
                    #closeBtn
                    (click)="submitData()"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
  
          <div
            class="modal fade"
            id="approveModalss"
            tabindex="-1"
            aria-
            labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body text-center">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      position: relative;
                      padding: 10px;
                    "
                  >
                    <div style="text-align: center; flex-grow: 1">
                      <label>Supplier Type: </label>
                      <span class="tex text-primary" style="color: red"
                        >Internal</span
                      >
                    </div>
                    <div
                      style="
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        cursor: pointer;
                        font-weight: bold;
                      "
                      data-bs-dismiss="modal"
                      (click)="clear()"
                      #closeBtn
                    >
                      <i
                        class="fa fa-times cursor col"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Close"
                      ></i>
                    </div>
                  </div>
    
                  <p class="mt-5">
                    <button
                      class="btn btn-success mr-5"
                      data-bs-dismiss="modal"
                      (click)="approveVouchers(requestCouponVoucher)"
                      #closeBtn
                    >
                      API
                    </button>
                    <button
                      class="btn btn-success"
                      data-bs-dismiss="modal"
                      (click)="manualUpload()"
                    >
                      Manual Upload
                    </button>
  
                    <button
                    class="btn btn-success"
                    data-bs-dismiss="modal"
                    (click)="manualUpload()"
                  >
                    Inventory
                  </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  