import { Component, NgModule, inject } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { BKDashboardComponent } from "../brand-kart/dashboard/dashboard.component";
import { DKDashboardComponent } from "../deal-kart/dashboard/dashboard.component";
import { VKDashboardComponent } from "../voucher-Kart/dashboard/dashboard.component";
import { LoginComponent } from "../login/login.component";
import { VKCreateOrderComponent } from "../voucher-Kart/create-order/create-order.component";
import { ViewVocherComponent } from "../inventory/view-voucher/view-voucher.component";
import { BKCreateOrderComponent } from "../brand-kart/create-order/create-order.component";
import { DKCreateOrderComponent } from "../deal-kart/create-order/create-order.component";
import { SearchComponent } from "../voucher-Kart/search/search.component";
import { VoucherKartEditComponent } from "../voucher-Kart/dashboard/voucher-kart-edit/voucher-kart-edit.component";
import { DealKartEditComponent } from "../deal-kart/dashboard/deal-kart-edit/deal-kart-edit.component";
import { BrandKartEditComponent } from "../brand-kart/dashboard/brand-kart-edit/brand-kart-edit.component";
import { CompanyComponent } from "../company/company.component";
import { BrandComponent } from "../brand/brand.component";
import { CompanyContractComponent } from "../company-contract/company-contract.component";
import { ProductComponent } from "../product/product.component";
import { CategoryComponent } from "../category/category.component";
import { BrandManagementComponent } from "../brand-management/brand-management.component";
import { BrandMgtEditComponent } from "../brand-management/brand-mgt-edit/brand-mgt-edit.component";
import { ProductmanagementComponent } from "../productmanagement/productmanagement.component";
import { ProductmgtEditComponent } from "src/app/productmanagement/productmgt-edit/productmgt-edit.component";
import { ProductMappingComponent } from "../product-mapping/product-mapping.component";
import { SettingsmenuComponent } from "../settingsmenu/settingsmenu.component";
import { AppService } from "src/app/restful-services/restful.service";
import { map, distinctUntilChanged, shareReplay } from "rxjs/operators";
import { TemplatesComponent } from "../templates/templates.component";
import { RouteBlockGuard } from "../route-block.guard";
import { VoucherWalletHistoryComponent } from "../voucher-wallet-history/voucher-wallet-history.component";
import { VoucherReportComponent } from "../voucher-report/voucher-report.component";
import { DenominationDashboardComponent } from "../denomination-dashboard/denomination-dashboard.component";
import { DenominationOrderViewlistComponent } from "../denomination-order-viewlist/denomination-order-viewlist.component";
import { CompanyAddEditComponent } from "../company/company-add-edit/company-add-edit.component";
import { ClientDigitalVoucherComponent } from "../voucher-Kart/dashboard/client-digital-voucher/client-digital-voucher.component";
import { ClientProductMappingComponent } from "../company/client-product-mapping/client-product-mapping.component";
import { WalletThresholdComponent } from "../company/wallet-threshold/wallet-threshold.component";
import { TermsComponent } from "../terms/terms.component";
import { RewardkartEmailTemplatesComponent } from "../rewardkart-email-templates/rewardkart-email-templates.component";
import { DigitalVoucherReportComponent } from "../digital-voucher-report/digital-voucher-report.component";
import { ExternalCategoryComponent } from "../external-category/external-category.component";
import { ExternalBrandComponent } from "../external-brand/external-brand.component";
import { B2cBrandComponent } from "../b2c-brand/b2c-brand.component";
import { B2cProductComponent } from "../b2c-product/b2c-product.component";
import { B2cCategoryComponent } from "../b2c-category/b2c-category.component";
import { CategoryProductComponent } from "../category-product/category-product.component";
import { ClientEmailLayoutComponent } from "../client-email-layout/client-email-layout.component";
import { CurrencyMarkupComponent } from "../currency-markup/currency-markup.component";
import { InternationalvoucherComponent } from "../internationalvoucher/internationalvoucher.component";
import { InternationaldashboardComponent } from "../internationaldashboard/internationaldashboard.component";
import { InternationalorderviewComponent } from "../internationalorderview/internationalorderview.component";
import { ClientRewardkartComponent } from "../company/client-rewardkart/client-rewardkart.component";
import { InventoryVoucherComponent } from "../inventory/inventory-voucher.component";
import { RkCatelogueComponent } from "../rk-catelogue/rk-catelogue.component";

const routes: Routes = [
  { path: "add-dealkart", component: DealKartEditComponent },
  { path: "add-digital", component: VoucherKartEditComponent },
  { path: "add-product", component: BrandKartEditComponent },
  { path: "brand", component: BrandComponent },
  { path: "brandmgt", component: BrandManagementComponent },
  { path: "brandmgt-add", component: BrandMgtEditComponent },
  { path: "category", component: CategoryComponent },
  { path: "clientcontract", component: CompanyContractComponent },
  { path: "clients", component: CompanyComponent },
  { path: "create-order-deal", component: DKCreateOrderComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "deal-dashboard", component: DKDashboardComponent },
  { path: "digital-dashboard", component: VKDashboardComponent },
  { path: "international-dashboard", component: InternationaldashboardComponent},
  { path: "international-voucher", component: InternationalvoucherComponent},
  { path: "digital-order-view", component: VKCreateOrderComponent },
  { path: "international-order-view", component: InternationalorderviewComponent },
  { path: "edit-brandkart", component: BrandKartEditComponent },
  { path: "edit-dealkart", component: DealKartEditComponent },
  { path: "edit-voucherkart", component: VoucherKartEditComponent },
  { path: "external-product", component: ProductComponent },
  { path: "internal-product", component: ProductmanagementComponent },
  { path: "internalproduct-add", component: ProductmgtEditComponent },
  { path: "login", component: LoginComponent },
  { path: "product-dashboard", component: BKDashboardComponent },
  { path: "product-order-view", component: BKCreateOrderComponent },
  { path: "productmap", component: ProductMappingComponent },
  { path: "source-mgt", component: SearchComponent },
  { path: "settings", component: SettingsmenuComponent },
  { path: "templates", component: TemplatesComponent },
  { path: "wallet", component: VoucherWalletHistoryComponent },
  { path: "report", component: VoucherReportComponent },
  { path: "denomination-dashboard", component: DenominationDashboardComponent },
  {
    path: "denomination-order-viewlist",
    component: DenominationOrderViewlistComponent,
  },
  { path: "company-edit", component: CompanyAddEditComponent },
  { path: "client-add-digital", component: ClientDigitalVoucherComponent },
  { path: "client-product-mapping", component: ClientProductMappingComponent },
  { path: "wallet-threshold", component: WalletThresholdComponent },
  { path: "terms", component: TermsComponent },
  { path: "rewardkart-template", component: RewardkartEmailTemplatesComponent },
  { path: "digital-voucher", component: DigitalVoucherReportComponent },
  { path: "external-category", component: ExternalCategoryComponent },
  { path: "external-brand", component: ExternalBrandComponent },
  { path: "b2c-brand", component: B2cBrandComponent },
  { path: "b2c-product", component: B2cProductComponent },
  { path: "b2c-category", component: B2cCategoryComponent },
  { path: "product-category", component: CategoryProductComponent },
  { path: "client-email", component: ClientEmailLayoutComponent },
  { path: "currency_markup", component: CurrencyMarkupComponent },
  { path: "rewardkart-client", component: ClientRewardkartComponent },
  { path: "inventory-voucher", component: InventoryVoucherComponent },
  { path: "view-voucher", component: ViewVocherComponent },
  { path: "", pathMatch: "full", redirectTo: "login" },
  { path : 'catelogue' , component : RkCatelogueComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
