import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { environmentfiles } from "src/environments/environment";

@Component({
  selector: "loyalty-app-left-sidebar",
  templateUrl: "./left-sidebar.component.html",
  styleUrls: ["./left-sidebar.component.scss"],
})
export class LeftSidebarComponent {
  public href: string = "";
  public items: any[] = [];
  public items1: any[] = [];
  public selectedItem = 0;
  public selectedItem1 = 0;
  role: any = "";
  path: string = "";
  // selectedItem: string = "";
  // collapsed:boolean = false;

  userDetails: any;
  roletype: any = "";

  constructor(private router: Router) {
    // let role = localStorage.getItem('role');
    this.role = localStorage.getItem("role") || "";
    this.path = window.location.pathname;

    let user = localStorage.getItem("userDetails");
    if (typeof user === "string") {
      const parse = JSON.parse(user);
      this.userDetails = parse;
      this.roletype = this.userDetails.data.role;
    }

    if (this.role === "admin" && this.roletype === "Admin") {
      this.items = [
        {
          name: "Dashboard",
          icon: "chart.svg",
          url: "dashboard",
          value: "1",
        },

        {
          name: "Voucher Issuance Request",
          icon: "Voucher Issuance Req.svg",
          url: "",
          value: "",
          children: [
            {
              name: "Digital Voucher",
              icon: "digital_voucher.svg",
              url: "",
              activeurl: "",
              value: "",
              children: [
                {
                  name: "Single",
                  icon: "digital_voucher.svg",
                  url: "digital-dashboard",
                  activeurl: "/digital-dashboard",
                  value: "2",
                },
                {
                  name: "Multiple",
                  icon: "chart.svg",
                  url: "denomination-dashboard",
                  activeurl: "/denomination-dashboard",
                  value: "3",
                },
              ],
              subMenuVisible: false,
            },

            {
              name: "Product Voucher",
              icon: "product_voucher.svg",
              url: "product-dashboard",
              activeurl: "/product-dashboard",
              value: "4",
            },
          ],
          subMenuVisible: false,
        },

        // {
        //   name: 'Product Voucher',
        //   icon: 'chart.svg',
        //   url: 'product-dashboard',
        //   value: '3'
        // },
        // {
        //   name: 'Deal Voucher',
        //   icon: 'chart.svg',
        //   url: 'deal-dashboard',
        //   value: '4'
        // },
        {
          name: "Source Management",
          icon: "source_mgt.svg",
          url: "source-mgt",
          value: "5",
        },
        {
          name: "Clients",
          icon: "Company.svg",
          url: "clients",
          value: "6",
        },
        // {
        //   name: 'Brand',
        //   icon: 'brand.svg',
        //   url: 'brand',
        //   value: '7'
        // },

        {
          name: "Category",
          icon: "category.svg",
          url: "category",
          value: "7",
        },
        {
          name: "Digital Voucher MIS Report",
          icon: "Ext product.svg",
          url: "digital-voucher",
          value: "8",
        },

        // {
        //   name: 'Brand Management',
        //   icon: 'brand_mgt.svg',
        //   url: 'brandmgt',
        //   value: '8'
        // },
        {
          name: "Products",
          icon: "product.svg",
          url: "",
          value: "",
          children: [
            {
              name: "Internal Product",
              icon: "Int product.svg",
              url: "internal-product",
              activeurl: "/internal-product",
              value: "9",
            },

            {
              name: "External Product",
              icon: "Ext product.svg",
              url: "external-product",
              activeurl: "/external-product",
              value: "10",
            },

            {
              name: "Product Mapping",
              icon: "Product mapping.svg",
              url: "productmap",
              activeurl: "/productmap",
              value: "11",
            },
            // {
            //   name: 'Terms & Conditions',
            //   icon: 'Product mapping.svg',
            //   url: 'terms',
            //   activeurl: '/terms',
            //   value: '12'
            // },
          ],
          subMenuVisible: false,
        },

        // {
        //   name: 'Settings',
        //   icon: 'brand_mgt.svg',
        //   url: 'settings',
        //   value: '12'
        // },
        // {
        //   name: 'External Product',
        //   icon: 'product.svg',
        //   url: 'product',
        //   value: '8'
        // },
        // {
        //   name: 'Internal Product',
        //   icon: 'product_mgt.svg',
        //   url: 'productmgt',
        //   value: '9'
        // },
      ];
    } else if (this.roletype === "SuperAdmin" && this.role === "SuperAdmin") {
      this.items = [
        {
          name: "Dashboard",
          icon: "chart.svg",
          url: "dashboard",
          value: "1",
        },

        {
          name: "Domestic Voucher Request",
          icon: "Voucher Issuance Req.svg",
          url: "",
          value: "",
          children: [
            {
              name: "Digital Voucher",
              icon: "digital_voucher.svg",
              url: "",
              activeurl: "",
              value: "",
              children: [
                {
                  name: "Single",
                  icon: "digital_voucher.svg",
                  url: "digital-dashboard",
                  activeurl: "/digital-dashboard",
                  value: "2",
                },
                {
                  name: "Multiple",
                  icon: "chart.svg",
                  url: "denomination-dashboard",
                  activeurl: "/denomination-dashboard",
                  value: "3",
                },
              ],
              subMenuVisible: false,
            },

            {
              name: "Product Voucher",
              icon: "product_voucher.svg",
              url: "product-dashboard",
              activeurl: "/product-dashboard",
              value: "4",
            },
          ],
          subMenuVisible: false,
        },
        
        {
          name: "International Voucher Request",
          icon: "Voucher Issuance Req.svg",
          url: "",
          value: "",
          children: [
            {
              name: "Digital Voucher",
              icon: "digital_voucher.svg",
              url: "",
              activeurl: "",
              value: "",
              children: [
                {
                  name: "Single",
                  icon: "digital_voucher.svg",
                  url: "international-dashboard",
                  activeurl: "/international-dashboard",
                  value: "22",
                },
                // {
                //   name: "Multiple",
                //   icon: "chart.svg",
                //   url: "denomination-dashboard",
                //   activeurl: "/denomination-dashboard",
                //   value: "23",
                // },
              ],
              subMenuVisible: false,
            },

            // {
            //   name: "Product Voucher",
            //   icon: "product_voucher.svg",
            //   url: "product-dashboard",
            //   activeurl: "/product-dashboard",
            //   value: "24",
            // },
          ],
          subMenuVisible: false,
        },

        
        {
          name: "Source Management",
          icon: "source_mgt.svg",
          url: "source-mgt",
          value: "5",
        },
        {
          name: "Clients",
          icon: "Company.svg",
          url: "clients",
          value: "6",
        },

        {
          name: "Category",
          icon: "category.svg",
          url: "category",
          value: "7",
        },
        {
          name: "Brand Management",
          icon: "brand_mgt.svg",
          url: "brandmgt",
          value: "8",
        },
        {
          name: "Products",
          icon: "product.svg",
          url: "",
          value: "",
          children: [
            {
              name: "Internal Product",
              icon: "Int product.svg",
              url: "internal-product",
              activeurl: "/internal-product",
              value: "9",
            },

            {
              name: "External Product",
              icon: "Ext product.svg",
              url: "external-product",
              activeurl: "/external-product",
              value: "10",
            },

            {
              name: "Product Mapping",
              icon: "Product mapping.svg",
              url: "productmap",
              activeurl: "/productmap",
              value: "11",
            },
          ],
          subMenuVisible: false,
        },

        {
          name: "Settings",
          icon: "brand_mgt.svg",
          url: "settings",
          value: "12",
        },
        {
          name: "Templates",
          icon: "digital_voucher.svg",
          url: "templates",
          value: "13",
        },
        {
          name: "Email Layout",
          icon: "digital_voucher.svg",
          url: "rewardkart-template",
          value: "14",
        },
        {
          name: "Voucher Redemption Report",
          icon: "Ext product.svg",
          url: "report",
          value: "15",
        },
        {
          name: "Digital Voucher MIS Report",
          icon: "Ext product.svg",
          url: "digital-voucher",
          value: "16",
        },
        {
          name: "External Category",
          icon: "Ext product.svg",
          url: "external-category",
          value: "17",
        },
        {
          name: "External Brand",
          icon: "Ext product.svg",
          url: "external-brand",
          value: "18",
        },
        {
          name: "B2C Module",
          icon: "Ext product.svg",
          url: "",
          value: "",
          children: [
            {
              name: "B2C Brands",
              icon: "Ext product.svg",
              url: "b2c-brand",
              activeurl: "/b2c-brand",
              value: "19",
            },
            {
              name: "B2C Category",
              icon: "Ext product.svg",
              url: "b2c-category",
              activeurl: "/b2c-category",
              value: "20",
            },
          ],
          subMenuVisible: false,
        },
        {
          name: "Terms & Conditions",
          icon: "Product mapping.svg",
          url: "terms",
          value: "21",
        },
        {
          name: "Currency Markup",
          icon: "digital_voucher.svg",
          url: "currency_markup",
          value: "22",
        },
        {
          name: "Rewardkart Client",
          icon: "Int product.svg",
          url: "rewardkart-client",
          value: "23",
        },
        {
          name: "Inventory Vouchers",
          icon: "Int product.svg",
          url: "inventory-voucher",
          value: "24",
        }
      ];
    } else if (this.role === "company" || "Company") {
      this.items = [
        {
          name: "Dashboard",
          icon: "chart.svg",
          url: "dashboard",
          value: "1",
        },
        {
          name: "Place Order for EGVs",
          icon: "digital_voucher.svg",
          url: "digital-dashboard",
          value: "",
          children : [
            {
              name: "Single Brand",
              icon: "digital_voucher.svg",
              url: "digital-dashboard",
              activeurl: "/digital-dashboard",
              value: "2",
              hover : "Same Denomination"
            },
            {
              name: "Multiple Brands",
              icon: "chart.svg",
              url: "denomination-dashboard",
              activeurl: "/denomination-dashboard",
              value: "3",
              hover : "Multiple Denomination"
            }
          ],
          subMenuVisible : true
        },
        // {
        //   name: 'Product Voucher',
        //   icon: 'product_voucher.svg',
        //   url: 'product-dashboard',
        //   activeurl: '/product-dashboard',
        //   value: '3'
        // },
        // {
        //   name: "Multiple Denomination",
        //   icon: "chart.svg",
        //   url: "denomination-dashboard",
        //   value: "3",
        // },
        {
          name: "E-Gift Voucher Templates",
          icon: "chart.svg",
          url: "client-email",
          value: "4",
        },

        {
          name: 'Rewardkart EGV catalogue',
          icon: 'digital_voucher.svg',
          url: 'catelogue',
          value: '5',
          // download : true
        }
      ];
    }

    if (this.role === "brand") {
      this.items = [
        {
          name: "Dashboard",
          icon: "chart.svg",
          url: "dashboard",
          value: "1",
        },
        {
          name: "Digital Voucher Request",
          icon: "digital_voucher.svg",
          url: "digital-dashboard",
          value: "2",
        },
        {
          name: "Product Voucher",
          icon: "product_voucher.svg",
          url: "product-dashboard",
          value: "3",
        },
        {
          name: "Multiple Denomination",
          icon: "chart.svg",
          url: "denomination-dashboard",
          value: "4",
        },
        // {
        //   name: 'Deal Voucher',
        //   icon: 'chart.svg',
        //   url: 'deal-dashboard',
        //   value: '4'
        // }
      ];
    }

    let e = localStorage.getItem("page");

    if (e) {
      if (this.items.length > 0 && (e === "9" || e === "10" || e === "11")) {
        this.items[6].subMenuVisible = true;
      } else if (
        this.items.length > 0 &&
        (e === "1" || e === "5" || e === "6")
      ) {
        this.items[e].subMenuVisible = true;
        this.selectedItem = parseInt(e);
      } else {
        this.selectedItem = parseInt(e);
      }
    }
  }

  ngOnInit() {}

  itemSelect(event: number, url: string , download?: boolean) {
    if (download) {
      const anchor = document.createElement('a');
      anchor.href = `${environmentfiles.ENVIRONMENT.baseURL}downloadAllProduct`; 
      anchor.target = '_self';
      anchor.download = 'sample-file.csv'; 
      anchor.click();
      anchor.remove();
    } else {
      for (let j = 0; j < this.items.length; j++) {
        this.items[j].subMenuVisible = false;
        if (this.items[j].children) {
          this.items[j].children.forEach((x: any) => {
            if (x.children) {
              if (x.children) {
                x.subMenuVisible = false;
                this.selectedItem1 = 100;
              }
            }
          });
        }
      }
      this.selectedItem = event;
      localStorage.setItem("page", event.toString());
      this.router.navigate(["/" + url]);
    }
    
  }

  itemSelect2(event: number, url: string) {
    for (let j = 0; j < this.items.length; j++) {
      this.items[j].subMenuVisible = false;
    }
    this.selectedItem = event;
    localStorage.setItem("page", event.toString());
    this.router.navigate(["/" + url]);
  }

  itemSelect22(
    event: number,
    url: string,
    item: any,
    i: any,
    childIndex?: number
  ) {
    this.selectedItem = event;
    this.path = item.activeurl;
    localStorage.setItem("page", i.toString());
    this.router.navigate(["/" + url]);
    this.toggleSubMenu(item, i);
    if (childIndex !== null && childIndex !== undefined) {
      this.selectedItem1 = event;
      this.items.forEach((element, y) => {
        if (y === i) {
          element.children[childIndex].subMenuVisible = element.children[
            childIndex
          ].subMenuVisible
            ? false
            : true;
        }
      });
    } else {
      this.selectedItem1 = 100;
    }
  }

  itemSelect23(
    event: number,
    url: string,
    item: any,
    i: any,
    childIndex: number
  ) {
    let { subMenuVisible, children = [] } = item;
    let tempItem = { ...item };
    tempItem.subMenuVisible = subMenuVisible ? false : true;
    this.items.forEach((element, y) => {
      if (y === i) {
        if (element && element.children.length) {
          element.children[childIndex] = tempItem;
        }
      }
    });
    this.toggleSubMenu(item, i);
  }

  // toggleSubMenu(item: any, i: any, subMenuDisable?: any) {
  //   for (let j = 0; j < this.items.length; j++) {
  //     this.items[j].subMenuVisible = false;
  //     if (this.items[j].children) {
  //       if (subMenuDisable) {
  //         this.items[j].children.forEach((x: any) => {
  //           if (x.children) {
  //             if (item.name !== "Voucher Issuance Request") {
  //               if (x.children) {
  //                 x.subMenuVisible = false;
  //                 if (
  //                   !["/digital-dashboard", "/denomination-dashboard"].includes(
  //                     window.location.pathname
  //                   )
  //                 ) {
  //                   this.selectedItem1 = 100;
  //                 }
  //               }
  //             }
  //           }
  //         });
  //       }
  //     }
  //   }
  //   this.items[i].subMenuVisible = !this.items[i].subMenuVisible;
  // }
  toggleSubMenu(item: any, i: any, subMenuDisable?: any) {
    const isCurrentlyVisible = this.items[i].subMenuVisible;
    for (let j = 0; j < this.items.length; j++) {
      this.items[j].subMenuVisible = false;
      if (this.items[j].children && subMenuDisable) {
        this.items[j].children.forEach((x: any) => {
          if (x.children && item.name !== "Voucher Issuance Request") {
            x.subMenuVisible = false;
            if (!["/digital-dashboard", "/denomination-dashboard"].includes(window.location.pathname)) {
              this.selectedItem1 = 100;
            }
          }
        });
      }
    }
    this.items[i].subMenuVisible = !isCurrentlyVisible;
  }
  
  
}
