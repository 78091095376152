import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.scss'],
  
})
export class CommonTableComponent {
  @Input() tableDatas:Array<any> = [];
  @Input() columns:Array<string> = [];
  @Input() d: number = 1;
  @Input() skip: number = 0;
  @Input() lastvalue: number = 0;
  @Input() count: number = 0;
  @Input() limit: number = 10;
  @Input() action: string = "EDIT";
  @Output() EditValue = new EventEmitter();
  @Output() pagination = new EventEmitter();
  @Input() primaryKeys: Array<string> = [];
  @Input() index: boolean = false;
  @Input() cssClass: string = "";
  role : string = '';
  @Input() itemPerPage: number = 10;

  constructor() {
    this.role = localStorage.getItem('role') || '';
  }

  keys(params:object) {
    // return Object.keys(params).filter(key => this.primaryKeys.includes(key));
    return this.primaryKeys.filter(key => Object.keys(params).includes(key));

  }

  pageChanged(pageNo: any) {
    this.d = pageNo;
    this.skip = (pageNo - 1) * this.itemPerPage;
    this.lastvalue = this.skip + this.itemPerPage;
    localStorage.setItem('pageNo', pageNo);
    this.pagination.emit(true);
  }

  edit(value:any) {
    this.EditValue.emit({
      data:value,
      isEdit: true
    });
  }

}
