<div
  class="modal fade"
  id="categoryModal"
  tabindex="-1"
  aria-
  labelledby="exampleModalLabel"
  aria-hidden="true"
  style="position: fixed">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body row mx-0 text-center">
        <p class="texts">Update Brand</p>

        <!-- <form #addForm="ngForm" name="form"  class="mt-4">    -->

        <div class="col-6">
          <div class="md-form form-sm my-3 leftAlign">
            <label class="mb-2">Name </label>
             <input
              type="text"
              [(ngModel)]="nameList"
              class="form-control"
              placeholder="Enter Name"
              mdbInput
              autocomplete="off"
              name="name"
              #nameval="ngModel"
              (keypress)="onKeyPresscomma($event)"
              (input)="restrictDecimals($event)"
              required />

            <!-- <span class="text-danger" *ngIf="nameval.touched  && nameval.errors?.['required']">
                            name is required
                        </span> -->
          </div>
        </div>
        <div class="col-6">
          <div class="md-form form-sm my-3 leftAlign">
            <label class="mb-2"
              >Description
              <!-- <span class="text-danger">*</span> -->
            </label>
             <textarea
              type="text"
              [(ngModel)]="descriptionList"
              class="form-control"
              placeholder="Enter Description"
              mdbInput
              autocomplete="off"
              name="description"
              #description="ngModel"
              (keypress)="onKeyPresscomma($event)"
              (input)="restrictDecimals($event)"
              required>
                            <span class="text-danger" 
                            *ngIf="description.touched && description.errors?.['required']">
                            description is required
                        </span> </textarea
            >
          </div>
        </div>
        <div class="col-6">
          <div class="md-form form-sm my-3 leftAlign">
            <label class="mb-2">Discount </label>
             <input
              type="text"
              [(ngModel)]="discount"
              class="form-control"
              placeholder="Enter Discount"
              mdbInput
              autocomplete="off"
              name="discountval"
              #discountval="ngModel"
              (keypress)="allowOnlyNumbers($event)"
              (input)="restrictDecimals($event)"
              required />

            <!-- <span class="text-danger" *ngIf="discountval.touched  && discountval.errors?.['required']">
                            Discount is required
                        </span> -->
          </div>
        </div>
        <div class="col-6">
          <div class="md-form form-sm my-3 leftAlign">
            <label class="mb-2">Store Locator </label>
             <input
              type="text"
              [(ngModel)]="storelocator"
              class="form-control"
              placeholder="Enter Store Locator"
              mdbInput
              autocomplete="off"
              name="store"
              #store="ngModel"
              (keypress)="onKeyPresscomma($event)"
              required />

            <!-- <span class="text-danger" *ngIf="store.touched  && store.errors?.['required']">
                            StoreLocator is required
                        </span> -->
          </div>
        </div>

        <div class="col-6">
          <div class="md-form form-sm my-3 leftAlign">
            <label class="mb-2">How to use </label>
             <input
              type="text"
              [(ngModel)]="howtouse"
              class="form-control"
              placeholder="Enter How to use"
              mdbInput
              autocomplete="off"
              name="how"
              #how="ngModel"
              (keypress)="onKeyPresscomma($event)"
              (input)="restrictDecimals($event)"
              required />

            <!-- <span class="text-danger" *ngIf="how.touched  && how.errors?.['required']">
                            How to use is required
                        </span> -->
          </div>
        </div>
        <div class="col-6">
          <div class="md-form form-sm my-3 leftAlign">
            <label class="mb-2">Reward Points </label>
             <input
              type="text"
              [(ngModel)]="reward"
              class="form-control"
              placeholder="Enter Reward Points"
              mdbInput
              autocomplete="off"
              name="rewardpoint"
              #rewardpoint="ngModel"
              (keypress)="allowOnlyNumbers($event)"
              (input)="restrictDecimals($event)"
              required />

            <!-- <span class="text-danger" *ngIf="rewardpoint.touched  && rewardpoint.errors?.['required']">
                            Reward Points is required
                        </span> -->
          </div>
        </div>

        <div class="col-6">
          <div class="md-form form-sm my-3 leftAlign">
            <label class="mb-2">Important Instruction </label>
            <div class="card-body pb-4">
              <angular-editor
                [(ngModel)]="this.instrction"
                [config]="config"></angular-editor>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="md-form form-sm my-3 leftAlign">
            <label class="mb-2">Terms and Condition </label>
            <div class="card-body pb-4">
              <angular-editor
                [(ngModel)]="this.terms"
                [config]="config"></angular-editor>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="md-form form-sm my-3 leftAlign">
            <label class="mb-2">Price </label>
             <input
              type="text"
              [(ngModel)]="price"
              class="form-control"
              placeholder="Enter Price"
              mdbInput
              autocomplete="off"
              name="priceList"
              #priceList="ngModel"
              (keypress)="allowOnlyNumbers($event)"
              required />

            <!-- <span class="text-danger" *ngIf="rewardpoint.touched  && rewardpoint.errors?.['required']">
                            Reward Points is required
                        </span> -->
          </div>
        </div>
        <div class="col-6">
          <div class="md-form form-sm my-3 leftAlign">
            <label class="mb-2">Usage Mode </label>

            <select
              id="usage_mode"
              [(ngModel)]="usage_mode"
              class="form-control"
              placeholder="Enter Usagemode"
              name="usage_mode">
              <option *ngIf="usageMode" selected disabled hidden>
                {{ usage_mode }}
              </option>
              <!-- <option  value="" selected disabled >Select Usagemode</option> -->
              <option value="Online">Online</option>
              <option value="Offline">In-Store</option>
              <option value="Omni Channel">Online + In-Store</option>
            </select>
          </div>
        </div>

        <p class="mt-3 btn-back">
          <button
            class="btn btn-secondary mr-8 px-4 py-2"
            data-bs-dismiss="modal"
            #closeCategoryBtn
            type="button">
            Back
          </button>
          <button
            class="btn btn-success px-4 py-2"
            data-bs-dismiss="modal"
            (click)="editedDatas()">
            Update
          </button>
        </p>

        <!-- </form> -->
      </div>
    </div>
  </div>
</div>
<div class="row mx-0">
  <div class="col-12 card border-0 shadow-sm px-0">
    <div class="card-body pb-4">
      <div class="row mb-4">
        <div class="col-3">
          <label class="texts">External Products List</label>
        </div>

        <div class="text-end col-9">
          <!-- <a href="/sample_product_discount.csv" class="add_bt btn btn-success mr-10"
                        type="button" target="_self" download="sample-file.csv">
                        <i class="fa fa-download pr-1"></i> Sample file
                    </a> -->
      <button class="btn btn-success mr-10" (click)="exportAllVoucher()">
              <i class="fa fa-download mr-10"></i>Product Export
      </button>

          <a
            class="mr-10"
            style="color: green"
            href="{{ this.fileurls }}/sample/sample_product_sku.csv"
            >SKU Sample file</a
          >

          <label
            class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10"
            for="inputSKUFile">
            <input
              type="file"
              accept=".csv"
              class="form-control d-none"
              id="inputSKUFile"
              #inputSKUFile
              (change)="importVoucherSKU($event)" />
            <i class="fa fa-upload pr-1"></i> Import SKU</label
          >
          <!-- </div>
 
                <div class="text-end col-9"> -->

          <!-- <a href="/sample_product_discount.csv" class="add_bt btn btn-success mr-10"
                        type="button" target="_self" download="sample-file.csv">
                        <i class="fa fa-download pr-1"></i> Sample file
                    </a> -->

          <a
            class="mr-10"
            style="color: green"
            href="{{ this.fileurls }}/sample/sample_product_discount.csv"
            >Discount Sample file</a
          >

          <label
            class="rounded px-3 py-2 bg-success text-center border-0 labelText mr-10"
            for="inputFile">
            <input
              type="file"
              accept=".csv"
              class="form-control d-none"
              id="inputFile"
              #inputFile
              (change)="importVoucher($event)" />
            <i class="fa fa-upload pr-1"></i> Import</label
          >
        </div>

        <div class="d-flex" style="padding-top: 20px">
          <!-- <div class="form-floating me-3">

                    <select id="source" class="form-select" [(ngModel)]="source" placeholder="source"
                        (change)='selectsourceEvent(source)'>

                        <option value="" selected disabled hidden>Source</option>
                        <option *ngFor="let option of sourceData" [value]="option.supplier_name">{{ option.supplier_name
                            }}</option>

                    </select>
                </div> -->

          <div class="form-floating me-3">
            <select
              id="source"
              class="form-select border-0 bg-light"
              [(ngModel)]="source"
              name="source"
              style="width: 150px; padding-top: 13px"
              (change)="selectsourceEvent(source)">
              <option value="" selected disabled hidden>Source</option>
              <option value="QwikCilver">QwikCilver</option>
              <option value="FlipKart">FlipKart</option>
              <option value="Amazon">Amazon</option>
              <option value="Ticket Express">Ticket Express</option>
              <option value="EZPIN">EZPIN</option>
              <option value="MEA">MEA</option>
              <option value="DT">DT ONE</option>
              <option value="YGG">YOU GOTA GIFT</option>
              <option value="Joyalukkas">Joyalukkas</option>
              <option value="ValueDesign">Value Design</option>
              <option value="Bamboo">Bamboo</option>
              <option value="LikeCard">LikeCard</option>
            </select>
          </div>

          <div class="form-floating me-3 textarea">
            <select
              id="category"
              class="form-select textarea"
              [(ngModel)]="category"
              placeholder="Category"
              [disabled]="fieldDisabled"
              (change)="filterList()">
              <option value="" selected disabled hidden>Category</option>
              <option value="All">All</option>
              <option
                class="textarea"
                *ngFor="let option of categoryData"
                [value]="option.external_id">
                {{ option.name }}
              </option>
            </select>
          </div>

          <div class="form-floating me-3 textarea">
            <select
              id="brand_name"
              class="form-select textarea"
              [(ngModel)]="brand_name"
              placeholder="Brand"
              (change)="filterList()"
              [disabled]="fieldDisabled">
              <option value="" selected disabled hidden>Brand</option>
              <option value="All">All</option>
              <option
                class="textarea"
                *ngFor="let option of brandData"
                [value]="option.brand_name">
                {{ option.brand_name }}
              </option>
            </select>
          </div>

          <div class="form-floating me-3">
            <input
              type="text"
              [(ngModel)]="product"
              name="product"
              class="form-control border-0 bg-light"
              placeholder="SKU/Product "
              style="width: 210px; padding-top: 13px"
              (keypress)="filterList(); onKeyPresscomma($event)" />
            <label for="tnums">SKU/Product</label>
          </div>

          <button
            class="btn btn-success mr-5"
            (click)="filterList()"
            style="width: 100px">
            Search
          </button>

          <!-- <button class="btn btn-success mr-5"  style="width: 100px;" (click)="filterList()">Search</button> -->
          <button
            class="btn btn-secondary mr-5"
            style="width: 90px"
            (click)="clear()">
            Clear
          </button>
        </div>

        <div class="mt-4 batch-data px-1">
          <div class="row w-100 mx-0 adcolor py-4 opacity-75 px-3 ps-0">
            <!-- <div class="col-1 text-center labelText" *ngIf="role === 'admin'">
                        All - <input type="checkbox" [checked]="selectAllChecked" class="filled-in form-check-input"
                            [(ngModel)]="selectAll">
                    </div> -->

            <div class="col w-10 labelText text-start">Product SKU</div>
            <div class="col w-10 labelText text-start">Client SKU</div>
            <!-- <div class="col labelText text-start"> Name</div> -->
            <div class="col w-10 labelText text-center">Name</div>
            <div class="col w-10 labelText text-center">Category</div>
            <!-- <div class="col labelText text-start"> Description </div> -->
            <div class="col w-10 labelText text-center">B2C Name</div>
            <div class="col w-10 labelText text-end">Image</div>
            <div class="col w-10 labelText text-end">Brand</div>
            <div class="col w-10 labelText text-end">Price</div>
            <div class="col w-10 labelText text-end">Discount</div>

            <!-- <div class="col text-center labelText" *ngIf="role === 'admin'">
                        Display in Voucherkart - <input type="checkbox" [checked]="selectAllChecked"
                            class="filled-in form-check-input" [(ngModel)]="selectAll">
                    </div>

                    <div class="col text-center labelText" *ngIf="role === 'admin'">
                        Display in Rewardkart - <input type="checkbox" [checked]="selectAllChecked"
                            class="filled-in form-check-input" [(ngModel)]="selectAlls">
                    </div> -->

            <!-- <div *ngIf = "displayhide" class="col labelText text-center"> Display in Voucherkart</div>
                    <div *ngIf = "displayhide" class="col labelText text-center"> Display in Rewardkart</div>  -->
            <!-- <div class="col labelText text-center"> View </div>  -->

            <div
              class="col w-10 labelText text-center test-class"
              *ngIf="displayhide">
              Display in Voucherkart <br /><br />
              <div class="row colinside">
                <div class="col border-end">BtoB</div>
                <div class="col border-end">BtoC</div>
              </div>
            </div>
            <div class="col w-10 labelText text-center">Action</div>
          </div>

          <div
            class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
            ng-reflect-ng-class="bg-light"
            *ngIf="productList && productList.length === 0 && !apiLoading">
            <div class="card-body row mx-0 ps-0">
              <div class="text-center">No Record Found.</div>
            </div>
          </div>

          <div
            class="w-100"
            *ngFor="
              let list of productList
                | paginate
                  : { itemsPerPage: limit, currentPage: p, totalItems: count };
              let i = index
            ">
            <div
              class="card my-0 py-0 border-0 rounded-0 shadow-items bg-light"
              ng-reflect-ng-class="bg-light">
              <div class="card-body row mx-0 ps-0">
                <div class="col w-10 text-start position-relative">{{ list.sku }}
                  <a  class="linkColor downloadIcon position-absolute start-100 top-0 cursor-pointer" #Edit (click)="editSKU(list)">
                  <i class="fa fa-edit" data-bs-toggle="modal" data-bs-placement="bottom" data-bs-target="#exampleModal" title="SKU Edit"></i>
                </a>
                </div>
                <div class="col w-10 text-start">{{ list.clientSKU }}</div>

                <!-- <div *ngIf="list.source === 'QC'" class="col text-start" title="{{list.name}}">

                                   
                                    <span> <img src="../../assets/images/qwikcilver-png.png" height="20"/>   {{list.name}}</span>                                    

                                </div>

                                <div *ngIf="list.source === 'AMAZON'" class="col text-start" title="{{list.name}}">
                                    
                                    <span><img src="../../assets/images/amazon1-png.png" height="20"/>      {{list.name}}</span>
                                </div>

                                <div *ngIf="list.source === 'FlipKart'" class="col text-start" title="{{list.name}}">
                                    
                                    <span><img src="../../assets/images/flipkart.jpg" height="20"/>      {{list.name}}</span>
                                </div>


                                <div *ngIf="list.source === 'TE'" class="col text-start" title="{{list.name}}">

                                    <span> <img src="../../assets/images/ticketexpress-png.png" height="20"/>    {{list.name}}</span>

                                </div> -->

                <!-- <div *ngIf = "list.brandName">
                                <div class="col text-truncate pe-0 text-center"> {{list.parent_brandName}} ({{list.brandName}})</div>
                            </div> -->
                <div
                  class="col text-start w-10"
                  title="{{
                    list.name +
                      '\n' +
                      'B2B Description :' +
                      '\n' +
                      list.description
                  }}">
                  {{ list.name || "-" }}
                </div>
                <div
                  class="col text-start w-10"
                  title="{{ list.category_name }}">
                  {{ list.category_name }}
                </div>
                <!-- <div class="col text-start" title="{{list.description}}"> {{list.description || '-'}}
                                </div> -->
                <div
                  class="col text-start w-10"
                  style="max-height: 30ch"
                  title="{{
                    list.b2c_name +
                      '\n' +
                      'B2C Description:' +
                      '\n' +
                      list.b2c_description
                  }}">
                  {{ list.b2c_name || "-" }}
                </div>
                <div class="col text-end w-10" style="position: relative">
                  <label for="{{ list.id }}">
                    <input
                      type="file"
                      accept="image/*"
                      class="form-control d-none"
                      id="{{ list.id }}"
                      #inputFile2
                      (change)="selectfile($event, list.id, list.thumbnail)" />
                    <i class="fa fa-edit pl-3 icon"></i>
                  </label>
                  <img
                    src="{{ list.thumbnail }}"
                    alt="product image"
                    class="fluid" />
                </div>

                <div
                  class="col text-start w-10"
                  title="{{ list.parent_brandName }}">
                  {{ list.parent_brandName }}

                  <span *ngIf="list.brandName" title="{{ list.brandName }}"
                    >({{ list.brandName }})</span
                  >
                </div>

                <div
                  *ngIf="
                    list.source !== 'QC' &&
                    list.source !== 'AMAZON' && list.source !== 'Bamboo' && list.source !== 'ValueDesign' &&
                    list.source !== 'MEA' &&
                    list.source !== 'EZPIN' &&
                    list.source !== 'DT'  &&
                    list.source !== 'Joyalukkas'
                    &&
                    list.source !== 'YGG' && list.source !== 'LikeCard'
                  "
                  class="col text-truncate text-end w-10">
                  {{ list.price | currency : "INR" }}
                </div>
                <!-- <div
                *ngIf="
                  list.source !== 'QC' &&
                  list.source !== 'AMAZON' &&
                  list.source !== 'MEA' &&
                  list.source !== 'EZPIN' &&
                  list.source !== 'DT'
                "
                class="col text-truncate text-end w-10">
                {{ list.price | currency : "INR" }}
              </div> -->
                <div
                  *ngIf="
                    list.source == 'QC' ||
                    list.source == 'AMAZON' || list.source == 'Bamboo' || list.source == 'ValueDesign' ||
                    list.source == 'MEA' ||
                    list.source == 'EZPIN' ||
                    list.source == 'DT' ||
                    list.source == 'YGG' || list.source == 'Joyalukkas' || list.source == 'LikeCard'
                  "
                  class="col w-10 text-start"
                  title="(min {{ list.minPrice | currency : 'INR' }} - max {{
                    list.maxPrice | currency : 'INR'
                  }})"><span *ngIf="list.price">{{list.currency_symbol
                      ? list.currency_symbol + list.price
                      : (list.price | currency : "INR")}}</span>
                  <span *ngIf="list.minPrice && list.maxPrice">
                    (min {{list.currency_symbol
                      ? list.currency_symbol + list.minPrice
                      : (list.minPrice | currency : "INR")}}
                  - max {{list.currency_symbol
                      ? list.currency_symbol + list.maxPrice
                      : (list.maxPrice | currency : "INR")}})</span>
                  
                </div>

                <div class="col text-truncate text-center w-10">
                  <span *ngIf="list.discount"> {{ list.discount }}%</span>
                  <span
                    *ngIf="
                      !list.discount &&
                      (list.source == 'QC' || list.source == 'AMAZON' || list.source == 'Joyalukkas')
                    "
                    >-</span
                  >
                </div>

                <div
                  class="col text-truncate text-start py-3 w-10"
                  *ngIf="displayhide">
                  <div class="row">
                    <div class="col">
                      <div class="col text-start" *ngIf="displayhide">
                        <div *ngIf="list.display_voucherkart === 'ON'">
                          <input
                            type="checkbox"
                            checked="true"
                            class="filled-in form-check-input"
                            (click)="type(list, 0, 'OFF')"
                            value="Voucherkart" />
                        </div>

                        <div *ngIf="list.display_voucherkart === 'OFF'">
                          <input
                            type="checkbox"
                            class="filled-in form-check-input"
                            (click)="type(list, 0, 'ON')"
                            value="Voucherkart" />
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="col text-start" *ngIf="displayhide">
                        <div class="col text-center px-0">
                          <div *ngIf="list.display_rewartkart === 'ON'">
                            <input
                              type="checkbox"
                              checked="true"
                              class="filled-in form-check-input"
                              (click)="type(list, 1, 'OFF')"
                              value="Rewardkart" />
                          </div>

                          <div *ngIf="list.display_rewartkart === 'OFF'">
                            <input
                              type="checkbox"
                              class="filled-in form-check-input"
                              (click)="type(list, 1, 'ON')"
                              value="Rewardkart" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col text-end w-10">
                  <a
                    class="linkColor downloadIcon"
                    #Edit
                    (click)="editProduct(list)">
                    <i
                      class="fa fa-edit mr-10"
                      data-bs-toggle="modal"
                      data-bs-placement="bottom"
                      data-bs-target="#exampleModal"
                      title="Edit"></i>
                  </a>
                </div>

                <!-- <div class="col text-truncate text-center"> 

                            <a class="linkColor">
                                <i class="fa fa-eye cursor col" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View"></i>
                            </a>

                            </div> -->

                <!-- <div class="col text-truncate pe-0 text-center">  </div>
                            <div class="col text-truncate pe-0 text-center">  </div> -->

                <!-- 
                            <div class="col px-0 text-center">

                                <div *ngIf="list.display_voucherkart === 'ON' ">


                                    <input type="checkbox" id="list{{i}}" [(ngModel)]="selectvoucherkart"
                                        value="Voucherkart" name="supplierType{{i}}" (change)="type(list.id,0)">
                                    <label for=list{{i}} class="ms-2 me-4">Voucherkart</label>

                                    <input type="checkbox" id="liste{{i}}{{i+1}}" [(ngModel)]="list.type"
                                        value="Rewardkart" name="supplierType{{i}}{{i+1}}" (change)="type(list.id,1)">
                                    <label for=liste{{i}}{{i+1}} class="ms-2 me-4">Rewardkart</label>


                                </div>

                                <div *ngIf="list.display_rewartkart === 'ON' ">


                                    <input type="checkbox" id="list{{i}}" value="Voucherkart" name="supplierType{{i}}"
                                        (change)="type(list.id,0)">
                                    <label for=list{{i}} class="ms-2 me-4">Voucherkart</label>


                                    <input type="checkbox" id="liste{{i}}{{i+1}}" [(ngModel)]="selectrewardkart"
                                        value="Rewardkart" name="supplierType{{i}}{{i+1}}" (change)="type(list.id,1)">
                                    <label for=liste{{i}}{{i+1}} class="ms-2 me-4">Rewardkart</label>


                                </div>

                                <div *ngIf="list.display_voucherkart === 'OFF' && 
                                    list.display_rewartkart === 'OFF'">

                                    <input type="checkbox" id="list{{i}}" value="Voucherkart" name="supplierType{{i}}"
                                        (change)="type(list.id,0)">
                                    <label for=list{{i}} class="ms-2 me-4">Voucherkart</label>

                                    <input type="checkbox" id="liste{{i}}{{i+1}}" [(ngModel)]="list.type"
                                        value="Rewardkart" name="supplierType{{i}}{{i+1}}" (change)="type(list.id,1)">
                                    <label for=liste{{i}}{{i+1}} class="ms-2 me-4">Rewardkart</label>

                                </div>
                            </div> -->
              </div>
            </div>
          </div>

          <div>
            <div class="row" *ngIf="productList && productList.length > 0">
              <div class="col-6 pt-3">
                <p>
                  Showing {{ p > 1 ? skip + 1 : p }} to
                  {{ lastvalue > count ? count : lastvalue }} of
                  {{ count }} entries
                </p>
              </div>
              <div class="col-6">
                <pagination-controls
                  class="list-pagination adpage"
                  (pageChange)="pageChanged($event)">
                </pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-ui-loader></ngx-ui-loader>
</div>


<div class="modal fade" id="skuEdit"  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="position: fixed">
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-body row mx-0 text-center">
        <p class="texts">Update SKU</p>

        <div class="col-12">
          <div class="md-form form-sm my-3 leftAlign">
            <label class="mb-2">Product SKU </label>
             <input
              type="text"
              [(ngModel)]="editedSku"
              class="form-control"
              placeholder="Enter SKU"
              mdbInput
              autocomplete="off"
              name="editedSku"
              required />
          </div>
        </div>
        

        <div class="mt-3 d-flex justify-content-end">
          <button
            class="btn btn-secondary me-3 px-4 py-2"
            data-bs-dismiss="modal"
            type="button">
            Close
          </button>
          <button
            class="btn btn-success px-4 py-2"
            data-bs-dismiss="modal"
            (click)="updateSKU()">
            Update
          </button>
        </div>

        <!-- </form> -->
      </div>
    </div>
  </div>
</div>